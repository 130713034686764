import React, { useEffect, useState } from "react";
import {
  HashRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Home from "./Screens/HomePage/Home";
import ClimbingScreen from "./Screens/Climbing/ClimbingScreen";
import ContactUsScreen from "./Screens/Contact/ContactUsScreen";
import CovidScreen from "./Screens/Contact/CovidScreen";
import InquireScreen from "./Screens/Contact/InquireScreen";
import DestinationMainScreen from "./Screens/Destinations/DestinationMainScreen";
import SafariMainScreen from "./Screens/Safaris/SafariMainScreen";
import Accomodations from "./Screens/Accommodations/Accomodations";
import AccomodationDetail from "./Screens/Accommodations/AccomodationDetail";
import SafariDetailScreen from "./Screens/Safaris/SafariDetailScreen";
import TravelInfoScreen from "./Screens/TravelInfo/TravelInfoScreen";
import AboutUsScreen from "./Screens/AboutUs/AboutUsScreen";
import AdminInquireScreen from "./Admin/AdminInquireScreen";
import AdminInquireById from "./Admin/AdminInquireById";
import LoginPage from "./Screens/Login/LoginPage";
import ActivitiesScreen from "./Screens/Activities/ActivitiesScreen";
import Navbar from "./Components/Navbar";
import AdminSideBar from "./Admin/AdminSideBar";
import ArrowTop from "./Components/ArrowTop";
import Footer from "./Components/Footer";
import FooterImages from "./Components/FooterImages";
import JsonData from "./Datas/data.json";
import "./App.css";
import PrivateRouters from "./utils/PrivateRouters";
import Recommend from "./Screens/HomePage/Recommend";


const App2 = () => {
  return (
    <Router>
      <Routes>
        <Route path="/*" element={<App />} />
      </Routes>
    </Router>
  );
};

function App() {
  const navigate = useNavigate();
  const userInformation = useSelector((state) => state.userInformation);
  const { userInfo, isLoggedIn } = userInformation;
 
  const EditAllInfo = useSelector((state) => state.EditAllInfo);
  const dispatch = useDispatch();

  const location = useLocation();
  const currentPath = location.pathname;
  const [landingPageData, setLandingPageData] = useState({});

  const [screenHeight, setScreenHeight] = useState(0);

  const handleScreenHeightChange = (height) => {
    const vhHeight = (height / window.innerHeight) * 100; // Convert to vh
    setScreenHeight(vhHeight);
  };

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
  }, [isLoggedIn]);

  return (
    <div
      className={
        userInfo && userInfo
          ? "d-flex col-12 p-0  unselectable"
          : "col-12 p-0  flex-column d-flex unselectable"
      }
    >
      {/* <div className="flex-column d-flex"> */}
      {!(userInfo && userInfo) && location.pathname !== "/login" && <Navbar />}
      {userInfo && userInfo && (
        <div style={{ width: "15%" }}>
          <AdminSideBar height={screenHeight} />
        </div>
      )}
      <ArrowTop />
      <Routes location={location} key={location.pathname}>
        <Route
          path="/"
          element={
            <Home
              destinationSummary={landingPageData?.destinationSummary}
              safaris={landingPageData?.safaris}
              data={landingPageData?.Home}
            />
          }
          exact
        ></Route>
        <Route
          path="/destinations"
          element={
            <DestinationMainScreen
              destinations={landingPageData?.destinations}
            />
          }
        ></Route>

        <Route
          path="/climbing-trekking"
          element={<ClimbingScreen data={landingPageData?.Home} />}
        ></Route>

        <Route
          path="/activities"
          element={<ActivitiesScreen data={landingPageData?.Home} />}
        ></Route>
        <Route
          path="/travel-info"
          element={<TravelInfoScreen data={landingPageData?.Home} />}
        ></Route>
        <Route
          path="/contactus"
          element={<ContactUsScreen data={landingPageData?.Home} />}
        ></Route>
        <Route
          path="/inquire"
          element={<InquireScreen data={landingPageData?.Home} />}
        ></Route>
        <Route
          path="/covid-updates"
          element={<CovidScreen data={landingPageData?.Home} />}
        ></Route>
        <Route
          path="/about-us"
          element={<AboutUsScreen data={landingPageData?.Home} />}
        ></Route>
        <Route
          path="/travel-essentials"
          element={
            <Recommend
              data={landingPageData?.Home}
              products={landingPageData?.salesMore}
            />
          }
        ></Route>
        <Route
          path="/safaris"
          element={
            <SafariMainScreen
              data={landingPageData?.Home}
              safaris={landingPageData?.safaris}
            />
          }
        ></Route>

        <Route
          path="/safaris/:name"
          element={
            <SafariDetailScreen
              data={landingPageData?.Home}
              safaris={landingPageData?.safaris}
            />
          }
        ></Route>
        <Route
          path="/accommodations"
          element={
            <Accomodations
              data={landingPageData?.Home}
              accos={landingPageData?.accommodations}
            />
          }
        ></Route>
        <Route
          path="/accommodation/:name"
          element={
            <AccomodationDetail
              data={landingPageData?.Home}
              accos={landingPageData?.accommodations}
            />
          }
        ></Route>
        <Route path="/login" element={<LoginPage />}></Route>
        <Route element={<PrivateRouters user={userInfo && userInfo} />}>
          <Route
            path="/messages-admin"
            element={
              <AdminInquireScreen onHeightChange={handleScreenHeightChange} />
            }
          ></Route>
          <Route
            path="/inbox/:inType/:id"
            element={
              <AdminInquireById onHeightChange={handleScreenHeightChange} />
            }
          ></Route>
        </Route>
      </Routes>
      {!(userInfo && userInfo) && location.pathname !== "/login" && (
        <div className="col-12 d-flex flex-column">
          {/* <FooterImages /> */}
          <Footer />
        </div>
      )}
    </div>
  );
}

export default App2;
