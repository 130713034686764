import React, { useState, useEffect, useRef } from "react";
import "./safaris.css";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useMediaQuery } from "react-responsive";
import bgImage from "../../Logos/bgImage.png";
import FooterImages from "../../Components/FooterImages";
gsap.registerPlugin(ScrollTrigger);

const DialogImage = styled.img`
  width: 100%;
  height: 15vw;
  object-fit: cover;
  border-radius: 10px 10px 0 0;

  @media (max-width: 768px) {
    /* Mobile devices */
    height: 40vw;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    /* Tablets */
    height: 30vw;
  }
`;

function SafariMainScreen({ safaris, data }) {
  const [showAnimations, setShowAnimations] = useState(false);
  const isBiggerScreen = useMediaQuery({ minWidth: 1824 });
  const isBigDesktop = useMediaQuery({ minWidth: 1224, maxWidth: 1823 });
  const isDesktop = useMediaQuery({ minWidth: 992, maxWidth: 1223 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    if (showAnimations) {
      const animations = gsap.utils.toArray(".crdShadow").map((element) => {
        return gsap.from(element, {
          opacity: 0,
          y: 100,
          scrollTrigger: {
            trigger: element,
            start: "top 80%",
            end: "bottom 20%",
            toggleActions: "play none none reverse",
          },
        });
      });

      return () => {
        animations.forEach((animation) => animation.kill());
      };
    }
  }, [showAnimations]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAnimations(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className="bg-light safariScreen mt-0"
      style={{
        minHeight: "100vh",
        paddingBottom: "10vw",
      }}
    >
      <div className="mt-2 col-12 p-3  d-flex flex-wrap justify-content-center ">
        {safaris?.map((s, index) => {
          return (
            <div
              className={`${
                isMobile
                  ? "col-12"
                  : isTablet
                  ? "col-6"
                  : isDesktop
                  ? "col-4"
                  : "col-3"
              } p-4`}
            >
              <Link to={`/safaris/${s.title2}`} style={{ all: "unset" }}>
                <div
                  style={{ visibility: showAnimations ? "visible" : "hidden" }}
                  className="crdShadoww crdHover card shadow  d-flex flex-column "
                >
                  <div>
                    <DialogImage
                      src={require(`../../Images/${s?.Images[0]?.image}`)}
                    />
                  </div>
                  <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                    <div className="col-8 fw-bold text-center">
                      {/* {s.duration} */}
                       {s.title}
                    </div>
                  </div>
                  <div
                    className="col-12 ps-2 mt-2 pe-2 text-center"
                    style={{ height: "150px", overFlowY: "auto" }}
                  >
                    {s.overview?.substring(0, 200)} ..
                  </div>
                  <div className="col-12 pb-3 d-flex justify-content-center">
                    <div className="mt-3">
                      <button className="btn-1">More Details</button>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SafariMainScreen;
