import { useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa";
import "./arrow.css";

function ArrowTop() {
  const [showArrow, setShowArrow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      if (scrollTop > 100) {
        setShowArrow(true);
      } else {
        setShowArrow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div>
      {/* Your component content goes here */}
      {/* {showArrow && (
          )} */}
      <div className="arrow-up" onClick={handleScrollToTop}>
        {/* <i className="fa fa-arrow-circle-up" aria-hidden="true"></i> */}
        <FaArrowUp color="white" />
      </div>
    </div>
  );
}

export default ArrowTop;
