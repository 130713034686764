import React, { useState, useEffect, useRef } from "react";
import "./aboutus.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import lodImg from "../../Images/lod.jpg";
import expressCard from "../../Images/americanCard.jpg";
import creditCard from "../../Images/creditC.png";
import visaCard from "../../Images/visa.png";
import wireCard from "../../Images/wireTran.jpg";
import Tooltip from "@mui/material/Tooltip";
import * as MdIcons from "react-icons/md";
import FooterImages from "../../Components/FooterImages";
import ImageSlider from "../../Components/ImageSlider";

gsap.registerPlugin(ScrollTrigger);

// const vehImages = [
//   {
//     id: 1,
//     image: "vehicle/3.jpg",
//   },
//   {
//     id: 2,
//     image: "vehicle/4.jpg",
//   },
//   {
//     id: 3,
//     image: "vehicle/6.jpg",
//   },
//   {
//     id: 4,
//     image: "vehicle/7.jpg",
//   },
//   {
//     id: 5,
//     image: "vehicle/8.jpg",
//   },
//   {
//     id: 6,
//     image: "vehicle/9.jpg",
//   },
//   {
//     id: 7,
//     image: "vehicle/5.jpg",
//   },
// ];

const vehImages = [
  {
    id: 1,
    image: "vhs/vh1.jpg",
  },
  {
    id: 2,
    image: "vhs/vh2.jpg",
  },
  {
    id: 3,
    image: "vhs/vh3.jpg",
  },
  {
    id: 4,
    image: "vhs/vh4.jpg",
  },
  {
    id: 5,
    image: "vhs/vh5.jpg",
  },
  {
    id: 6,
    image: "vhs/vh6.jpg",
  },
  {
    id: 7,
    image: "vhs/vh7.jpg",
  },
  {
    id: 8,
    image: "vhs/vh9.jpg",
  },
];

const otherImages = [
  // {
  //   id: 1,
  //   image: "epicGrand/1.jpg",
  // },
  // {
  //   id: 2,
  //   image: "shadeSafari/2.jpg",
  // },
  // {
  //   id: 3,
  //   image: "shadeSafari/2.jpg",
  // },
  {
    id: 4,
    image: "swalaCamp/10.jpg",
  },
];

const BackgroundHome = ({ images }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const imageElements = container.querySelectorAll(".imgt");

    const action = gsap.timeline({ repeat: 0, repeatDelay: 0 });

    imageElements.forEach((imageElement, index) => {
      action.to(imageElement, {
        autoAlpha: 1,
        duration: 1.5,
        delay: index === 0 ? 0 : 3,
      });
    });

    action.play();

    return () => {
      action.kill();
    };
  }, [images]);

  return (
    <div
      ref={containerRef}
      id="slideshow-container"
      className="img-container"
      style={{ height: "60vh", width: "100%", position: "relative" }}
    >
      {images?.map((imageSrc, index) => (
        <div
          key={index}
          className="imgt d-flex justify-content-center align-items-center"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${require(`../../Images/accomodataions//${imageSrc.image}`)})`,
            backgroundSize: "cover",
            height: "90%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            opacity: index === 0 ? 1 : 0,
          }}
        >
          <div className="text-light fw-bold fs-2">
            Discover the World, Unforgettable Journeys
          </div>
        </div>
      ))}
    </div>
  );
};

const BackgroundHome2 = ({ images }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const imageElements = container.querySelectorAll(".imgt");

    const action = gsap.timeline({ repeat: -1, repeatDelay: 3 });

    imageElements.forEach((imageElement, index) => {
      action.to(imageElement, {
        autoAlpha: 1,
        duration: 1.5,
        delay: index === 0 ? 0 : 3,
      });
    });

    action.play();

    return () => {
      action.kill();
    };
  }, [images]);

  return (
    <div
      ref={containerRef}
      id="slideshow-container"
      className="img-container"
      style={{ height: "60vh", width: "100%", position: "relative" }}
    >
      {images?.map((imageSrc, index) => (
        <div
          key={index}
          className="imgt d-flex justify-content-center align-items-center"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${require(`../../Images/accomodataions//${imageSrc.image}`)})`,
            backgroundSize: "cover",
            height: "90%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            opacity: index === 0 ? 1 : 0,
          }}
        >
          <span className="fw-bold text-light fs-3">
            Unforgettable Journeys with Remarkable Rides
          </span>
        </div>
      ))}
    </div>
  );
};

const OurGuides = ({ isMobile, isTablet }) => {
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const section5Ref = useRef(null);
  const section6Ref = useRef(null);
  const section7Ref = useRef(null);

  useEffect(() => {
    gsap.from(section1Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section1Ref.current,
        start: "top 100%", // Adjust the start position as needed
        end: "bottom 20%", // Adjust the end position as needed
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section2Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section2Ref.current,
        start: "top 100%",
        end: "bottom 100%",
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section3Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section3Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });
    gsap.from(section4Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section4Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section5Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section4Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section6Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section6Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section7Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section7Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });
  }, []);

  return (
    <div className="col-12 d-flex flex-column justify-content-center align-items-center">
      <div
        className={`${
          isMobile ? "col-12  p-2" : isTablet ? "col-10" : "col-8"
        } section1`}
        ref={section1Ref}
      >
        <div className="col-12 mb-3 text-center h3">OUR GUIDES</div>
        <div className="col-12 text-center">
          <p>
            Safari guides are one of our most valuable resources and crucial
            part of a successful safari. Hence, our policy is to employ those
            with the best potential performance and discipline. All our guides
            are fluent in English and Swahili. Some can converse in other
            foreign languages including, German, Spanish, French and Italian.
            With their help, our clients will learn about wildlife, flora &
            fauna as well as acquiring first-hand experience of African
            hospitality. We have regular training to keep our guides abreast in
            clients’ safety and to ensure that the highest standards services
            are achieved.
          </p>
        </div>
      </div>
      <div
        className={`${
          isMobile ? "col-12  p-2" : isTablet ? "col-10" : "col-8"
        } mt-3 section2 `}
        ref={section2Ref}
      >
        <div className="col-12 text-center h3">OUR RESPONSIBILITIES</div>
        {/* <div className="col-12 text-center h3">OUR RESPONSIBILITIES</div> */}
        <div className="col-12 d-flex flex-column">
          <div className="mb-2">• In-charge of driving the safari vehicle.</div>
          <div className="mb-2">
            • Provide briefing on your safari tour before prior to departure.
          </div>
          <div className="mb-2">
            • All our guides are well-trained in tracking wildlife and also have
            an intensive knowledge of animal behavior patterns and geography.
          </div>
          <div className="mb-2">
            • Provide information about each parks visited, animals spotted, and
            all other educational and interesting information about African
            Wildlife in general.
          </div>
          <div className="mb-2">
            • It’s our interest and our duty to ensure that every client ends
            their tour happy and satisfied with our arrangements and be our good
            ambassadors to others and come back to us again.
          </div>
        </div>
      </div>
    </div>
  );
};

const OurServices = ({ isMobile, isTablet }) => {
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const section5Ref = useRef(null);
  const section6Ref = useRef(null);
  const section7Ref = useRef(null);
  const section8Ref = useRef(null);
  const section9Ref = useRef(null);
  const section10Ref = useRef(null);

  useEffect(() => {
    gsap.from(section1Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section1Ref.current,
        start: "top 100%", // Adjust the start position as needed
        end: "bottom 20%", // Adjust the end position as needed
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section2Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section2Ref.current,
        start: "top 100%",
        end: "bottom 100%",
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section3Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section3Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });
    gsap.from(section4Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section4Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section5Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section4Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section6Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section6Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section7Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section7Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });
    gsap.from(section8Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section8Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });
    gsap.from(section9Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section9Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section10Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section10Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });
  }, []);

  return (
    <div className="col-12 d-flex flex-column justify-content-center align-items-center">
      <div
        className={`${
          isMobile ? "col-12  p-2" : isTablet ? "col-10" : "col-8"
        } section1`}
        ref={section1Ref}
      >
        <div className="col-12 text-center h2 fw-bold">ABOUT US</div>
        <div className="col-12  text-center">
          <p>
            360 Extreme Adventures is a safari operator based in Tanzania. We
            are a well-established founded by Abeid Mohamed who worked as a tour
            guide before founding the company. During his career as a guide, he
            understood the travelers’ decision to visit Africa is precious and
            wanted to make sure every traveler’s dream come true, then he
            started a company where people from around the world can reach and
            see the beauty of this country. Abeid is also deeply knowledgeable
            of the fauna and flora, culture and traditions of Tanzania. With the
            combination of Abeid’s great personality, gentle nature, and acute
            mind, driven as it is to excellence, 360 Extreme Adventures we
            provide top-quality tours and safaris that leave nothing to chance,
            ensuring that your experience in Tanzania is truly the holiday of a
            life time.
          </p>
        </div>
      </div>
      <div
        className={`${
          isMobile ? "col-12  p-2" : isTablet ? "col-10" : "col-8"
        } mt-3 section2`}
        ref={section2Ref}
      >
        <div className="col-12 text-center h3">OUR TEAM</div>
        <div className="col-12 text-center">
          <p>
            360 Extreme Adventures we a committed to provide countless
            remarkable experiences for unforgettable holiday, guided by our team
            of professional and well-trained tour guides and company employees.
            We are incredibly proud of our country and everything it has to
            offer and sharing it with people from all over the world. With our
            team we provide tours with the best value for money, and we work
            hand-in-hand with clients to assure they will get the most fitting
            itinerary that serves their needs. We make sure to be able to ride
            the continuous change of the modern times through regular staff
            training. We implement our staff development after our customer's
            different way of life to provide a service at par with client
            expectations.
          </p>
        </div>
      </div>
      <div
        className={`${
          isMobile ? "col-12  p-2" : isTablet ? "col-10" : "col-8"
        } mt-3 section3`}
        ref={section3Ref}
      >
        <div className="col-12 text-center fw-bold h2">WHY TRAVEL WITH US</div>
        <div className="col-12 d-flex flex-column">
          <div className="mb-2">
            • First and foremost, we offer you a “No Worries Guarantee.”
          </div>
          <div className="mb-2">
            • An incredible guide (full-time). Our Guides are incredible with
            high level of skill, experience, stamina and interpersonal skills.
            They share our passion for tanzania safaris and everything they have
            to offer.
          </div>
          <div className="mb-2">
            • We provide 24/7 customer service support. We listen to what our
            clients want and do our best to create your perfect Tanzania safari
            tour.
          </div>
          <div className="mb-2">
            • We provide briefing upon arrival and ensure you are well
            taken-cared of until your flight departure.
          </div>
          <div className="mb-2">
            • We operate our own fleet of vehicles and employ our own safari
            guides which makes our prices competitive as it eliminates
            commission to third parties.
          </div>
          <div className="mb-2">
            • With our passion and our sincere desire to offer highly-satisfying
            tours, we provide a wide variety of major safari tours including
            wedding and honeymoon packages. Mount Kilimanjaro tours and Zanzibar
            beach holidays.
          </div>
          <div className="mb-2">
            • We believe that nothing is more important to us than to fulfill
            our clients’ dream of a once-in-a-lifetime African safari.
          </div>
        </div>
      </div>
      <div
        className={`${
          isMobile ? "col-12  p-2" : isTablet ? "col-10" : "col-8"
        } mt-3 section4`}
        ref={section4Ref}
      >
        <div className="col-12 text-center ">
          <div className="col-12 mt-3">
            <div className="col-12 text-center h2 fw-bold">OUR SERVICES</div>
            <div className="col-12 d-flex flex-column">
              <div className="mt-2 h5" style={{ letterSpacing: "2px" }}>
                SAFARI OPTIONS
              </div>
              <div className="mt-2">
                <p>
                  We offer a wide range of excellent safari options. You can
                  choose between safari packages, or you can customize your tour
                  with the help of our team. Make your safari dream come true
                  with us, and witness stunning African landscapes, diverse
                  cultures, encounter wildlife and do various activities.
                </p>
              </div>
            </div>
            <div
              className="col-12 d-flex flex-column mt-3 section5"
              ref={section5Ref}
            >
              <div className="mt-2 h5" style={{ letterSpacing: "2px" }}>
                DEPARTURES
              </div>
              <div className="mt-2">
                <p>
                  All our African Safaris depart daily and are based on a
                  minimum of 2 persons. Prices will be lower with 3 or more
                  participants. Please advise us of the number travelling when
                  you make an enquiry so that we can provide you with an
                  accurate quotation.
                </p>
              </div>
            </div>
            <div
              className="col-12 d-flex flex-column mt-3 section6"
              ref={section6Ref}
            >
              <div className="mt-2 h5" style={{ letterSpacing: "2px" }}>
                INCENTIVE TRAVEL
              </div>
              <div className="mt-2">
                <p>
                  We also arrange for incentive travel packages, for your
                  employees, affiliates, or partners at reasonable costs.
                </p>
              </div>
            </div>
            <div
              className="col-12 d-flex flex-column mt-3 section7"
              ref={section7Ref}
            >
              <div className="mt-2 h5" style={{ letterSpacing: "2px" }}>
                TRANSPORTATION
              </div>
              <div className="mt-2">
                <p>
                  360 Extreme Adventures is one of the few companies that has
                  its own fleet of vehicles. These are 7-seater custom safari
                  Land Cruisers with pop-up roof for 360 degree viewing. The
                  vehicles are well maintained for comfort and safety. Each unit
                  is equipped with 2-way long range radios for easy
                  communication.
                </p>
              </div>
            </div>
            <div
              className="col-12 d-flex flex-column mt-3 section8"
              ref={section8Ref}
            >
              <div className="mt-2 h5" style={{ letterSpacing: "2px" }}>
                ACCOMODATION
              </div>
              <div className="mt-2">
                <p>
                  Accommodation options range from 3 to 5 star lodges depending
                  on your budget and preferences. In the same way, we can offer
                  campign options from the most basic and adventurous to luxury
                  tented camps.
                </p>
              </div>
            </div>
            <div
              className="col-12 d-flex flex-column mt-3 section9"
              ref={section9Ref}
            >
              <div className="mt-2 h5" style={{ letterSpacing: "2px" }}>
                ALTERATIONS TO TOURS
              </div>
              <div className="mt-2">
                <p>
                  The right is reserved to change any route or arrangements
                  should conditions require to offer substitutes of equal value
                  or to cancel the operation of any scheduled safari, in which
                  event a reasonable amount will be refunded.
                </p>
                <p className="mt-2">
                  ‘’In an increasingly competitive industry, we don't compromise
                  the comfort of our clients, we understand that by offering
                  superior customer service and professionalism, we can provide
                  unrivaled levels of services.’’
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const OurVehicles = ({ isTablet, isMobile }) => {
  const section1Ref = useRef(null);

  useEffect(() => {
    gsap.from(section1Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section1Ref.current,
        start: "top 100%", // Adjust the start position as needed
        end: "bottom 20%", // Adjust the end position as needed
        toggleActions: "play none none reverse",
      },
    });
  }, []);

  return (
    <div className="col-12 d-flex flex-column justify-content-center align-items-center">
      <div
        className={`${
          isMobile ? "col-12  p-2" : isTablet ? "col-10" : "col-8"
        } mt-4`}
        ref={section1Ref}
      >
        <div className="col-12 h2 fw-bold text-center">OUR SAFARI VEHICLES</div>
        <div>
          <ImageSlider
            images={vehImages}
            isMobile={isMobile}
            isTablet={isTablet}
          />
        </div>
        <div className="col-12 mt-3 text-center">
          <p>
            All our vehicles have been customized to provide optimum safari
            experience. Serviced and maintained to a very high standard before
            and after every safari. This does away with the problems which may
            compromise the quality of services. We believe every guest would
            like to have the most comfortable vehicles on safari. In response to
            the clients’ needs, our safari vehicles are designed for maximum
            space and comfort.
          </p>
        </div>
        <div className="col-12 mt-5 text-center h4 fw-bold">
          EXTENDED & WIDER
        </div>
        <div className="col-12 mt-2 mb-3 text-center">
          <p>
            We extend and widened the body of the vehicles to allow more space
            to move around and to get the best photographic angles without
            bumping into each other.
          </p>
        </div>
        <div className="col-12 mt-5 text-center h4 fw-bold">LARGER WINDOWS</div>
        <div className="col-12 mt-2 mb-3 text-center">
          <p>
            We made large sliding windows to facilitate good viewing experience
            and convenient photography during game drives.{" "}
          </p>
        </div>
        <div className="col-12 mt-5 text-center h4 fw-bold">POP UP ROOF</div>
        <div className="col-12 mt-2 mb-3 text-center">
          <p>
            Pop-up roofs for 360 degree viewing and shade from the sun. The roof
            can be removed so that you can stand up and have a better wildlife
            viewing.{" "}
          </p>
        </div>
        <div className="col-12 mt-5 text-center h4 fw-bold">
          POWER INVERTERS
        </div>
        <div className="col-12 mt-2 mb-3 text-center">
          <p>
            All our vehicles have 110v and 220v power sockets to make it
            possible to charge your electronic devices. Please bring travel
            adaptors to ensure they are suitable for your appliances / devices.
          </p>
        </div>
        <div className="col-12 mt-5 text-center h4 fw-bold">FIRST AID KIT</div>
        <div className="col-12 mt-2 mb-3 text-center">
          <p>
            Every safari vehicle is equipped with a first aid kit. Our guides
            are trained to use this in case of emergencies.
          </p>
        </div>
        <div className="col-12 mt-5 text-center h4 fw-bold">COOLER BOX</div>
        <div className="col-12 mt-2 text-center">
          <p>
            Our vehicles have a small fridge to keep beverages cold during
            safari. This is not to be taken for granted especially after a long
            day out in the savannah.
          </p>
        </div>
        <div className="col-12 mt-5 text-center h4 fw-bold">SPARE TIRES</div>
        <div className="col-12 mt-2 mb-3 text-center">
          <p>
            We carry two spare tires. This is better than having one due to poor
            roads in some areas and presence of acacia thorns among others.
          </p>
        </div>
      </div>
    </div>
  );
};

const PaymentAndTerms = ({ isMobile, isTablet }) => {
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const section5Ref = useRef(null);
  const section6Ref = useRef(null);
  const section7Ref = useRef(null);
  const section8Ref = useRef(null);
  const section9Ref = useRef(null);
  const section10Ref = useRef(null);

  useEffect(() => {
    gsap.from(section1Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section1Ref.current,
        start: "top 100%", // Adjust the start position as needed
        end: "bottom 20%", // Adjust the end position as needed
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section2Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section2Ref.current,
        start: "top 100%",
        end: "bottom 100%",
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section3Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section3Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });
    gsap.from(section4Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section4Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section5Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section4Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section6Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section6Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section7Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section7Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });
    gsap.from(section8Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section8Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });
    gsap.from(section9Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section9Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section10Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section10Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });
  }, []);
  return (
    <div className="col-12  d-flex flex-column justify-content-center align-items-center">
      <div
        className={`${
          isMobile ? "col-12  p-2" : isTablet ? "col-10" : "col-8"
        } section1`}
        ref={section1Ref}
      >
        <div className="col-12 text-center mb-3 fs-3 fw-bold">
          Payment and Terms
        </div>
        <div className="col-12 d-flex flex-column mt-5">
          <div className="col-12 fs-5 fw-bold">Payment Options</div>
          <div className="col-12 mt-1 ms-2">• Online Credit Card Payment</div>
          <div className="col-12 mt-1 ms-2">• Direct Wire Transfer</div>
          <div className="col-12 text-center d-flex justify-content-center pb-4">
            <div className="col-" style={{ cursor: "pointer" }}>
              <Tooltip title="America Express">
                <a
                  href="https://www.americanexpress.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={expressCard}
                    style={{ width: "35px", height: "35px" }}
                  />
                </a>
              </Tooltip>
            </div>
            <div className="col-  pe-2 ps-2" style={{ cursor: "pointer" }}>
              <Tooltip title="Visa">
                <img src={visaCard} style={{ width: "35px", height: "35px" }} />
              </Tooltip>
            </div>
            <div className="col-  pe-2 ps-2" style={{ cursor: "pointer" }}>
              <Tooltip title="Credit Card">
                <a
                  href="https://www.bankofamerica.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={creditCard}
                    style={{ width: "35px", height: "35px" }}
                  />
                </a>
              </Tooltip>
            </div>
            <div className="col-  ps-2">
              <Tooltip title="Wire Transfer">
                <a
                  href="https://www.bankofamerica.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={wireCard}
                    style={{ width: "35px", height: "35px" }}
                  />
                </a>
              </Tooltip>
            </div>
          </div>
          <div className="col-12 mt-3 text-center fw-bold">
            By proceeding with payment, please make sure to read guidelines
            carefully
          </div>
        </div>
        <div className="col-12 d-flex flex-column mt-4">
          <div className="col-12 fs-3 fw-bold text-center">
            Guidelines & Faqs
          </div>
          <div className="col-12 h6 mt-3 fw-bold fs-4 ">PAYMENTS TERMS</div>
          <div className="col-12 mt-1 ms-3 fs-6">
            (A) Online Credit Card Payment{" "}
          </div>
          <div className="col-12 mt-1  ms-3 fs-6">
            (B) Direct Wire Transfer{" "}
          </div>
          <div className="col-12 mt-1  ms-3 fs-6">
            (C) Cancellation Policies & Fees{" "}
          </div>
        </div>
        <div className="col-12 d-flex ms-3 flex-column mt-3">
          <div className="col-12 h5 fw-bold">
            {" "}
            <MdIcons.MdOutlineSubdirectoryArrowRight color="black" /> (A){" "}
          </div>
          <div className="col-12 d-flex ms-2 flex-column  mt-3 ">
            <div className="col-12 h6 ">
              1. What types of cards are accepted?
            </div>
            <div className="ps-3">
              <p>We accept Visa, Mastercard, and American Express</p>
            </div>
          </div>
          <div className="col-12 d-flex flex-column  mt-3 ">
            <div className="col-12 h6 ">2. Why is there a 4% charge?</div>
            <div className="text-center ps-3">
              <p>
                This mode of payment has a 4% bank surcharge that has to be
                added on top of the original amount. The 4% charge does not come
                from 360 Extreme Adventures, it is a bank surcharge for credit
                card transactions
              </p>
            </div>
          </div>
          <div className="col-12 d-flex flex-column  mt-3 ">
            <div className="col-12 h6 ">
              3. Why should I make payment using credit card?
            </div>
            <div className="text-center ps-3">
              <p>
                Credit card payment is the fastest and easiest method of making
                payments to us. We can immediately get an approval once the
                payment was processed. This payment method helps us confirm
                reservations easily.
              </p>
            </div>
          </div>

          <div className="col-12 d-flex flex-column ms-2  mt-3 ">
            <div className="col-12 h6 ">
              4. How would I know if the credit card payment is successful?
            </div>
            <div className="text-center ps-3">
              <p>
                After you input the card details thru the secured site, you’ll
                see the approval and the online receipt that you must print out
                for your records. Kindly send us a copy of the payment receipt
                to confirm your reservation promptly.
              </p>
            </div>
          </div>
          <div className="col-12 d-flex flex-column  mt-3 ">
            <div className="col-12 h6 ">
              5. What happens if my credit card transaction fails?
            </div>
            <div className="text-center ps-3">
              <p>
                If your credit card transaction fails, you may need to contact
                your bank to authorize payments to Tanzania. Often times, banks
                may put a caveat to protect their customers from fraudulent
                transactions in foreign countries
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 d-flex flex-column mt-3">
          <div className="col-12 h5 fw-bold">
            {" "}
            <MdIcons.MdOutlineSubdirectoryArrowRight color="black" /> (B)
          </div>
          <div className="col-12 mt-2 text-center">
            <p>
              Below are the details you’ll need to process a direct wire
              transfer to our account. Please note that using this method, bank
              charges will be shouldered by the client. Kindly ensure that the
              final account name is mentioned in the transfer copy. This payment
              method may take a minimum of 7 business days to complete.
            </p>
          </div>
          <div className="col-12 d-flex flex-column ms-4 mt-3 ">
            <div className="col-12 h6 ">1. What’s the Bank Details?</div>
            <div className="ps-3">
              <p>
                Account Name: <span className="text-danger">Xxxxxxxx</span>{" "}
                Limited Bank:{" "}
                <span className="text-danger">
                  Standard Chartered Bank (T) Limited
                </span>{" "}
                Branch:{" "}
                <span className="text-danger">
                  International House Branch – Dar-es-Salaam Bank
                </span>{" "}
                Address:{" "}
                <span className="text-danger">Corner Garden Avenue/Ohio</span>{" "}
                Street P.O. Box <span className="text-danger">10716</span>{" "}
                Tanzania Account Number:{" "}
                <span className="text-danger">XXXxxxx</span> Swift Code:{" "}
                <span className="text-danger">XXXXXxxxx</span>
              </p>
            </div>
          </div>
          <div className="col-12 d-flex flex-column ms-4  mt-3 ">
            <div className="col-12 h6 ">
              2. How do I make a direct wire transfer?
            </div>
            <div className="text-center ps-2">
              <p>
                A Direct Wire Transfer can be done thru online banking or by
                visiting your local bank. It is also imperative that the
                beneficiary’s account details are accurate to avoid disputes.
              </p>
            </div>
          </div>
          <div className="col-12 d-flex flex-column   mt-3 ">
            <div className="col-12 h6 ms-4">
              3. How much are bank charges for the beneficiary bank account?
            </div>
            <div className="text-center ">
              <p>
                Depending on the bank used to process the wire transfer,
                approximately $25 – $75 should be kept as a provision for the
                beneficiary bank account charges.
              </p>
            </div>
          </div>

          <div className="col-12 d-flex ms-4 flex-column  mt-3 ">
            <div className="col-12 h6 ">
              4. What’s next after the wire transfer was completed?
            </div>
            <div className="text-center">
              <p>
                Once the wire transfer was completed successfully, you’ll need
                to send us a scanned copy of the transaction confirmation so
                that we can follow up thru our bank.
              </p>
            </div>
          </div>
          <div className="col-12 d-flex flex-column  mt-3 ">
            <div className="col-12 h6 ms-4">
              5. What’s the turn around time for the payment to get to us?
            </div>
            <div className="text-center ">
              <p>
                International Direct Wire Transfer may take up to 7 business day
                before it gets credited to our account.
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 d-flex flex-column mt-3 pb-3 ms-4">
          <div className="col-12 h5 fw-bold">
            {" "}
            <MdIcons.MdOutlineSubdirectoryArrowRight color="black" /> (C)
          </div>
          <div className="col-12 mt-2 text-center">
            <p>
              More than 120 days before the safari or tour starts, 25% off the
              payment. 119 to 90 days before the safari or tour starts, 50 % off
              the payment. 89 to 31 days before the safari or tour starts, 75 %
              off the payment. 30 days and below or No Show, 100% off total
              costs.
            </p>
            <p className="mt-3 ">
              Note: Tour packages are confirmed upon receipt of 30% deposit of
              the total cost of the package. Balance Payable in full 30 days
              prior to arrival. Full payment or 50% is required if the tour is
              booked less than 30 days.
            </p>
          </div>
          <div className="col-12 mt-3 text-center h6 fw-bold">Pay Now</div>
          {/* <div className="col-12 text-center ">Click to Pay</div> */}
          <div className="col-12 text-center d-flex justify-content-center pb-4">
            <div className="col-">
              <Tooltip title="America Express">
                <a
                  href="https://www.americanexpress.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={expressCard}
                    style={{ width: "35px", height: "35px" }}
                  />
                </a>
              </Tooltip>
            </div>
            <div className="col- pe-2 ps-2">
              <Tooltip title="Visa">
                <img src={visaCard} style={{ width: "35px", height: "35px" }} />
              </Tooltip>
            </div>
            <div className="col- pe-2">
              <Tooltip title="Credit Card">
                <a
                  href="https://www.bankofamerica.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={creditCard}
                    style={{ width: "35px", height: "35px" }}
                  />
                </a>
              </Tooltip>
            </div>
            <div className="col-">
              <Tooltip title="Wire Transfer">
                <a
                  href="https://www.bankofamerica.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={wireCard}
                    style={{ width: "35px", height: "35px" }}
                  />
                </a>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function AboutUsScreen({ data }) {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = data?.images ? data?.images : [];

  const [activeId, setActiveId] = useState("ourservices");

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [images.length]);

  const variants = {
    open: { opacity: 1, y: "10%" },
    closed: { opacity: 0, y: "-90%" },
  };

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);

  const toDiv1Scroll = () => {
    section1Ref.current.scrollIntoView();
  };

  const toDiv2Scroll = () => {
    section2Ref.current.scrollIntoView();
  };

  const toDiv3Scroll = (e, name) => {
    e.preventDefault();
    setActiveId(name);
    section3Ref?.current.scrollIntoView();
  };

  const toDiv4Scroll = () => {
    section4Ref.current.scrollIntoView();
  };

  const currentImage = images[currentImageIndex]?.image;
  const nextImageIndex = (currentImageIndex + 1) % images.length;
  const nextImage = images[nextImageIndex]?.image;

  const location = useLocation();
  useEffect(() => {
    const { hash } = location;
    if (hash && hash == "#paymentAndTerms") {
      setActiveId("paymentAndterms");
    }
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location]);

  return (
    <div
      className="aboutUsScreen pb-3"
      style={{
        paddingBottom: "10vw",
        minHeight: "100vh",
      }}
    >
      <BackgroundHome images={otherImages} />

      <div className="col-12 ">
        <div className="col-12">
          <div className="col-12   d-flex justify-content-center align-items-center">
            <div
              className={`${
                isMobile ? "col-12 p-3" : isTablet ? "col-11 p-3" : "col-8 p-4"
              } col-8  mt-4  crdShadow2   d-flex justify-content-center`}
            >
              <div className="col-12 d-flex justify-content-center align-items-center">
                <div
                  style={{
                    cursor: "pointer",
                    fontSize: isMobile ? "11px" : "15px",
                  }}
                  className="me-3 ms-3 "
                  onClick={(e) => toDiv3Scroll(e, "ourservices")}
                >
                  <span
                    className={
                      activeId == "ourservices"
                        ? "activeCls pb-2 text-nowrap"
                        : "text-nowrap pb-2"
                    }
                  >
                    Our Services
                  </span>
                </div>
                <div
                  className="me-3 ms-3"
                  style={{
                    cursor: "pointer",
                    fontSize: isMobile ? "11px" : "15px",
                  }}
                  onClick={(e) => toDiv3Scroll(e, "ourguides")}
                >
                  <span
                    className={
                      activeId == "ourguides"
                        ? "activeCls ps-3 pe-3 pb-2 text-nowrap"
                        : "ps-3 pe-3 pb-2 text-nowrap"
                    }
                  >
                    Our Guides
                  </span>
                </div>

                <div
                  className="me-3 ms-3 "
                  style={{
                    cursor: "pointer",
                    fontSize: isMobile ? "11px" : "15px",
                  }}
                  onClick={(e) => toDiv3Scroll(e, "paymentAndterms")}
                >
                  <span
                    className={
                      activeId == "paymentAndterms"
                        ? "text-nowrap activeCls pb-2"
                        : "text-nowrap pb-2"
                    }
                  >
                    Payment & Terms
                  </span>
                </div>
                <div
                  className="me-3 ms-3 "
                  style={{
                    cursor: "pointer",
                    fontSize: isMobile ? "11px" : "15px",
                  }}
                  onClick={(e) => toDiv3Scroll(e, "ourvehicles")}
                >
                  <span
                    className={
                      activeId == "ourvehicles"
                        ? "activeCls pb-2 text-nowrap"
                        : "pb-2 text-nowrap"
                    }
                  >
                    Our Vehicles
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-12 mt-3"
          style={{ minHeight: "100vh" }}
          ref={section3Ref}
          id="paymentAndTerms"
        >
          {activeId == "ourguides" ? (
            <div className="col-12">
              <OurGuides isMobile={isMobile} isTablet={isTablet} />
            </div>
          ) : activeId == "ourservices" ? (
            <div className="col-12">
              <OurServices isMobile={isMobile} isTablet={isTablet} />
            </div>
          ) : activeId == "ourvehicles" ? (
            <div className="col-12">
              <OurVehicles isMobile={isMobile} isTablet={isTablet} />
            </div>
          ) : activeId == "paymentAndterms" ? (
            <div className="col-12">
              <PaymentAndTerms isMobile={isMobile} isTablet={isTablet} />
            </div>
          ) : (
            <div className="col-12">
              <PaymentAndTerms />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AboutUsScreen;
