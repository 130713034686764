import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { getAllBookedSafarisAct, deleteByIdAct } from "../Actions/AdminPnAct";
import { USER_LOGOUT } from "../Constants/userConstants";
import { DELETE_BOOKED_SAFARI_BYID_RESET } from "../Constants/AdminPanelConst";
// import { getAllBookedSafarisAct } from "../Actions/AdminPnAct";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SwipeableViews from "react-swipeable-views";
import { Spinner } from "react-bootstrap";
import animationBadge from "../Assets/newLabel1.json";
import animationEmpty from "../Assets/emptybox.json";
import Lottie from "lottie-react";
import Badge from "@mui/material/Badge";
import "./adminInq.css";
const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  popup: {
    width: 650,
    height: 400,
    backgroundColor: "#fff",
    borderRadius: 5,
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "center",
    paddingBottom: 20,
  },
  popupBB: {
    width: 450,
    height: 200,
    backgroundColor: "#fff",
    borderRadius: 5,
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "center",
    paddingBottom: 10,
  },
  rowView: {
    display: "flex",
    flexDirection: "row",
  },
  colView: {
    display: "flex",
    width: "60px",
    flexDirection: "column",
    borderBottom: "1px solid #F5F5F5",
    marginRight: "10px",
  },

  colViewSN: {
    display: "flex",
    width: "25px",
    flexDirection: "column",
    borderBottom: "1px solid #F5F5F5 ",
    marginRight: "10px",
  },
  colViewID: {
    display: "flex",
    width: "30px",
    marginRight: "10px",
    flexDirection: "column",
    borderBottom: "1px solid #F5F5F5 ",
  },

  colViewWW: {
    display: "flex",
    width: "80px",
    flexDirection: "column",
    borderBottom: "1px solid #F5F5F5 ",
  },

  colViewItem1: {
    width: "50px",
  },
  colViewItem2: {
    width: "30px",
  },
};

const LoadigPopup = ({ typ }) => {
  return (
    <div clasName="p-3">
      <div clasName="">
        <div clasName="col-12">
          <div className="col-12 mt-5 d-flex flex-column align-items-center justify-content-center">
            <div>
              <Spinner
                size="lg"
                style={{ width: "11rem", height: "11rem" }}
                animation="border"
                variant="dark"
              ></Spinner>
            </div>
            <div className="mt-3">Preparing {typ} List please wait....</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SafariTb = ({ safaris, deleteHandler }) => {
  return (
    <table className="col-12 patnerListTable">
      <thead>
        <tr>
          <th>Name</th>
          <th>Country</th>
          <th>Phone</th>
          <th>Email</th>
          <th>Safari</th>
          <th>Booked On</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {!safaris?.length ? (
          <tr>
            <td colSpan="8">
              <div className="col-12 d-flex flex-column justify-contents-center">
                <Lottie
                  style={{ height: "11.5rem" }}
                  animationData={animationEmpty}
                />
                <span className="mt-2 fs-4 text-center col-12">
                  No any booked Safari available
                </span>
              </div>
            </td>
          </tr>
        ) : (
          safaris?.map((s) => {
            return (
              <tr key={s._id}>
                <td>
                  {s.id}. {s.firstName} {s.lastName}
                </td>
                <td>{s.country}</td>
                <td>{s.phone}</td>
                <td>{s.email}</td>
                <td className="fw-bold" style={{ color: "#000" }}>
                  {s.safariName}
                </td>
                <td>{s.createdAt}</td>
                <td>
                  {s.isNew ? (
                    <Badge
                      badgeContent={
                        <>
                          <Lottie
                            style={{ height: "2.5rem" }}
                            animationData={animationBadge}
                          />
                        </>
                      }
                      // color="light"
                    >
                      <Link to={`/inbox/${"safari"}/${s._id}`}>
                        <button className="btn-1 pt-1 pb-1 pe-2 ps-2">
                          Details
                        </button>
                      </Link>
                    </Badge>
                  ) : (
                    <Link to={`/inbox/${"safari"}/${s._id}`}>
                      <button className="btn-1 pt-1 pb-1 pe-2 ps-2">
                        Details
                      </button>
                    </Link>
                  )}
                </td>
                <td>
                  <i
                    style={{ cursor: "pointer" }}
                    onClick={(e) =>
                      deleteHandler(e, s._id, "safari", s.firstName)
                    }
                    className="fa fa-trash text-danger"
                  ></i>
                </td>
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  );
};

const InqTb = ({ inqs, deleteHandler }) => {
  return (
    <div>
      <table className="col-12 patnerListTable">
        <thead>
          <tr>
            <th>Name</th>
            <th>Country</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Sent On</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!inqs?.length ? (
            <tr>
              <td colSpan="7">
                <div className="col-12 d-flex flex-column justify-contents-center">
                  <Lottie
                    style={{ height: "11.5rem" }}
                    animationData={animationEmpty}
                  />
                  <span className="mt-2 fs-4 text-center col-12">
                    No any Inquires or messages available
                  </span>
                </div>
              </td>
            </tr>
          ) : (
            inqs?.map((s) => {
              return (
                <tr key={s._id}>
                  <td>
                    {s.id}. {s.firstName} {s.lastName}
                  </td>
                  <td>{s.country}</td>
                  <td>{s.phone}</td>
                  <td>{s.email}</td>
                  <td>{s.createdAt}</td>
                  <td>
                    {s.isNew ? (
                      <Badge
                        badgeContent={
                          <>
                            <Lottie
                              style={{ height: "3rem" }}
                              animationData={animationBadge}
                            />
                          </>
                        }
                        // color="light"
                      >
                        <Link to={`/inbox/${"inquire"}/${s._id}`}>
                          <button className="btn-1 pt-1 pb-1 pe-2 ps-2">
                            Details
                          </button>
                        </Link>
                      </Badge>
                    ) : (
                      <Link to={`/inbox/${"inquire"}/${s._id}`}>
                        <button className="btn-1 pt-1 pb-1 pe-2 ps-2">
                          Details
                        </button>
                      </Link>
                    )}
                  </td>
                  <td>
                    <i
                      style={{ cursor: "pointer" }}
                      onClick={(e) =>
                        deleteHandler(e, s._id, "inquire", s.firstName)
                      }
                      className="fa fa-trash text-danger"
                    ></i>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

const NewsLTb = ({ news }) => {
  return (
    <table className="col-12 patnerListTable">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Created On</th>
        </tr>
      </thead>
      <tbody>
        {!news?.length ? (
          <tr>
            <td colSpan="4">
              <div className="col-12 d-flex flex-column justify-contents-center">
                <Lottie
                  style={{ height: "11.5rem" }}
                  animationData={animationEmpty}
                />
                <span className="mt-2 fs-4 text-center col-12">
                  No any Email subscribed to news letters available
                </span>
              </div>
            </td>
          </tr>
        ) : (
          news?.map((s) => {
            return (
              <tr>
                <td key={s._id}>{s.name}</td>
                <td>{s.email}</td>
                <td>{s.createdAt}</td>
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  );
};

function AdminInquireScreen({ onHeightChange, isMobile, isTablet }) {
  const screenRef = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInformation = useSelector((state) => state.userInformation);
  const { userInfo, isLoggedIn } = userInformation;

  const AllBookedSafaris = useSelector((state) => state.AllBookedSafaris);
  const {
    loading: getSafarisLoading,
    success: getSafarisSuccess,
    error: getSafarisError,
    bookedSafaris,
  } = AllBookedSafaris;

  const CreateSafari = useSelector((state) => state.CreateSafari);
  const { success: addSafariSuccess } = CreateSafari;

  const deleteSFById = useSelector((state) => state.deleteSFById);
  const { loading: deleteLoading, success: deleteSafariSuccesss } =
    deleteSFById;

  const [index, setIndex] = useState(0);
  const [deletePop, setDeletePop] = useState(false);
  const [deletedID, setDeleteID] = useState("");
  const [categ, setCateg] = useState("");
  const [personName, setPersonName] = useState("");

  const deleteHandler = (e, id, categ, name) => {
    e.preventDefault();
    setDeleteID(id);
    setCateg(categ);
    setPersonName(name);
    setDeletePop(true);
  };

  const cancelDeleteHandler = () => {
    setDeleteID("");
    setCateg("");
    setPersonName("");
    setDeletePop(false);
  };

  const confirmdelete = (e) => {
    e.preventDefault();
    console.log("categ", categ);
    console.log("deleted", deletedID);
    dispatch(
      deleteByIdAct({
        typ: categ,
        tId: deletedID,
      })
    );
  };

  const handleSlideChange = (event, value) => {
    setIndex(index);
  };

  const handleChangeIndex = (index) => {
    setIndex(index);
    // setIndex(index);
  };

  // useEffect(() => {
  //   const height = screenRef.current.offsetHeight;
  //   onHeightChange(height);
  // }, [onHeightChange]);
  useLayoutEffect(() => {
    const height = screenRef.current.offsetHeight;
    onHeightChange(height);
  }, [onHeightChange]);

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    } else {
      if (deleteSafariSuccesss) {
        setDeleteID("");
        setPersonName("");
        setCateg("");
        setDeletePop(false);
        dispatch({
          type: DELETE_BOOKED_SAFARI_BYID_RESET,
        });
      }
      dispatch(getAllBookedSafarisAct());
    }
  }, [
    isLoggedIn,
    userInfo,
    USER_LOGOUT,
    addSafariSuccess,
    deleteSafariSuccesss,
  ]);

  const safaris = bookedSafaris?.safaris;
  const inqs = bookedSafaris?.inquires;
  const news = bookedSafaris?.newsLetters;

  const newSafar = [];
  const newInqs = [];
  const newNws = [];

  for (let i = safaris?.length - 1; i >= 0; i--) {
    newSafar?.push(safaris[i]);
  }

  for (let i = inqs?.length - 1; i >= 0; i--) {
    newInqs?.push(inqs[i]);
  }

  for (let i = news?.length - 1; i >= 0; i--) {
    newNws?.push(news[i]);
  }

  newSafar?.forEach((s, index) => {
    s.id = 1 + index;
  });

  newInqs?.forEach((s, index) => {
    s.id = 1 + index;
  });

  newNws?.forEach((s, index) => {
    s.id = 1 + index;
  });

  return (
    <div
      style={{ minHeight: "100vh" }}
      className=" adminScreen p-4"
      ref={screenRef}
    >
      {getSafarisLoading && (
        <div style={styles.overlay}>
          <div style={styles.popup}>
            <LoadigPopup
              typ={
                index == 0
                  ? "Booked Safaris"
                  : index == 1
                  ? "Inquires"
                  : "News Letter"
              }
            />
          </div>
        </div>
      )}
      {deletePop && (
        <div style={styles.overlay}>
          <div style={styles.popupBB} className="p-5">
            <div className="col-12 d-flex flex-column">
              Are you sure you want to delete{" "}
              {categ == "safari" ? "safari booked by" : "inquire sent by"}{" "}
              {personName} ?
            </div>
            <div className="mt-3 d-flex col-12">
              <button
                className="btn btn-danger  me-2 col-"
                onClick={cancelDeleteHandler}
              >
                cancel
              </button>
              <button
                className="btn btn-dark col-"
                onClick={(e) => confirmdelete(e)}
              >
                {deleteLoading && (
                  <Spinner
                    variant="light"
                    size="sm"
                    animation="border"
                  ></Spinner>
                )}
                delete
              </button>
            </div>
          </div>
        </div>
      )}

      <div className={` col-12 d-flex`}>
        <div className="col-7 d-flex pb-3 borderBottom">
          <span className="headingh1">Inbox</span>
        </div>
      </div>
      <div className="col-12 mt-2">
        <div className={`  d-flex  flex-column borderBottom`}>
          <div className="headingh3 fw-bold">General </div>
          <div className="">
            <p>Inbox Infos such as booked safaris,inquires,news letters etc</p>
          </div>
        </div>
      </div>

      <div className=" col-12 mt-4 card card-shadow ">
        <div className="col-8">
          <Tabs
            value={index}
            onChange={handleSlideChange}
            style={styles.tabs}
            sx={{ paddingLeft: "0", paddingRight: "0" }}
            className="col-12"
          >
            <Tab
              style={styles.tabsItem}
              label="Safaris"
              onClick={() => setIndex(0)}
              sx={{ fontSize: "9px", marginRight: 0, paddingRight: 0 }}
            />
            <Tab
              label="Inquires"
              onClick={() => setIndex(1)}
              sx={{ fontSize: "9px", marginLeft: 2, marginRight: 2 }}
            />
            <Tab
              label="News Letters"
              onClick={() => setIndex(2)}
              sx={{ fontSize: "9px", marginLeft: 2, marginRight: 2 }}
            />
          </Tabs>
        </div>
      </div>
      <div className="col-12 mt-4">
        <SwipeableViews index={index} onChangeIndex={handleChangeIndex}>
          <SafariTb safaris={newSafar} deleteHandler={deleteHandler} />
          <InqTb inqs={newInqs} deleteHandler={deleteHandler} />
          <NewsLTb news={newNws} />
        </SwipeableViews>
      </div>
    </div>
  );
}

export default AdminInquireScreen;
