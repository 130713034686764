import {
  SET_ACTIVE_ELEMEMT,
  SET_ACTIVE_ELEMEMT_LOADING,
  GET_ALL_OTHERINFOS_REQUEST,
  GET_ALL_OTHERINFOS_SUCCESS,
  GET_ALL_OTHERINFOS_FAIL,
  GET_ALL_OTHERINFOS_RESET,
  EDIT_OTHERINFOS_REQUEST,
  EDIT_OTHERINFOS_SUCCESS,
  EDIT_OTHERINFOS_FAIL,
  EDIT_OTHERINFOS_RESET,
  CREATE_SAFARI_BOOKING_REQUEST,
  CREATE_SAFARI_BOOKING_SUCCESS,
  CREATE_SAFARI_BOOKING_FAIL,
  CREATE_SAFARI_BOOKING_RESET,
  GET_ALL_BOOKED_SAFARIS_FAIL,
  GET_ALL_BOOKED_SAFARIS_REQUEST,
  GET_ALL_BOOKED_SAFARIS_SUCCESS,
  GET_BOOKED_SAFARI_BYID_REQUEST,
  GET_BOOKED_SAFARI_BYID_SUCCESS,
  GET_BOOKED_SAFARI_BYID_FAIL,
  DELETE_BOOKED_SAFARI_BYID_REQUEST,
  DELETE_BOOKED_SAFARI_BYID_SUCCESS,
  DELETE_BOOKED_SAFARI_BYID_FAIL,
  DELETE_BOOKED_SAFARI_BYID_RESET,
} from "../Constants/AdminPanelConst";

export const activeElmntRd = (state = { activeItem: {} }, action) => {
  switch (action.type) {
    case SET_ACTIVE_ELEMEMT_LOADING:
      return { success: true };

    case SET_ACTIVE_ELEMEMT:
      return { ...state, activeItem: action.payload };

    default:
      return state;
  }
};

export const editAllOtherInfoRd = (state = { editedAllInfos: [] }, action) => {
  switch (action.type) {
    case EDIT_OTHERINFOS_REQUEST:
      return { loading: true };

    case EDIT_OTHERINFOS_SUCCESS:
      return { loading: false, editedAllInfos: action.payload, success: true };

    case EDIT_OTHERINFOS_FAIL:
      return { loading: false, error: action.payload };

    case EDIT_OTHERINFOS_RESET:
      return { editedAllInfos: [] };

    default:
      return state;
  }
};

export const getAllOtherInfoRd = (state = { allInfos: [] }, action) => {
  switch (action.type) {
    case GET_ALL_OTHERINFOS_REQUEST:
      return { loading: true };

    case GET_ALL_OTHERINFOS_SUCCESS:
      return { loading: false, allInfos: action.payload, success: true };

    case GET_ALL_OTHERINFOS_FAIL:
      return { loading: false, error: action.payload };

    case GET_ALL_OTHERINFOS_RESET:
      return { allInfos: [] };

    default:
      return state;
  }
};

export const createSafariRd = (state = { createSafariMessage: [] }, action) => {
  switch (action.type) {
    case CREATE_SAFARI_BOOKING_REQUEST:
      return { loading: true };

    case CREATE_SAFARI_BOOKING_SUCCESS:
      return {
        loading: false,
        createSafariMessage: action.payload,
        success: true,
      };

    case CREATE_SAFARI_BOOKING_FAIL:
      return { loading: false, error: action.payload };

    case CREATE_SAFARI_BOOKING_RESET:
      return { createSafariMessage: [] };

    default:
      return state;
  }
};

export const getAllBookedSafarisRd = (
  state = { bookedSafaris: [] },
  action
) => {
  switch (action.type) {
    case GET_ALL_BOOKED_SAFARIS_REQUEST:
      return { loading: true };

    case GET_ALL_BOOKED_SAFARIS_SUCCESS:
      return { loading: false, bookedSafaris: action.payload, success: true };

    case GET_ALL_BOOKED_SAFARIS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const getBookedSafariByIdRd = (
  state = { bookedSafariById: [] },
  action
) => {
  switch (action.type) {
    case GET_BOOKED_SAFARI_BYID_REQUEST:
      return { loading: true };

    case GET_BOOKED_SAFARI_BYID_SUCCESS:
      return {
        loading: false,
        bookedSafariById: action.payload,
        success: true,
      };

    case GET_ALL_BOOKED_SAFARIS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const deleteByIdRd = (state = { deleteSafariById: [] }, action) => {
  switch (action.type) {
    case DELETE_BOOKED_SAFARI_BYID_REQUEST:
      return { loading: true };

    case DELETE_BOOKED_SAFARI_BYID_SUCCESS:
      return {
        loading: false,
        deleteSafariById: action.payload,
        success: true,
      };

    case DELETE_BOOKED_SAFARI_BYID_FAIL:
      return { loading: false, error: action.payload };

    case DELETE_BOOKED_SAFARI_BYID_RESET:
      return { deleteSafariById: [] };

    default:
      return state;
  }
};
