import React, { useEffect, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
const ImageSlider = ({ images, isMobile, isTablet }) => {
  return (
    <div className=" col-12 d-flex justify-content-center">
      <div className={isMobile || isTablet ? "col-12 p-2" : "col-10"}>
        <Carousel
          useKeyboardArrows={true}
          infiniteLoop={true}
          autoPlay={true}
          showIndicators={false}
          autoFocus={true}
          showStatus={false}
          width="100%"
          thumbWidth={150}
          dynamicHeight={false}
        >
          {images?.map((image, index) => (
            <div
              className="col-12 "
              style={{ height: "65vh", position: "relative" }}
            >
              <img
                fill
                // style={{ width: "100%", height: "100%" }}
                src={`${require(`../Images/accomodataions/${image.image}`)}`}
                alt={`Image ${index + 1}`}
              />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default ImageSlider;
