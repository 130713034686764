import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useMediaQuery } from "react-responsive";
import "./acco.css";
import { Link, useParams } from "react-router-dom";
import FooterImages from "../../Components/FooterImages";
import ImageSlider from "../../Components/ImageSlider";

gsap.registerPlugin(ScrollTrigger);

const BackgroundHome = ({ images, heading }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const imageElements = container.querySelectorAll(".imgt");

    const action = gsap.timeline({ repeat: -1, repeatDelay: 3 });

    imageElements.forEach((imageElement, index) => {
      action.to(imageElement, {
        autoAlpha: 1,
        duration: 1.5,
        delay: index === 0 ? 0 : 3,
      });
    });

    action.play();

    return () => {
      action.kill();
    };
  }, [images]);

  return (
    <div
      ref={containerRef}
      id="slideshow-container"
      className=" img-container"
      style={{ height: "60vh", width: "100%" }}
    >
      {images?.map((imageSrc, index) => (
        <div
          key={index}
          className="imgt d-flex align-middle text-center text-light fw-bold fs-2 justify-content-center  align-items-center "
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${require(`../../Images/accomodataions/${imageSrc.image}`)})`,
            backgroundSize: "cover", // or "cover" depending on your desired effect
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "100%",
            width: "100%%",
            opacity: index === 0 ? 1 : 0,
          }}
        >
          <div className="col-12 ">
            {heading}
            {/* <span className=" text-light">middle</span> */}
          </div>
        </div>
      ))}
    </div>
  );
};

function AccomodationDetail({ accos }) {
  const { name } = useParams();
  const div2Ref = useRef(null);
  const isBiggerScreen = useMediaQuery({ minWidth: 1824 });
  const isBigDesktop = useMediaQuery({ minWidth: 1224, maxWidth: 1823 });
  const isDesktop = useMediaQuery({ minWidth: 992, maxWidth: 1223 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const newarr = [];

  accos?.forEach((obj) => {
    Object.keys(obj)
      .filter((key) => key !== "summaryImages")
      .forEach((key) => {
        obj[key].forEach((acco) => {
          acco?.accoList?.forEach((c) => {
            const { name, name2, images, overview } = c;

            newarr.push({ name, name2, overview, images });
          });
        });
      });
  });

  const mainAcco = newarr?.filter((s) => s.name2 == name)?.[0];

  useEffect(() => {
    div2Ref.current.scrollIntoView();
  }, []);

  return (
    <div
      style={{
        paddingBottom: "10vw",
      }}
    >
      <div
        style={{
          minHeight: "100vh",
        }}
        ref={div2Ref}
        className="accoScreen pt-3 col-12"
      >
        <div className="fs-1 fw-bold col-12 text-center mb-3">
          {mainAcco?.name}
        </div>
        <ImageSlider
          images={mainAcco?.images}
          isMobile={isMobile}
          isTablet={isTablet}
        />
        <div className="col-12 d-flex mt-2 pt-0 justify-content-center align-items-center flex-column">
          <div
            className={`${
              isMobile ? "col-12 p-2" : isTablet ? "col-11" : "col-8"
            } text-center fw-bold fs-2 `}
          >
            Overview
          </div>
          <div
            className={`${
              isMobile ? "col-12 p-2" : isTablet ? "col-11" : "col-8"
            } mt-2 text-center`}
          >
            <p>{mainAcco?.overview}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccomodationDetail;
