export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const SET_LOGIN_STATUS_REQUEST = "SET_LOGIN_STATUS_REQUEST";
export const SET_LOGIN_STATUS_SUCCESS = "SET_LOGIN_STATUS_SUCCESS";
export const SET_LOGIN_STATUS_FAIL = "SET_LOGIN_STATUS_FAIL";
export const SET_LOGIN_STATUS_RESET = "SET_LOGIN_STATUS_RESET";

export const USER_REGISTRATION_REQUEST = "USER_REGISTRATION_REQUEST";
export const USER_REGISTRATION_SUCCESS = "USER_REGISTRATION_SUCCESS";
export const USER_REGISTRATION_FAIL = "USER_REGISTRATION_FAIL";
export const USER_REG_STORTE = "USER_REG_STORTE";

export const USER_CHANGEPASSWORD_REQUEST = "USER_CHANGEPASSWORD_REQUEST";
export const USER_CHANGEPASSWORD_SUCCESS = "USER_CHANGEPASSWORD_SUCCESS";
export const USER_CHANGEPASSWORD_FAIL = "USER_CHANGEPASSWORD_FAIL";
export const USER_CHANGEPASSWORD_RESET = "USER_CHANGEPASSWORD_RESET";

export const GET_USERINFO_REQUEST = "GET_USERINFO_REQUEST";
export const GET_USERINFO_SUCCESS = "GET_USERINFO_SUCCESS";
export const GET_USERINFO_FAIL = "GET_USERINFO_FAIL";

export const EDIT_USERPROFILE_REQUEST = "EDIT_USERPROFILE_REQUEST";
export const EDIT_USERPROFILE_SUCCESS = "EDIT_USERPROFILE_SUCCESS";
export const EDIT_USERPROFILE_FAIL = "EDIT_USERPROFILE_FAIL";
export const RESET_USER_PROFILE = "RESET_USER_PROFILE";
export const EDIT_USERPROFILE_RESET = "EDIT_USERPROFILE_RESET";

export const CREATE_USER_OTHERINFOS_REQUEST = "CREATE_USER_OTHERINFOS_REQUEST";
export const CREATE_USER_OTHERINFOS_SUCCESS = "CREATE_USER_OTHERINFOS_SUCCESS";
export const CREATE_USER_OTHERINFOS_FAIL = "CREATE_USER_OTHERINFOS_FAIL";

export const GET_USER_OTHERINFOS_REQUEST = "CREATE_USER_OTHERINFOS_REQUEST";
export const GET_USER_OTHERINFOS_SUCCESS = "CREATE_USER_OTHERINFOS_SUCCESS";
export const GET_USER_OTHERINFOS_FAIL = "CREATE_USER_OTHERINFOS_FAIL";

export const VIEW_USER_BYID_REQUEST = "VIEW_USER_BYID_REQUEST";
export const VIEW_USER_BYID_SUCCESS = "VIEW_USER_BYID_SUCCESS";
export const VIEW_USER_BYID_FAIL = "VIEW_USER_BYID_FAIL";
export const VIEW_USER_BYID_RESET = "VIEW_USER_BYID_RESET";

// ,
//     {
//       "id": 5,
//       "title": "Lake Manyara National Park",
//       "description": "60% of this park is covered by the lake and the remaining 40% is characterized by acacia woodlands, savannah grasslands, rocky escarpment of the rift valley and forest. The forest at Lake Manyara is always green due to ground water that finds its way from the Ngorongoro highlands. The park is widely renowned for its wealth of lesser and greater flamingoes by its soda lake, amazing tree-climbing lions and huge baboons population. Other features include a hippopotamus pool and hot springs. There is a hot water spring on the western shores of lake Manyara called ‘’Maji Moto’’. This 60 °C geothermal feature is formed by underground water that passes through hot volcanic magma rocks of the great rift valley. Many tourists who visit Lake Manyara National Park also engage in different activities during their safari including nature walk which gives a closer encounter with the natural surroundings at the park, Canoeing - which take place when the water levels are favorable to allow the tourist to relax in the comfort of their boat and enjoy the view of park and rift valley escarpment and taking photography.",
//       "Images": [
//         {
//           "id": 1,
//           "image": "Arusha-NP-min.jpg"
//         },
//         {
//           "id": 2,
//           "image": "Arusha National Park waterbuck.jpg"
//         },
//         {
//           "id": 3,
//           "image": "arusha-park.jpg"
//         },
//         {
//           "id": 4,
//           "image": "colo.jpg"
//         }
//       ]
//     },
//     {
//       "id": 6,
//       "title": "Serval Wildlife",
//       "description": "A wildlife sanctuary & luxury ecotourism resort that offers an exclusive and unparalleled experience tucked away in the Siha district of Kilimanjaro, Tanzania. A 35-minute drive from Kilimanjaro International Airport, 60 minutes from Moshi town and approximately 90 minutes from Arusha City. The Sanctuary is currently home to a variety of different animals such as the Majestic African Lions, Calm Colobus Monkeys, Graceful Masai Giraffes, Nocturnal Bat-eared Foxes, Exquisite Elands, Oafish Ostriches, and Mischievous Blue & Vervet monkeys! Offering unforgettable direct interactions with a variety of free-roaming wild animals. They have ‘four stars’ luxury villas. Its 1100 sqft / 110 m2 villas feature panoramic views of Mount Kilimanjaro and Mount Meru. Each villa has porches and seating areas for the rescued wildlife to come say hello! The ecosystem thrives through direct revegetation of over 5000 hand planted endemic flora; beneficial to the animals. The place is over 1000 acres, prepared in phases and in accordance with the needs of the animals. Serval Wildlife provides outstanding memories. This place feels like heaven on earth, a once in a lifetime experience",
//       "Images": [
//         {
//           "id": 1,
//           "image": "Arusha-NP-min.jpg"
//         },
//         {
//           "id": 2,
//           "image": "Arusha National Park waterbuck.jpg"
//         },
//         {
//           "id": 3,
//           "image": "arusha-park.jpg"
//         },
//         {
//           "id": 4,
//           "image": "colo.jpg"
//         }
//       ]
//     },
//     {
//       "id": 7,
//       "title": "Ngorongoro Conservation Area",
//       "description": "The main highlight of Ngorongoro Conservation Area is the Ngorongoro Crater which is known as the world’s largest inactive volcanic caldera. The caldera is home to the Big Five and is one of the best places to see endangered black rhinos. Aside from its sheer wildlife density, Ngorongoro offers a scenic view perfect for picnic lunches in the wild. Further north from the crater you have the highlands plus the Olmoti and Empakaai Craters which are perfect locations for walking safaris. Further west from the crater you will find Olduvai Gorge, where the earliest remains of the human beings were found. A little further from Olduvai you will find the Ndutu Plains, home to wildebeest herds who calve in this area around late January and early February. This is also the area where you will find a healthy population of cheetahs and other carnivores who make this place their home when the herds are home!",
//       "Images": [
//         {
//           "id": 1,
//           "image": "Arusha-NP-min.jpg"
//         },
//         {
//           "id": 2,
//           "image": "Arusha National Park waterbuck.jpg"
//         },
//         {
//           "id": 3,
//           "image": "arusha-park.jpg"
//         },
//         {
//           "id": 4,
//           "image": "colo.jpg"
//         }
//       ]
//     },
//     {
//       "id": 8,
//       "title": "Lake Eyasi",
//       "description": "Lake Eyasi is a seasonal shallow endorheic Salt Lake on the floor of the Great Rift Valley. Lake Eyasi’s water levels vary greatly between the rainy and dry seasons. During the dry season the lake is virtually nonexistent and animals are forced to share what water is left, which makes for easier wildlife viewing. The lake can get quite deep during the rainy season and it attracts hippos who like to cool off in its brackish waters. Lake Eyasi is a wild, scenically stunning area where you can get a real insight into the way of life of some of Tanzania’s interesting tribes, most notably the Hadzabe and Datoga.",
//       "Images": [
//         {
//           "id": 1,
//           "image": "Arusha-NP-min.jpg"
//         },
//         {
//           "id": 2,
//           "image": "Arusha National Park waterbuck.jpg"
//         },
//         {
//           "id": 3,
//           "image": "arusha-park.jpg"
//         },
//         {
//           "id": 4,
//           "image": "colo.jpg"
//         }
//       ]
//     },
//     {
//       "id": 9,
//       "title": "Hadzabe",
//       "description": "Traditionally, they are viewed as hunter-foragers people who acquire nearly all of their food by hunting wild animals and foraging wild plant foods and honey. The Hadzabe have resisted the forcible settlement policies and most of their children have never seen a doctor or school - the bush provides for all their needs and is a class room for their offspring. A visit to them enables visitors to see their simple bush homes where a cave or tree canopy provides them with shelter. They live entirely off the bush and from hunting. The string on their lethal bows is often made from giraffe tendons and the arrows coated with a strong poison made from a certain tree. They kindle fires by rubbing wood. Some trees provide sap that repels mosquitoes. Others provide cures for snake bites & some like aloe vera is used to heal cuts. Roots provide a wide range of medicines and the mighty baobab fruits as a source of drink. A few hours spent with the bushmen makes the apparently inhospitable bush country come to life and to watch them hunt, a truly unique experience.",
//       "Images": [
//         {
//           "id": 1,
//           "image": "Arusha-NP-min.jpg"
//         },
//         {
//           "id": 2,
//           "image": "Arusha National Park waterbuck.jpg"
//         },
//         {
//           "id": 3,
//           "image": "arusha-park.jpg"
//         },
//         {
//           "id": 4,
//           "image": "colo.jpg"
//         }
//       ]
//     },
//     {
//       "id": 10,
//       "title": "Datoga",
//       "description": "The Datoga, like the Maasai, are pastoralists. However, unlike the Maasai, these pastoralists are also skilled silversmiths who supply the Hadzabe with iron tips, knives and spears in exchange for honey and fruits. Their origins lie in the Horn of Africa and they are thought to have immigrated some 3,000 years ago. They wear traditional dress decorated in colored beads and the women often have facial scarification for beauty. The Datoga blend in with their environment with outfits that are a reddish, brown color, similar to the soil, with reddish patched leather dresses, necklaces, beadwork and bracelets. Another cultural feature that distinguishes the Datoga from other tribes is the decorative facial scarification with circular patterns around their eyes.",
//       "Images": [
//         {
//           "id": 1,
//           "image": "Arusha-NP-min.jpg"
//         },
//         {
//           "id": 2,
//           "image": "Arusha National Park waterbuck.jpg"
//         },
//         {
//           "id": 3,
//           "image": "arusha-park.jpg"
//         },
//         {
//           "id": 4,
//           "image": "colo.jpg"
//         }
//       ]
//     },
//     {
//       "id": 11,
//       "title": "Serengeti National Park",
//       "description": "Perhaps the most popular and most photographed wildlife sanctuary in the world is Serengeti National Park. Its name causes echoes, drumrolls and chills down the spine because of its unparalleled wildlife splendor from the endless golden savannas to the magnanimous wildlife concentration and diversity including plenty of sightings of the Big Five to the world-famous Great Wildebeest Migration. It is a prime cheetah country that holds the most population of predators in the world. In fact, predator-prey interactions are common here all year round.",
//       "Images": [
//         {
//           "id": 1,
//           "image": "Arusha-NP-min.jpg"
//         },
//         {
//           "id": 2,
//           "image": "Arusha National Park waterbuck.jpg"
//         },
//         {
//           "id": 3,
//           "image": "arusha-park.jpg"
//         },
//         {
//           "id": 4,
//           "image": "colo.jpg"
//         }
//       ]
//     },

//     {
//       "id": 12,
//       "title": "Lake Natron",
//       "description": "The soda lake sits on the lowest point of the Great Rift Valley part in East Africa and is quite shallow, less than three metres deep, and varies in width depending on its water level. The temperatures in the lake mud can reach more than 40 degrees Celsius where evaporation causes high alkalinity. It is believed that the lake’s high alkaline environment is a barrier against predators trying to reach the flamingo nests. Lying on the base of the mountain, Lake Natron is said to be the only breeding area for the endangered Lesser Flamingoes that live in the Great Rift Valley and it is believed to be the world’s most important breeding site for the Lesser Flamingoes, accounting for 75% of the global population. The area around Lake Natron is often described as having a desolate and almost lunar landscape beauty. Walks around the lake and to the streams and waterfalls along the nearby escarpment, make for a fantastic adventure off the beaten track. ",
//       "Images": [
//         {
//           "id": 1,
//           "image": "Arusha-NP-min.jpg"
//         },
//         {
//           "id": 2,
//           "image": "Arusha National Park waterbuck.jpg"
//         },
//         {
//           "id": 3,
//           "image": "arusha-park.jpg"
//         },
//         {
//           "id": 4,
//           "image": "colo.jpg"
//         }
//       ]
//     },
//     {
//       "id": 13,
//       "title": "Saadani National Park",
//       "description": "Along the coast of Indian ocean in Tanzania ,lies one of the most beautiful Park, Saadani. Located only 130 km north of Dar es salaam and directly to the west of Zanzibar Island. Saadani is the only coastal wildlife sanctuary in East Africa, which not only means relaxing on Indian Ocean beaches after each safari, but provides the opportunity to observe Africa’s big game and birdlife interacting with the sea. Being the only National Park that harbors both marine and terrestrial habitats, Saadani measuring about 1100 sq. km in size, has a diverse population of mammals and birds. Elephant, leopard, lion, buffalo, giraffe, zebra, colobus monkey, hippo, crocodile and the rare Roosevelt sable can be seen here. Saadani offers a choice of nature walk, boat safari on the Wami River and driving safari in the savannah part of the park and enjoy the beautiful unspoiled beach that lies within the Park. It is not surprising to encounter animals on the beach in this coastal Park. Saadani has plenty of marine life and large numbers of wild animals.",
//       "Images": [
//         {
//           "id": 1,
//           "image": "Arusha-NP-min.jpg"
//         },
//         {
//           "id": 2,
//           "image": "Arusha National Park waterbuck.jpg"
//         },
//         {
//           "id": 3,
//           "image": "arusha-park.jpg"
//         },
//         {
//           "id": 4,
//           "image": "colo.jpg"
//         }
//       ]
//     },
//     {
//       "id": 14,
//       "title": "Bagamoyo",
//       "description": "Bagamoyo city is an old settlement. It is a place of considerable significance to world history, both as an entry point for Arab and European missionaries, explorers, and traders in East and Central Africa, and in the history of the infamous slave trade. Trade goods were first fish, salt and rubber and in the first half of the 19th century for ivory and the slaves. Traders came from the African interior on their way to Zanzibar. This explains the meaning of the word Bagamoyo (“Bwaga-Moyo”). In Kiswahili it means “lay down your heart”. Either the name might have been given due to the slave trade that passed through the city (“give up all hope”). Or because of the porters who rested in Bagamoyo after carrying 35 pounds of cargo from the Great Lakes region on their shoulders (“unload and rest”). Bagamoyo is now being considered for inclusion as one of the World Heritage sites, The government is working to conserve and protect the fascinating Gothic and Afro-Arabic the colonial ruins in and around Bagamoyo and to revive the city. Tranquil and its white sand beaches are considered some of the finest on the whole of the East African coast, Bagamoyo fascinating history make it a pleasant day or weekend trip.",
//       "Images": [
//         {
//           "id": 1,
//           "image": "Arusha-NP-min.jpg"
//         },
//         {
//           "id": 2,
//           "image": "Arusha National Park waterbuck.jpg"
//         },
//         {
//           "id": 3,
//           "image": "arusha-park.jpg"
//         },
//         {
//           "id": 4,
//           "image": "colo.jpg"
//         }
//       ]
//     },
//     {
//       "id": 15,
//       "title": "Mafia Island",
//       "description": "It is the ideal location for total relaxation in the sun, sand and sea. Mafia is Tanzania’s island paradise just to the south of Zanzibar. Covering an area of 822 square km, this beautiful Indian Ocean tropical island is famous for deep sea fishing and scuba diving. The pristine coral reefs are spectacular offering a colourful undersea adventure experience, the world’s most unique underwater seascape. Mafia offers extraordinary escapism. It has mangroves, sea-grass beds, lagoons and coastal forest. It is one of the most important marine habitats in the world. Watching Juani’s turtle hatchlings scuttle to the sea is quite magical, Secluded sandbars, whilst sailing or swimming alongside the annual whale shark migration, laidback vibe makes this island amazing and perhaps one of Africa’s greatest encounters. Other activities on the island include bird watching, and visits to local villages, historic archaeological sites, coastal forests, coconut plantations and traditional boat-building yards. ",
//       "Images": [
//         {
//           "id": 1,
//           "image": "Arusha-NP-min.jpg"
//         },
//         {
//           "id": 2,
//           "image": "Arusha National Park waterbuck.jpg"
//         },
//         {
//           "id": 3,
//           "image": "arusha-park.jpg"
//         },
//         {
//           "id": 4,
//           "image": "colo.jpg"
//         }
//       ]
//     },
//     {
//       "id": 16,
//       "title": "Zanzibar",
//       "description": "Zanzibar is an archipelago of historic Indian Ocean islands, awash with atmosphere and idyllic beach experiences. Pristine beaches, clear ocean waters, and historical destinations are the main highlights in Zanzibar. Summer is the best time to hit this part of Africa to make the most of that heat. It has a wide range of accommodation choices and plenty to see, do and discover. The island is the perfect place to relax after an exciting safari or after conquering Kilimanjaro. It is also perfect for a romantic holiday getaway, making it a great stop as part of an African Honeymoon Safari. Take a dip in Zanzibar’s sparkling tides, and witness the precious marine life under its salty seas. Zanzibar is the best pick if you want to experience a stunning African beach. You may also want to visit the Jozani Forest, where the famous red colobus monkey dwells. The region homes this rare primate, where you can also witness various birdlife. Visiting this destination wouldn’t be complete without learning its roots and history. Zanzibar is where Spice Island is located, which bears significant stories of the past. Wander the Stone Town, Tanzania where you can find the House of Wonders, National Museum of Zanzibar, and the Old Fort.",
//       "Images": [
//         {
//           "id": 1,
//           "image": "Arusha-NP-min.jpg"
//         },
//         {
//           "id": 2,
//           "image": "Arusha National Park waterbuck.jpg"
//         },
//         {
//           "id": 3,
//           "image": "arusha-park.jpg"
//         },
//         {
//           "id": 4,
//           "image": "colo.jpg"
//         }
//       ]
//     },
//     {
//       "id": 17,
//       "title": "Pemba",
//       "description": "Pemba is a hidden masterpiece filled with forests, mangrove swamps, hidden beaches, lagoons, tidal sandbanks and famous for its spices and coconuts. It is littered with the ruins, reminders of the Arab domination of the island in the 17th century. Pemba’s pristine beauty has been preserved so well, this rural island is a world away from the tourist crowds and its waters are among the best in the world for diving and fishing. It known as ‘clove island’ due to being the world’s main exporter of cloves (70% of all the world’s cloves are grown in Pemba). Visit the Ngezi Forest Reserve which is full of rare species of bird. Here you should be able to see Pemba’s white-eye, green pigeon, scops owl and sunbird. Endemic mammals in this small forest reserve are Pemba flying fox, Kirk’s red colobus, vervet monkey, blue duiker and marsh. Often overshadowed by its showier neighbor Zanzibar, Pemba sits quietly in the wings, waiting to be discovered. Make the small effort and you’ll find and island trove that blends culture, sights and smells witnessed only by the very few.",
//       "Images": [
//         {
//           "id": 1,
//           "image": "Arusha-NP-min.jpg"
//         },
//         {
//           "id": 2,
//           "image": "Arusha National Park waterbuck.jpg"
//         },
//         {
//           "id": 3,
//           "image": "arusha-park.jpg"
//         },
//         {
//           "id": 4,
//           "image": "colo.jpg"
//         }
//       ]
//     },
//     {
//       "id": 18,
//       "title": "Dar es Salaam City",
//       "description": "From a small Swahili town in the middle of the 19th century, Dar es Salaam has grown into a large metropolitan centre with a population of over 3,500,000 dwellers. The city population represents the over 120 tribes of Tanzania with their different cultural backgrounds coming together, alongside the oriental civilizations from the far and middle east, and the Europeans including early Portuguese visitors in the 16th Century, the Germans and later the British.All cultures at one time or another passed through Dar es Salaam. It is necessary to visit Dar es Salaam to understand the secret of letting different cultures live together in absolute harmony. Being the commercial capital of Tanzania, Dar es Salaam is also the main gateway to the nature reserves of southern Tanzania, which include the Selous Game Reserve and the National Parks of Mikumi, Ruaha and Udzungwa Mountains, Saadani and Kitulo. Dar es Salaam, meaning ‘The Haven of Peace’ in Arabic, has one of the best deep natural harbours in the world. It also serves as a convenient connection for travel to the islands of Mafia, Zanzibar and Pemba both by sea and air. A city tour of Dar es Salaam will take you to the National Museum, Nyerere Cultural Centre, the Village Museum, Mwenge Wood Carvings Market, and the Magogoni Fish Market, among other interesting spots",
//       "Images": [
//         {
//           "id": 1,
//           "image": "Arusha-NP-min.jpg"
//         },
//         {
//           "id": 2,
//           "image": "Arusha National Park waterbuck.jpg"
//         },
//         {
//           "id": 3,
//           "image": "arusha-park.jpg"
//         },
//         {
//           "id": 4,
//           "image": "colo.jpg"
//         }
//       ]
//     }
