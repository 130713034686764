import {
  GET_ALL_OTHERINFOS_REQUEST,
  GET_ALL_OTHERINFOS_SUCCESS,
  GET_ALL_OTHERINFOS_FAIL,
  GET_ALL_OTHERINFOS_RESET,
  SET_ACTIVE_ELEMEMT,
  SET_ACTIVE_ELEMEMT_LOADING,
  EDIT_OTHERINFOS_REQUEST,
  EDIT_OTHERINFOS_SUCCESS,
  EDIT_OTHERINFOS_FAIL,
  CREATE_SAFARI_BOOKING_REQUEST,
  CREATE_SAFARI_BOOKING_SUCCESS,
  CREATE_SAFARI_BOOKING_FAIL,
  GET_ALL_BOOKED_SAFARIS_REQUEST,
  GET_ALL_BOOKED_SAFARIS_SUCCESS,
  GET_ALL_BOOKED_SAFARIS_FAIL,
  GET_BOOKED_SAFARI_BYID_REQUEST,
  GET_BOOKED_SAFARI_BYID_SUCCESS,
  GET_BOOKED_SAFARI_BYID_FAIL,
  DELETE_BOOKED_SAFARI_BYID_FAIL,
  DELETE_BOOKED_SAFARI_BYID_RESET,
  DELETE_BOOKED_SAFARI_BYID_SUCCESS,
  DELETE_BOOKED_SAFARI_BYID_REQUEST,
} from "../Constants/AdminPanelConst";
import axios from "axios";

export const activeElmntAct = (itm) => async (dispatch, getState) => {
  dispatch({
    type: SET_ACTIVE_ELEMEMT_LOADING,
  });
  dispatch({
    type: SET_ACTIVE_ELEMEMT,
    payload: {
      item: itm,
    },
  });
  localStorage.setItem(
    "adminActiveItem",
    JSON.stringify(getState().AdminPanelItems.activeItem)
  );
};

export const editOtherInfoAct = (newInfo) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EDIT_OTHERINFOS_REQUEST,
    });

    const {
      userInformation: { userInfo },
    } = getState();

    const config = {
      headers: {
        // "Content-Type": "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(
      "https://360xtremeadventures.com/api/othersAdventure/main-content/",
      // "http://127.0.0.1:8000/api/othersAdventure/main-content/",
      newInfo,
      config
    );

    dispatch({
      type: EDIT_OTHERINFOS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EDIT_OTHERINFOS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getAllOtherInfoAct = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_ALL_OTHERINFOS_REQUEST,
    });

    const {
      userInformation: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      "http://127.0.0.1:8000/api/othersAdventure/get-allMain/",
      config
    );

    dispatch({
      type: GET_ALL_OTHERINFOS_SUCCESS,
      payload: data,
    });
    sessionStorage.setItem("allInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: GET_ALL_OTHERINFOS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const BookSafarisAct = (dt) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CREATE_SAFARI_BOOKING_REQUEST,
    });

    const {
      userInformation: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      "https://360xtremeadventures.com/api/othersAdventure/create-bookSafari/",
      // "http://127.0.0.1:8000/api/othersAdventure/create-bookSafari/",
      dt,
      config
    );

    dispatch({
      type: CREATE_SAFARI_BOOKING_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_SAFARI_BOOKING_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getAllBookedSafarisAct = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_ALL_BOOKED_SAFARIS_REQUEST,
    });

    const {
      userInformation: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      // "https://360xtremeadventures.com/api/othersAdventure/get-allSafaris/",
      "http://127.0.0.1:8000/api/othersAdventure/get-allSafaris/",
      config
    );

    dispatch({
      type: GET_ALL_BOOKED_SAFARIS_SUCCESS,
      payload: data,
    });
    sessionStorage.setItem("allBookedSafaris", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: GET_ALL_BOOKED_SAFARIS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getBookedSafarByIdAct = (dt) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_BOOKED_SAFARI_BYID_REQUEST,
    });

    const {
      userInformation: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `https://360xtremeadventures.com/api/othersAdventure/get-ById/`,
      // `http://127.0.0.1:8000/api/othersAdventure/get-ById/`,
      dt,
      config
    );

    dispatch({
      type: GET_BOOKED_SAFARI_BYID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_BOOKED_SAFARI_BYID_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteByIdAct = (dt) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_BOOKED_SAFARI_BYID_REQUEST,
    });

    const {
      userInformation: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `https://360xtremeadventures.com/api/othersAdventure/get-deleteById/`,
      // `http://127.0.0.1:8000/api/othersAdventure/get-deleteById/`,
      dt,
      config
    );

    dispatch({
      type: DELETE_BOOKED_SAFARI_BYID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_BOOKED_SAFARI_BYID_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
