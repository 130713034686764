import React, { useState, useEffect, useRef } from "react";
import "./home.css";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import bgImage from "../../Logos/bgImage.png";
import lodImg from "../../Images/lod.jpg";
import FooterImages from "../../Components/FooterImages";
import styled from "styled-components";

gsap.registerPlugin(ScrollTrigger);

const DialogImage = styled.img`
  width: 100%;
  height: 15vw;
  object-fit: cover;
  border-radius: 10px 10px 0 0;

  @media (max-width: 768px) {
    /* Mobile devices */
    height: 40vw;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    /* Tablets */
    height: 30vw;
  }
`;

const homeSumImages = [
  {
    id: 1,
    image: "1.jpg",
  },
  {
    id: 2,
    image: "2.jpg",
  },
  {
    id: 3,
    image: "3.jpg",
  },
  {
    id: 4,
    image: "4.jpeg",
  },
  {
    id: 5,
    image: "5.jpg",
  },
  {
    id: 6,
    image: "6.jpg",
  },
];

const BackgroundHome = ({ images, toDiv2Scroll, isMobile, isTablet }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const imageElements = container.querySelectorAll(".imgt");

    const action = gsap.timeline({ repeat: -1, repeatDelay: 3 });

    imageElements.forEach((imageElement, index) => {
      action.to(imageElement, {
        autoAlpha: 1,
        duration: 1.5,
        delay: index === 0 ? 0 : 3,
      });
    });

    action.play();

    return () => {
      action.kill();
    };
  }, [images]);

  return (
    <div
      ref={containerRef}
      id="slideshow-container"
      className="img-container"
      style={{ height: "60vh", width: "100%", position: "relative" }}
    >
      {images?.map((imageSrc, index) => (
        <div
          key={index}
          className="imgt"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${require(`../../Images/1111/${imageSrc.image}`)})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            opacity: index === 0 ? 1 : 0,
          }}
        >
          <div
            className="col-12 d-flex justify-content-center align-items-center flex-column"
            style={{ marginTop: isMobile ? "50px" : "30px" }}
          >
            <div
              className={`${
                isMobile ? "fs-4" : isTablet ? "fs-3" : "fs-1"
              } h1Head text-light ltrSpcng`}
            >
              EXPERIENCE THE WORLD
            </div>
            <div
              className={`${
                isMobile ? "fs-4" : isTablet ? "fs-3" : "fs-1"
              } h1Head text-light ltrSpcng`}
            >
              IN
            </div>
            <div
              className={`${
                isMobile ? "fs-4" : isTablet ? "fs-3" : "fs-1"
              } h1Head text-light ltrSpcng`}
            >
              A UNIQUE WAY
            </div>
            <div className="d-flex  justify-content-center mt-4">
              <button
                className="btn-2 pt-3  pb-3 ps-3 pe-3"
                onClick={toDiv2Scroll}
              >
                GET STARTED
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

function Home({ destinationSummary, safaris, data }) {
  const isBiggerScreen = useMediaQuery({ minWidth: 1824 });
  const isBigDesktop = useMediaQuery({ minWidth: 1224, maxWidth: 1823 });
  const isDesktop = useMediaQuery({ minWidth: 992, maxWidth: 1223 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  const navigate = useNavigate();

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = data?.images ? data?.images : [];
  const [showDestSumDetail, setShowDestSumDetail] = useState(false);
  const [showDestID, setShowDestID] = useState("");

  const section0Ref = useRef(null);
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const section5Ref = useRef(null);
  const section6Ref = useRef(null);
  const section7Ref = useRef(null);
  const section8Ref = useRef(null);

  const div2Ref = useRef(null);

  const toDiv2Scroll = () => {
    div2Ref.current.scrollIntoView();
  };

  // useEffect(() => {
  //   div2Ref.current.scrollIntoView();
  // }, []);

  useEffect(() => {
    gsap.from(section0Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section1Ref.current,
        start: "top 0%", // Adjust the start position as needed
        end: "bottom 100%", // Adjust the end position as needed
        toggleActions: "play none none reverse",
      },
    });
    gsap.from(section1Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section1Ref.current,
        start: "top 80%", // Adjust the start position as needed
        end: "bottom 20%", // Adjust the end position as needed
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section2Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section2Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section3Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section3Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });
    gsap.from(section4Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section4Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section5Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section4Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section6Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section6Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section7Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section7Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section8Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section7Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [images.length]);

  const seeDestSummaryHandler = (e, id) => {
    e.preventDefault();
    setShowDestID(id);
    console.log("id", id);
    setShowDestSumDetail(true);
  };

  const closeDestSummaryHandler = (e, id) => {
    e.preventDefault();
    setShowDestID("");
    setShowDestSumDetail(false);
  };
  const toDestSummHandler = (e, p) => {
    e.preventDefault();
    navigate({
      pathname: `/${p}`,
      hash: "#mount",
    });
  };

  const popularSafaris = safaris?.filter(
    (s) =>
      s.title2 == "3-DAYS-PRECIOUS-SERENGETI-SAFARI" ||
      s.title2 == "5-Days-Serengeti-Great-Migration-Safari" ||
      s.title2 == "6-Days-Mt-Kilimanjaro-Summit-Climbing-Marangu-Route" ||
      s.title2 == "7-Days-Best-Camping-Tanzania-Safari" ||
      s.title2 == "Fantastic-8-Days-wildebeest-Migration-Calving-Safari" ||
      s.title2 == "10-days-Exclusive-Tanzania-Premium-Safari" ||
      s.title2 == "10-Days-Luxury-Honeymoon-Safari-Zanzibar" ||
      s.title2 == "14-Days-Mid-range-Safari-Authentic-Cultural-Experience"
  );

  return (
    <div
      className="homeScreen m-0 pb-0"
      style={{
        minHeight: "100vh",
      }}
    >
      <BackgroundHome
        images={images}
        toDiv2Scroll={toDiv2Scroll}
        isMobile={isMobile}
      />
      <div
        className=""
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <div className="col-12 pt-1   .scroll-section" ref={div2Ref}>
          <div
            className="col-12 mt-2 d-flex justify-content-center"
            style={{
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              // backgroundImage: `url("Images/chuiBG2.png")`,
            }}
          >
            <div
              className={` ${
                isMobile
                  ? "col-10"
                  : isTablet
                  ? "col-8"
                  : isDesktop
                  ? "col-8 "
                  : isBigDesktop
                  ? "col-7"
                  : isBiggerScreen
                  ? "col-6"
                  : "col-8"
              } d-flex justify-content-center  mt-0 pt-0 align-items-center flex-column `}
            >
              <div className="col-12 text-center mt-0 pt-0">
                <p>
                  360 Extreme Adventures we are a local safari operator in
                  Tanzania, the land of most famous Africa&#39;s natural
                  attractions: Mount Kilimanjaro, Ngorongoro Crater and the
                  largest overland migration called the Great Wildebeest
                  Migration in Serengeti. Not to mention the world’s most
                  beautiful tropical beaches at Zanzibar Island. With our
                  headquarters in Arusha – Tanzania, we offer all types of
                  safari tours and experiences with the best value for money as
                  well as year-round itineraries for family, honeymoon, Zanzibar
                  beach holiday and all other types of travelers. Make your
                  safari dream come true with us and witness stunning African
                  landscapes, diverse cultures, opportunities for climbing Mount
                  Kilimanjaro, encounter wildlife and do various activities.
                </p>
                <p className="mt-2">
                  ‘‘Let's build new stories together and create unforgettable,
                  rewarding memories that transcend generations.’’
                </p>
              </div>
              <div className="col-12 d-flex flex-column mt-3" ref={section1Ref}>
                <div className="col-12 text-center h3 mb-2">WHAT WE OFFER</div>
                <div className="col-12 d-flex text-center">
                  <p className="">
                    Tanzania is frequently voted as the best country to visit
                    when searching for wildlife in Africa. As a local company,
                    we take pride in this. If it times well with your visit, 360
                    Extreme Adventures worth it the best. We can showcase what
                    Tanzania has to offer.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center mt-5   mb-3">
            <div
              className={`
              ${
                isMobile
                  ? "col-10"
                  : isTablet
                  ? "col-8"
                  : isDesktop
                  ? "col-8 "
                  : isBigDesktop
                  ? "col-7"
                  : isBiggerScreen
                  ? "col-6"
                  : "col-8"
              }
              d-flex flex-column text-center section2`}
              ref={section2Ref}
            >
              <div className="col-12 h3 text-center mb-2">
                GREAT MIGRATION SAFARI
              </div>
              <p className="col-12">
                Tanzania is home to the great wildebeest migration safaris where
                millions of wildebeests move from one place to another in search
                of greener pastures. Serengeti National Park is home to the
                spectacular wildebeest migration and offers top-class viewing
                than elsewhere on earth. In Tanzania, the Great Migration has 2
                main events, the Calving season from January to April and the
                River Crossing from July to October.
              </p>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center mt-4  mb-3 ">
            <div
              className={`
              ${
                isMobile
                  ? "col-10"
                  : isTablet
                  ? "col-8"
                  : isDesktop
                  ? "col-8 "
                  : isBigDesktop
                  ? "col-7"
                  : isBiggerScreen
                  ? "col-6"
                  : "col-8"
              }
              d-flex flex-column section3 text-center`}
              ref={section3Ref}
            >
              <div className="text-center col-12 h3 mb-2">
                YEAR ROUND SAFARI
              </div>
              <p className="col-12">
                Tanzania is a year-round destination famous for its rich
                scenery, density of wildlife, and enriching tribal culture.
                There are many reasons to choose a Tanzania safari over other
                African nations. The country is one of the broadest ranges of
                wildlife on the Africa continent, many of the parks still exist
                without any boundary fences. To name a few, the country’s
                northern safari areas are the most visited where the famous
                Serengeti and Ngorongoro can be found. We provide you a unique
                safari with a wide choice of activities ranging from game
                viewing to interactive cultural immersion experiences.
              </p>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center mt-4  mb-3">
            <div
              className={`
              ${
                isMobile
                  ? "col-10"
                  : isTablet
                  ? "col-8"
                  : isDesktop
                  ? "col-8 "
                  : isBigDesktop
                  ? "col-7"
                  : isBiggerScreen
                  ? "col-6"
                  : "col-8"
              }
              d-flex flex-column section4 text-center`}
              ref={section4Ref}
            >
              <div className="h3 mb-2 text-center">
                PERFECT COMBO- SAFARI & ZANZIBAR
              </div>
              <p className="">
                Tanzania is a top tourist destination in Africa. Tanzania
                mainland and islands, each with unique charm that lures numerous
                visitors from around the world every year. We have Safari and
                Zanzibar tour combos. We are able to arrange this for you,
                creating a seamless experience in Tanzania from bush to beach
                that we make sure you don’t miss exploring the city of Stonetown
                in Zanzibar and learn about its history, food, and culture. Grab
                a drink and enjoy the pristine beach and white sands. We believe
                that our considerable experience and knowledge ensure we can
                offer you a unique, exciting and unforgettable African safari
                experience.
              </p>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center mt-5  mb-3">
            <div
              className={`
              ${
                isMobile
                  ? "col-10"
                  : isTablet
                  ? "col-8"
                  : isDesktop
                  ? "col-8 "
                  : isBigDesktop
                  ? "col-7"
                  : isBiggerScreen
                  ? "col-6"
                  : "col-8"
              }
              d-flex flex-column text-center section5`}
              ref={section5Ref}
            >
              <div className="col-12 h3 text-center">UNIQUE ACCOMMODATIONS</div>
              <p className="col-12">
                Tanzania has a diverse mix of accommodation that suit all budget
                and types of travelers from couples, families with small,
                children, elders, and people with disability. Accommodations
                range from budget camping tents that are famous for backpackers
                and travelers who want to rough it out, to standard lodges or
                tented camps that feature basic facilities, to luxurious
                hotel-style lodges and extravagant tented camps that are just
                exquisite in every corner. Depending on the type of
                accommodation you choose, you will be staying in an exclusive
                area in the wilderness where you will be surrounded by stunning
                vistas and freely roaming animals or you will be staying in
                camps/lodges that are strategically located along areas very
                accessible to wildlife encounters or attractions.
              </p>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center mt-5  pb-3">
            <div
              ref={section6Ref}
              className={`
              ${
                isMobile
                  ? "col-10"
                  : isTablet
                  ? "col-8"
                  : isDesktop
                  ? "col-8 "
                  : isBigDesktop
                  ? "col-7"
                  : isBiggerScreen
                  ? "col-6"
                  : "col-8"
              }
              d-flex flex-column text-center section6`}
            >
              <div className="col-12 h3 b-2 text-center ">
                FLEXIBLE SCHEDULES
              </div>
              <p className="col-12">
                We are a tailor-made Tanzania safari tours operator. We offer
                maximum flexibility on the programme you want, for the dates
                convenient to you, and the accommodation style you prefer. That
                means maximum enjoyment on your safari – after all it is your
                holiday and we will do our best to ensure we exceed your
                expectations. So, we will help plan, advice & implement that
                really special safari for you.
              </p>
            </div>
          </div>
        </div>
        <div
          className="col-12 d-flex justify-content-center mb-3"
          style={{
            paddingTop: isMobile ? "13px" : "5rem",
          }}
        >
          <div
            className={`
              ${
                isMobile
                  ? "col-12"
                  : isTablet
                  ? "col-12"
                  : isDesktop
                  ? "col-11"
                  : isBigDesktop
                  ? "col-10"
                  : isBiggerScreen
                  ? "col-9"
                  : "col-9"
              }
              d-flex flex-column section2`}
            ref={section7Ref}
          >
            <div className="col-12 text-center h3">DESTINATIONS</div>
            <div
              className={
                isMobile
                  ? "col-12 mt-3 d-flex flex-column"
                  : "col-12 mt-3 d-flex justify-content-between flex-wrap"
              }
            >
              {isMobile || isTablet
                ? destinationSummary?.map((d) => {
                    return (
                      <div
                        className="col-12 d-flex flex-column p-2 mt-3"
                        key={d}
                      >
                        <div className="col-12 fs-2 fw-bold text-center">
                          {d.title}
                        </div>
                        <div
                          className="col-12 d-flex flex-wrap justify-content-center"
                          style={{
                            height: "40vh",
                            opacity: 1,
                            transition: "opacity 1s",
                            backgroundImage: `url(${require(`../../Images/11/${d.image}`)})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                        ></div>
                        <div className="col-12 d-flex flex-wrap  justify-content-center">
                          <p className="text-center">{d.description}</p>
                        </div>
                      </div>
                    );
                  })
                : destinationSummary?.map((d, index) => {
                    return (
                      <div
                        style={{ height: "50vh" }}
                        key={index}
                        onClick={(e) => toDestSummHandler(e, d.path)}
                        className={`${
                          isMobile ? "col-12" : isTablet ? "col-6" : "col-4"
                        } d-flex flex-column ${index == 1 && "ps-2 pe-2"} `}
                      >
                        <div
                          className=" col-12 text-center"
                          style={{
                            fontSize: isMobile || isTablet ? "16px" : "20px",
                          }}
                        >
                          {d.title}
                        </div>
                        <div
                          className="col-12 d-flex flex-wrap justify-content-center"
                          style={{
                            // position: "absolute",
                            // top: 0,
                            // left: 0,
                            // width: "100%",
                            height: "70%",
                            cursor: "pointer",
                            opacity: 1,
                            transition: "opacity 1s",
                            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${require(`../../Images/11/${d.image}`)})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                        >
                          <div className="col-12 text-light p-4 d-flex flex-column">
                            <div className="col-12 text-center">{d.title}</div>
                            <div className="col-12 text-center">
                              <p>{d.description}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>
            <div className="col-12 mt-4 text-center fw-bold fs-2">
              POPULAR SAFARIS
            </div>
            <div className="col-12 mt-2 d-flex flex-wrap justify-content-center">
              {popularSafaris?.map((s, index) => {
                return (
                  <div
                    className={`${
                      isMobile
                        ? "col-12"
                        : isTablet
                        ? "col-6"
                        : isDesktop
                        ? "col-4"
                        : "col-3"
                    } p-4`}
                  >
                    <Link to={`/safaris/${s.title2}`} style={{ all: "unset" }}>
                      <div
                        // style={{ visibility: showAnimations ? "visible" : "hidden" }}
                        className="crdShadoww crdHover card shadow  d-flex flex-column "
                      >
                        <div>
                          <DialogImage
                            src={require(`../../Images/${s?.Images[0]?.image}`)}
                          />
                        </div>
                        <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                          <div className="col-8 fw-bold text-center">
                            {/* {s.duration} */}
                            {s.title}
                          </div>
                        </div>
                        <div
                          className="col-12 ps-2 mt-2 pe-2 text-center"
                          style={{ height: "150px", overFlowY: "auto" }}
                        >
                          {s.overview?.substring(0, 200)} ..
                        </div>
                        <div className="col-12 pb-3 d-flex justify-content-center">
                          <div className="mt-3">
                            <button className="btn-1">More Details</button>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col-12 mt-5 d-flex justify-content-center mb-3">
        <FooterImages />
      </div> */}
    </div>
  );
}

export default Home;
