import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useMediaQuery } from "react-responsive";
import "./act.css";
import FooterImages from "../../Components/FooterImages";

gsap.registerPlugin(ScrollTrigger);

const mainActiveImages = [
  {
    imageID: 1,
    image: "1.jpg",
  },
  {
    imageID: 2,
    image: "2.png",
  },
  {
    imageID: 3,
    image: "3.jpg",
  },
  {
    imageID: 4,
    image: "4.jpg",
  },
  {
    imageID: 5,
    image: "5.gif",
  },
  {
    imageID: 6,
    image: "6.jpg",
  },
  {
    imageID: 7,
    image: "7.jpg",
  },
  {
    imageID: 8,
    image: "8.jpeg",
  },
  {
    imageID: 9,
    image: "9.jpeg",
  },
  {
    imageID: 10,
    image: "10.jpg",
  },
  {
    imageID: 11,
    image: "11.jpg",
  },
  {
    imageID: 12,
    image: "12.jpg",
  },
  {
    imageID: 13,
    image: "13.jpg",
  },
  {
    imageID: 14,
    image: "14.jpg",
  },
  {
    imageID: 15,
    image: "15.jpg",
  },
];

const walkingImages = [
  {
    imageID: 1,
    image: "walkSaf/1.jpg",
  },
  {
    imageID: 2,
    image: "walkSaf/2.gif",
  },
  {
    imageID: 3,
    image: "walkSaf/3.jpg",
  },
  {
    imageID: 4,
    image: "walkSaf/4.jpg",
  },
  {
    imageID: 5,
    image: "walkSaf/5.jpg",
  },
  {
    imageID: 6,
    image: "walkSaf/6.jpg",
  },
];

const boatImages = [
  {
    imageID: 1,
    image: "boat/1.jpg",
  },
  {
    imageID: 2,
    image: "boat/2.jpg",
  },
  {
    imageID: 3,
    image: "boat/3.jpg",
  },
  {
    imageID: 4,
    image: "boat/4.jpg",
  },
  {
    imageID: 5,
    image: "boat/5.jpeg",
  },
  {
    imageID: 6,
    image: "boat/6.jpg",
  },
  {
    imageID: 7,
    image: "boat/7.jpg",
  },
];

const ballonmages = [
  {
    imageID: 1,
    image: "ballon/1.jpg",
  },
  {
    imageID: 2,
    image: "ballon/2.png",
  },
  {
    imageID: 3,
    image: "ballon/3.jpg",
  },
  {
    imageID: 4,
    image: "ballon/4.jpg",
  },
  {
    imageID: 5,
    image: "ballon/5.jpg",
  },
  {
    imageID: 6,
    image: "ballon/6.jpg",
  },
];

const masaiImages = [
  {
    imageID: 1,
    image: "masai/1.jpg",
  },
  {
    imageID: 2,
    image: "masai/2.jpg",
  },
  {
    imageID: 3,
    image: "masai/3.jpeg",
  },
  {
    imageID: 4,
    image: "masai/4.jpg",
  },
  {
    imageID: 5,
    image: "masai/5.jpeg",
  },
  {
    imageID: 6,
    image: "masai/6.jpg",
  },
];

const mtoImages = [
  {
    imageID: 1,
    image: "mto/1.jpg",
  },
  {
    imageID: 2,
    image: "mto/2.jpg",
  },
  {
    imageID: 3,
    image: "mto/3.jpeg",
  },
  {
    imageID: 4,
    image: "mto/4.jpg",
  },
  {
    imageID: 5,
    image: "mto/5.jpg",
  },
  {
    imageID: 6,
    image: "mto/6.jpg",
  },
];

const handzabeImages = [
  {
    imageID: 1,
    image: "zabe/1.jpg",
  },
  {
    imageID: 2,
    image: "zabe/2.jpg",
  },
  {
    imageID: 3,
    image: "zabe/3.jpg",
  },
  {
    imageID: 4,
    image: "zabe/4.jpeg",
  },
  {
    imageID: 5,
    image: "zabe/5.jpg",
  },
];

const sandImages = [
  {
    imageID: 1,
    image: "sand/1.jpg",
  },
  {
    imageID: 2,
    image: "sand/2.jpg",
  },
  {
    imageID: 3,
    image: "sand/3.jpg",
  },
  {
    imageID: 4,
    image: "sand/4.jpg",
  },
  {
    imageID: 5,
    image: "sand/5.jpg",
  },
  {
    imageID: 6,
    image: "sand/6.jpg",
  },
];

const oldvaiImages = [
  {
    imageID: 1,
    image: "oldv/1.jpg",
  },
  {
    imageID: 2,
    image: "oldv/2.jpg",
  },
  {
    imageID: 3,
    image: "oldv/3.jpg",
  },
  {
    imageID: 4,
    image: "oldv/4.jpg",
  },
  {
    imageID: 5,
    image: "oldv/5.jpeg",
  },
  {
    imageID: 6,
    image: "oldv/6.jpeg",
  },
];

const nightImages = [
  {
    imageID: 1,
    image: "night/1.jpg",
  },
  {
    imageID: 2,
    image: "night/2.jpg",
  },
  {
    imageID: 3,
    image: "night/3.jpg",
  },
  {
    imageID: 4,
    image: "night/4.jpg",
  },
  {
    imageID: 5,
    image: "night/5.jpg",
  },
];

const photoImages = [
  {
    imageID: 1,
    image: "photo/1.jpg",
  },
  {
    imageID: 2,
    image: "photo/2.jpg",
  },
  {
    imageID: 3,
    image: "photo/3.jpg",
  },
  {
    imageID: 4,
    image: "photo/4.jpg",
  },
  {
    imageID: 5,
    image: "photo/5.jpg",
  },
  {
    imageID: 6,
    image: "photo/6.jpg",
  },
  {
    imageID: 7,
    image: "photo/7.jpg",
  },
];

const BackgroundHome = ({ images, heading, clr, cHeight }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const imageElements = container.querySelectorAll(".imgt");

    const action = gsap.timeline({ repeat: -1, repeatDelay: 3 });

    imageElements.forEach((imageElement, index) => {
      action.to(imageElement, {
        autoAlpha: 1,
        duration: 1.5,
        delay: index === 0 ? 0 : 3,
      });
    });

    action.play();

    return () => {
      action.kill();
    };
  }, [images]);

  return (
    <div
      ref={containerRef}
      id="slideshow-container"
      className="img-container"
      style={{
        height: cHeight ? "55hv" : "35vh",
        width: "100%",
        position: "relative",
      }}
    >
      {images?.map((imageSrc, index) => (
        <div
          key={index}
          className="imgt"
          style={{
            backgroundImage: clr
              ? `url(${require(`../../Images/activities/${imageSrc.image}`)})`
              : `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${require(`../../Images/activities/${imageSrc.image}`)})`,
            backgroundSize: "fit",
            objectFit: "cover",
            backgroundRepeat: "no-repeat",
            opacity: index === 0 ? 1 : 0,
          }}
        >
          <div className="col-12 d-flex justify-content-center "></div>
          <div className="col-12 text-center text-light pt-5 fw-bold fs-2">
            {heading}
          </div>
        </div>
      ))}
    </div>
  );
};

function ActivitiesScreen({ data }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = data?.images ? data?.images : [];
  const isBiggerScreen = useMediaQuery({ minWidth: 1824 });
  const isBigDesktop = useMediaQuery({ minWidth: 1224, maxWidth: 1823 });
  const isDesktop = useMediaQuery({ minWidth: 992, maxWidth: 1223 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isNotMobile = useMediaQuery({ minWidth: 768 });

  const [activeId, setActiveId] = useState("faq");

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const section5Ref = useRef(null);
  const section6Ref = useRef(null);
  const section7Ref = useRef(null);
  const section8Ref = useRef(null);
  const section9Ref = useRef(null);
  const section10Ref = useRef(null);

  useEffect(() => {
    gsap.from(section1Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section1Ref.current,
        start: "top 100%", // Adjust the start position as needed
        end: "bottom 20%", // Adjust the end position as needed
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section2Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section2Ref.current,
        start: "top 100%",
        end: "bottom 100%",
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section3Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section3Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });
    gsap.from(section4Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section4Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section5Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section4Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section6Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section6Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section7Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section7Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });
    gsap.from(section8Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section8Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });
    gsap.from(section9Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section9Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section10Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section10Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [images.length]);

  const variants = {
    open: { opacity: 1, y: "10%" },
    closed: { opacity: 0, y: "-90%" },
  };

  const currentImage = images[currentImageIndex]?.image;
  const nextImageIndex = (currentImageIndex + 1) % images.length;
  const nextImage = images[nextImageIndex]?.image;

  return (
    <div
      className=" pb-3 ActivitieScreen"
      style={{
        minHeight: "100vh",
      }}
    >
      <BackgroundHome
        images={mainActiveImages}
        heading="Embark on Unforgettable Adventures"
      />
      <div
        // style={{
        //   position: "absolute",
        //   top: 350,
        //   left: 0,
        //   paddingBottom: 20,
        // }}
        className="col-12 "
      >
        <div className="col-12  d-flex flex-column justify-content-center align-items-center">
          <div
            className={
              isMobile
                ? "col-12  d-flex flex-column pe-2 ps-2 "
                : isTablet
                ? "col-11 ps-1 pe-1   d-flex flex-column"
                : "col-9 d-flex flex-column"
            }
          >
            <div className="col-12 mb-3 mt-5 text-center">
              <p>
                Although game drive is the primary activity in your Tanzania
                safari, there’s also an extensive list of activities you can add
                to your itinerary to make for a diverse holiday. These
                activities are usually not included in a typical safari package
                but can be added at an extra cost. Choose the activity within
                your interest and decide for yourself when you want to do it.
              </p>
            </div>
          </div>
          <div className="col-12 mt-2 d-flex flex-wrap" ref={section2Ref}>
            <div
              className={`${
                isMobile ? "col-12" : isTablet ? "col-11" : "col-12"
              } text-center fs-4 fw-bold  mb-2`}
            ></div>
            <div
              className={`${
                isMobile ? "col-12" : isTablet ? "col-11" : "col-7"
              } pe-2  mt-2 d-flex justify-content-end`}
            >
              <div
                className={`${
                  isMobile ? "col-12 ps-2" : isTablet ? "col-11 ps-1" : "col-10"
                } pe-2 mb-3 text-center d-flex flex-column align-items-center`}
              >
                <h1>Walking Safari</h1>
                <p>
                  Walking Safaris is possible for those who love the
                  outdoor-world and would like to feel connection with the
                  environment. A walking safari is an excellent break from game
                  drives, where you will learn a variety of flora and fauna and
                  beautiful sceneries of which you may not fully appreciate from
                  the seat of your safari vehicle. The walks are guided and
                  normally accompanied by an armed ranger. Our more popular
                  walking destinations are: Arusha National Park, Lake Eyasi,
                  Ngorongoro Crater, Lake Natron and Lake Manyara National Park.
                  Walking is completely different to a game drive. On foot you
                  will feel, hear, touch, see, smell and be a participant in the
                  world of wildlife rather than an observer. Imagine, walk on
                  the same path as the elephants and other wildlife, you feel
                  the wind through your hair, hear a lion roar in the distance
                  and smell the bush and wildlife. Your senses awaken, as you
                  become part of environment and the wilderness becomes more
                  real.
                </p>
              </div>
            </div>
            <div
              className={`${
                isMobile ? "col-12" : isTablet ? "col-12" : "col-5"
              }`}
            >
              <BackgroundHome images={walkingImages} clr cHeight />
            </div>
          </div>
          <div className="col-12 mt-4 ">
            {isMobile || isTablet ? (
              <div className="col-12 d-flex flex-column">
                <div className="col-12 text-center fs-3 fw-bold"></div>
                <div className="col-12 mt-2">
                  <BackgroundHome images={boatImages} clr cHeight />
                </div>
                <div className="col-12 text-center p-2 d-flex flex-column align-items-center">
                  <h1>Boat & Canoeing Safaris</h1>
                  <p>
                    Canoeing is mostly conducted in the Momella Lakes within
                    Arusha National Park, Lake Duluti near Arusha town and Lake
                    Manyara National Park. While boating safari is well at River
                    Wami bordering Saadani National Park. The waterways provide
                    an excellent natural setting for both boat safaris and
                    canoeing, usually as an add-on to a game drive itinerary.
                    From the boat or canoe visitors would have a different
                    approach to viewing game and birding, not to mention the
                    sheer thrill of cruising past hippos and other amphibian /
                    riverine wildlife. Tanzania is a land of many lakes and
                    rivers traversing lands rich in wildlife.{" "}
                  </p>
                </div>
              </div>
            ) : (
              <div className="col-12">
                <div className="col-12 d-flex">
                  <div className="col-7  mt-2 d-flex justify-content-end">
                    <div className="col-10 pe-2 mb-3 ">
                      <BackgroundHome images={boatImages} clr cHeight />
                    </div>
                  </div>
                  <div className="col-5 mt-2 d-flex flex-column">
                    <div className="col-11  fs-3 fw-bold text-center h5  mb-2"></div>
                    <div className="col-11 mb-3 text-center d-flex justify-content-center flex-column align-items-center">
                      <h1>Boat & Canoeing Safaris</h1>
                      <p>
                        Canoeing is mostly conducted in the Momella Lakes within
                        Arusha National Park, Lake Duluti near Arusha town and
                        Lake Manyara National Park. While boating safari is well
                        at River Wami bordering Saadani National Park. The
                        waterways provide an excellent natural setting for both
                        boat safaris and canoeing, usually as an add-on to a
                        game drive itinerary. From the boat or canoe visitors
                        would have a different approach to viewing game and
                        birding, not to mention the sheer thrill of cruising
                        past hippos and other amphibian / riverine wildlife.
                        Tanzania is a land of many lakes and rivers traversing
                        lands rich in wildlife.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-12 ">
            {isMobile || isTablet ? (
              <div className="col-12 d-flex flex-column">
                <div className="col-12 text-center fs-3 fw-bold">
                  Balloon Safaris
                </div>
                <div className="col-12 mt-2">
                  <BackgroundHome images={boatImages} clr />
                </div>
                <div className="col-12  text-start fst-italic fs-5 fw-bold mb-2">
                  Serengeti Balloon Safari
                </div>
                <div className="col-12 text-center p-2">
                  <p>
                    It is an experience of a lifetime as you rise with the sun
                    and gently bob through the air in whichever direction the
                    winds of the morning take you. It is a spellbinding scene as
                    you witness herds of wildebeest, towers of giraffe, or a
                    pride of lions running across the plains of the Serengeti
                    just a few hundred meters below and your eye darting to
                    every possible corner, euphoric. Definitely worth it as it
                    is one of those trips that you will remember for all time.
                    This activity cannot be organized on the day and due to the
                    reliance on favorable weather, it is not on offer throughout
                    the year. Price per person is around 500 to 600 USD, which
                    covers the ride, a champagne toast after the landing, and a
                    delicious bush breakfast. The balloons are huge and the
                    compartmented comfy baskets can accommodate from around 8 to
                    16 people for each voyage depending on the size. At this
                    time, only children over seven years old are allowed on the
                    trip and they must be accompanied by an adult. As such, it
                    might be too pricey for budget and standard travelers, but
                    if it is within your means, the feeling you’ll get when you
                    are up there and looking down at the incredible scene below
                    is priceless. The dry season (June to October) is the ideal
                    time for a hot balloon safari in the Serengeti. Thankfully,
                    the dry season is also the perfect time to witness the Great
                    Migration, so floating silently above this wondrous
                    spectacle means you’re in for a treat of a lifetime. Pick up
                    is at early dawn usually around 5/5.30 AM and you could grab
                    a coffee and a light breakfast before departing your lodge.
                    On your way to the launch site, you may spot nocturnal
                    animals that you could not have encountered at any other
                    time of day. There is a pre-launch safety briefing from the
                    pilot while the balloon is being inflated. Flight time is
                    approximately 1 hour and the balloon can ascend up to 1000
                    ft exposing the vast magnificence and spectacular panorama
                    of the Serengeti below. After that a splendid bush breakfast
                    in the middle of the Serengeti awaits, capping off what is
                    sure to be one of the most thrilling experiences ever, you
                    will then continue your day with game drive and other
                    activities, depending on what you’ve planned.
                  </p>
                </div>
              </div>
            ) : (
              <div className="col-12 mt-5 d-flex  flex-wrap d-flex justify-content-center align-items-center">
                <div className="col-7 text-center fs-3 fw-bold  mb-2">
                  Balloon Safaris
                </div>
                <div className="col-7 pe-2  mt-2 d-flex  justify-content-end">
                  <div className="col-10 text-center pe-2 mb-3">
                    <div className="col-12  text-start fst-italic mb-2">
                      Serengeti Balloon Safari
                    </div>
                    <p>
                      It is an experience of a lifetime as you rise with the sun
                      and gently bob through the air in whichever direction the
                      winds of the morning take you. It is a spellbinding scene
                      as you witness herds of wildebeest, towers of giraffe, or
                      a pride of lions running across the plains of the
                      Serengeti just a few hundred meters below and your eye
                      darting to every possible corner, euphoric. Definitely
                      worth it as it is one of those trips that you will
                      remember for all time. This activity cannot be organized
                      on the day and due to the reliance on favorable weather,
                      it is not on offer throughout the year. Price per person
                      is around 500 to 600 USD, which covers the ride, a
                      champagne toast after the landing, and a delicious bush
                      breakfast. The balloons are huge and the compartmented
                      comfy baskets can accommodate from around 8 to 16 people
                      for each voyage depending on the size. At this time, only
                      children over seven years old are allowed on the trip and
                      they must be accompanied by an adult. As such, it might be
                      too pricey for budget and standard travelers, but if it is
                      within your means, the feeling you’ll get when you are up
                      there and looking down at the incredible scene below is
                      priceless. The dry season (June to October) is the ideal
                      time for a hot balloon safari in the Serengeti.
                      Thankfully, the dry season is also the perfect time to
                      witness the Great Migration, so floating silently above
                      this wondrous spectacle means you’re in for a treat of a
                      lifetime. Pick up is at early dawn usually around 5/5.30
                      AM and you could grab a coffee and a light breakfast
                      before departing your lodge. On your way to the launch
                      site, you may spot nocturnal animals that you could not
                      have encountered at any other time of day. There is a
                      pre-launch safety briefing from the pilot while the
                      balloon is being inflated. Flight time is approximately 1
                      hour and the balloon can ascend up to 1000 ft exposing the
                      vast magnificence and spectacular panorama of the
                      Serengeti below. After that a splendid bush breakfast in
                      the middle of the Serengeti awaits, capping off what is
                      sure to be one of the most thrilling experiences ever, you
                      will then continue your day with game drive and other
                      activities, depending on what you’ve planned.
                    </p>
                  </div>
                </div>
                <div className="col-5 pt-4">
                  <BackgroundHome images={ballonmages} clr cHeight />
                </div>
              </div>
            )}
          </div>
          <div className="col-12 text-center h5 mt-5  fs-2 fw-bold  mb-2">
            Culture
          </div>
          <div className="col-12 ">
            {isMobile || isTablet ? (
              <div className="col-12 d-flex flex-column">
                <div className="col-12 text-center fs-3 fw-bold">
                  Balloon Safaris
                </div>
                <div className="col-12 mt-2">
                  <BackgroundHome images={masaiImages} clr cHeight />
                </div>
                <div className="col-12  text-center fs-5 fw-bold mb-2">
                  Maasai
                </div>
                <div className="col-12 text-center p-2">
                  <p>
                    Most visitors to Africa, especially first timers, find the
                    continent and its people enchantingly different and a
                    special experience. 360 Extreme Adventures appreciate this
                    fact and endeavor to include visits to the local communities
                    to give our guests the opportunity to see first hand the way
                    of life in a typical African village. Tanzania has over 120
                    tribes each with its own culture. The Maasai in northern
                    Tanzania are among the most popular ethnic groups in the
                    area, a proud people fervently attached to their cultural
                    values. Visitors will be shown around the Maasai Boma (The
                    huts, normally built by women, are made of wood, mud and cow
                    dung), and are welcome to explore the huts where Maasai
                    families live and learn a few things about their way of
                    living. The villagers will show off and they will sell their
                    colourful beadwork and other handcrafted wares they made.
                    The Maasai warriors would challenge men to engage in a spear
                    throwing match or perform a tribal dance, and ladies may
                    choose to participate in beadwork. This is intended to
                    expose visitors to the Maasai culture and enrich them with
                    some authentic African experiences.
                  </p>
                </div>
              </div>
            ) : (
              <div className="col-12 mt-2 d-flex">
                <div className="col-7 d-flex justify-content-end">
                  <div className="col-10 pt-3 mt-3 pe-2">
                    <BackgroundHome images={masaiImages} clr cHeight />
                  </div>
                </div>
                <div className="col-5  d-flex flex-column pe-3 d-flex justify-content-center align-items-center">
                  <div className="col-12 text-center fst-italic  fs-4 fw-bold mb-2">
                    Maasai
                  </div>
                  <div className="col-12 mb-3">
                    <p>
                      Most visitors to Africa, especially first timers, find the
                      continent and its people enchantingly different and a
                      special experience. 360 Extreme Adventures appreciate this
                      fact and endeavor to include visits to the local
                      communities to give our guests the opportunity to see
                      first hand the way of life in a typical African village.
                      Tanzania has over 120 tribes each with its own culture.
                      The Maasai in northern Tanzania are among the most popular
                      ethnic groups in the area, a proud people fervently
                      attached to their cultural values. Visitors will be shown
                      around the Maasai Boma (The huts, normally built by women,
                      are made of wood, mud and cow dung), and are welcome to
                      explore the huts where Maasai families live and learn a
                      few things about their way of living. The villagers will
                      show off and they will sell their colourful beadwork and
                      other handcrafted wares they made. The Maasai warriors
                      would challenge men to engage in a spear throwing match or
                      perform a tribal dance, and ladies may choose to
                      participate in beadwork. This is intended to expose
                      visitors to the Maasai culture and enrich them with some
                      authentic African experiences.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="col-12 mt-4 ">
            {isMobile || isTablet ? (
              <div className="col-12 d-flex flex-column">
                <div className="col-12 text-center fs-3 fw-bold">
                  Hadzabe & Datoga
                </div>
                <div className="col-12 mt-2">
                  <BackgroundHome images={handzabeImages} clr cHeight />
                </div>
                <div className="col-12 text-center p-2">
                  <p>
                    The Hadzabe tribe (hunter-gatherers) lives close to the
                    shores of Lake Eyasi, as do the Nilotic-speaking Datoga
                    tribe who are skilled farmers and crafts people. These are
                    the most distinct tribes of all because their style of
                    living is quite different from others and they are also not
                    closely related to any other people genetically. Their
                    history and culture are rooted in the earth. These people
                    will make you remember about the early humans on earth.
                    Visits to these tribes are possible on half day or full day
                    excursions which would include a visit to their homesteads,
                    learning about their way of life, medicinal plants, and even
                    animal tracking with bows and arrows with the Hadzabe
                    hunters.
                  </p>
                </div>
              </div>
            ) : (
              <div className="col-12  d-flex flex-wrap d-flex justify-content-center align-items-center">
                <div className="col-7  d-flex   justify-content-end">
                  <div className="col-10 mb-3">
                    <div className="col-12  text-center fst-italic  fs-4 fw-bold mb-2">
                      Hadzabe & Datoga
                    </div>
                    <p>
                      The Hadzabe tribe (hunter-gatherers) lives close to the
                      shores of Lake Eyasi, as do the Nilotic-speaking Datoga
                      tribe who are skilled farmers and crafts people. These are
                      the most distinct tribes of all because their style of
                      living is quite different from others and they are also
                      not closely related to any other people genetically. Their
                      history and culture are rooted in the earth. These people
                      will make you remember about the early humans on earth.
                      Visits to these tribes are possible on half day or full
                      day excursions which would include a visit to their
                      homesteads, learning about their way of life, medicinal
                      plants, and even animal tracking with bows and arrows with
                      the Hadzabe hunters.
                    </p>
                  </div>
                </div>
                <div className="col-5 ">
                  <div className="col-11 pe-2">
                    <BackgroundHome images={handzabeImages} clr cHeight />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="col-12 mt-4">
            {isMobile || isTablet ? (
              <div className="col-12 d-flex flex-column">
                <div className="col-12 text-center fs-3 fw-bold">
                  Mto wa Mbu Villageee
                </div>
                <div className="col-12 mt-2">
                  <BackgroundHome images={mtoImages} clr cHeight />
                </div>
                <div className="col-12 text-center p-2">
                  <p>
                    Meet an array of tribes living together in a small area. Mto
                    wa Mbu is one of the first cultural tourism sites, situated
                    on the foot of the Great Rift Valley borderings Lake Manyara
                    National Park, straddling the famous Arusha / Serengeti
                    route, 120kms and 60kms from Arusha and Ngorongoro
                    respectively. Mto wa Mbu is one of the most popular cultural
                    tour sites in Tanzania, attracting a new wave of tribes from
                    all over the country, each with its own cultural background.
                    Nowhere else in Tanzania have so many different tribes
                    settled in such a small area. The Mto wa Mbu tour is a
                    village walk, designed to provide guests with an experience
                    of the rich cultural heritage in Tanzania, albeit in a short
                    time of half a day or one full day. The varied produces,
                    handicrafts and activities that can be seen on the market
                    place and in the village farms and lifestyle is an
                    illustration of this cultural diversity. Visitors may see
                    Chagga people brew their famous banana beer, mbege; meet a
                    farmer from Kigoma extract palm oil from palm trees;
                    appreciate the Sandawe with their fascinating click
                    language, similar to the Khoisan of the Kalahari Desert,
                    making bows & arrows for hunting; interact with the Rangi
                    from Kondoa using the papyrus from the lakes and rivers for
                    weaving beautiful mats and baskets. Visit the Mbugwe people
                    from Manyara Region who will show you how they grind
                    different grains to obtain flour using a traditional
                    millstone, you will also learn about traditional iron
                    smelting technology, one of the oldest in Africa. The local
                    people will show you how to make different tools like
                    spears, knives, arrows. You will discover a side of Tanzania
                    that many visitors miss.
                  </p>
                </div>
              </div>
            ) : (
              <div className="col-12 d-flex">
                <div className="col-7 d-flex justify-content-end">
                  <div className="col-10 pt-3 mt-3 pe-2">
                    <BackgroundHome images={mtoImages} clr cHeight />
                  </div>
                </div>
                <div className="col-5  d-flex flex-column pe-3">
                  <div className="col-11 text-center fst-italic  fs-4 fw-bold mb-2">
                    Mto wa Mbu Village
                  </div>
                  <div className="col-11 mb-3">
                    <p>
                      Meet an array of tribes living together in a small area.
                      Mto wa Mbu is one of the first cultural tourism sites,
                      situated on the foot of the Great Rift Valley borderings
                      Lake Manyara National Park, straddling the famous Arusha /
                      Serengeti route, 120kms and 60kms from Arusha and
                      Ngorongoro respectively. Mto wa Mbu is one of the most
                      popular cultural tour sites in Tanzania, attracting a new
                      wave of tribes from all over the country, each with its
                      own cultural background. Nowhere else in Tanzania have so
                      many different tribes settled in such a small area. The
                      Mto wa Mbu tour is a village walk, designed to provide
                      guests with an experience of the rich cultural heritage in
                      Tanzania, albeit in a short time of half a day or one full
                      day. The varied produces, handicrafts and activities that
                      can be seen on the market place and in the village farms
                      and lifestyle is an illustration of this cultural
                      diversity. Visitors may see Chagga people brew their
                      famous banana beer, mbege; meet a farmer from Kigoma
                      extract palm oil from palm trees; appreciate the Sandawe
                      with their fascinating click language, similar to the
                      Khoisan of the Kalahari Desert, making bows & arrows for
                      hunting; interact with the Rangi from Kondoa using the
                      papyrus from the lakes and rivers for weaving beautiful
                      mats and baskets. Visit the Mbugwe people from Manyara
                      Region who will show you how they grind different grains
                      to obtain flour using a traditional millstone, you will
                      also learn about traditional iron smelting technology, one
                      of the oldest in Africa. The local people will show you
                      how to make different tools like spears, knives, arrows.
                      You will discover a side of Tanzania that many visitors
                      miss.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="col-12 text-center fs-3">Historical Sites</div>

          <div className="col-12 mt-2">
            {isMobile || isTablet ? (
              <div className="col-12 d-flex flex-column">
                <div className="col-12 text-center fs-3 fw-bold">
                  The Shifting Sands
                </div>
                <div className="col-12 mt-2">
                  <BackgroundHome images={sandImages} clr cHeight />
                </div>
                <div className="col-12 text-center p-2">
                  <p>
                    The black sand dunes that occur in the eastern Serengeti
                    Plain near the Olduvai Gorge hominid site within the
                    Ngorongoro Conservation Area in Tanzania are mainly
                    identified as volcanic sand dunes. The migratory dunes, also
                    known as the Shifting Sands, are located at least twelve
                    kilometers north-west of the Museum at Olduvai Gorge and
                    nearly two kilometers from the Gorge's bank. It is one of
                    the most spectacular moving ash dunes in the world,
                    gradually blown westward across the plains at a rate of
                    approximately 15 to 29 meters annually and has scoured
                    lighter portions of the ash from the area, leaving behind
                    the heavier dark colored iron-rich minerals that formed the
                    dune. It is a remarkable crescent-shaped black dune made of
                    volcanic ash from the active Ol doinyo Lengai, reaching
                    exactly 5 meters in height and stretching 100 m (328 ft)
                    long along its curves. The dunes are composed of highly
                    magnetic volcanic ash, which holds the sand particles
                    together, justifying why the granules of the dunes aren’t
                    blown away by winds. What makes it standout is the fact that
                    it occurs as a solitary, isolated dune. Such dunes are
                    typically found in huge dune formations. They are composed
                    of fine black ash that is significantly darker than the
                    surroundings, which are characterized by vast plains with
                    short grasses and bushes supplemented by scattered acacia
                    trees (the presence of iron-rich minerals accounts for the
                    sand’s dark color). The dunes are well exposed above the
                    plains and are easily accessible via trails that venture
                    down the Olduvai Gorge ravine towards the Serengeti’s
                    eastern grasslands on the Ngorongoro Conservation Area’s
                    side.
                  </p>
                </div>
              </div>
            ) : (
              <div className="col-12  d-flex  flex-wrap d-flex justify-content-center align-items-center">
                <div className="col-7 pe-2  mt-2 d-flex  justify-content-end">
                  <div className="col-10 pe-2 mb-3">
                    <div className="col-12 text-center fs-3 fw-bold  mb-2">
                      The Shifting Sands
                    </div>
                    <p>
                      The black sand dunes that occur in the eastern Serengeti
                      Plain near the Olduvai Gorge hominid site within the
                      Ngorongoro Conservation Area in Tanzania are mainly
                      identified as volcanic sand dunes. The migratory dunes,
                      also known as the Shifting Sands, are located at least
                      twelve kilometers north-west of the Museum at Olduvai
                      Gorge and nearly two kilometers from the Gorge's bank. It
                      is one of the most spectacular moving ash dunes in the
                      world, gradually blown westward across the plains at a
                      rate of approximately 15 to 29 meters annually and has
                      scoured lighter portions of the ash from the area, leaving
                      behind the heavier dark colored iron-rich minerals that
                      formed the dune. It is a remarkable crescent-shaped black
                      dune made of volcanic ash from the active Ol doinyo
                      Lengai, reaching exactly 5 meters in height and stretching
                      100 m (328 ft) long along its curves. The dunes are
                      composed of highly magnetic volcanic ash, which holds the
                      sand particles together, justifying why the granules of
                      the dunes aren’t blown away by winds. What makes it
                      standout is the fact that it occurs as a solitary,
                      isolated dune. Such dunes are typically found in huge dune
                      formations. They are composed of fine black ash that is
                      significantly darker than the surroundings, which are
                      characterized by vast plains with short grasses and bushes
                      supplemented by scattered acacia trees (the presence of
                      iron-rich minerals accounts for the sand’s dark color).
                      The dunes are well exposed above the plains and are easily
                      accessible via trails that venture down the Olduvai Gorge
                      ravine towards the Serengeti’s eastern grasslands on the
                      Ngorongoro Conservation Area’s side.
                    </p>
                  </div>
                </div>
                <div className="col-5 pt-4">
                  <div className="col-11">
                    <BackgroundHome images={sandImages} clr cHeight />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="col-12 mt-4 ">
            {isMobile || isTablet ? (
              <div className="col-12 d-flex flex-column">
                <div className="col-12 text-center fs-3 fw-bold">
                  Olduvai Gorge
                </div>
                <div className="col-12 mt-2">
                  <BackgroundHome images={oldvaiImages} clr cHeight />
                </div>
                <div className="col-12 text-center p-2">
                  <p>
                    The Olduvai Gorge, which is a remarkable archaeological site
                    in East Africa and perhaps in the world. It was officially
                    recognized as a part of the UNESCO World Heritage Site in
                    1979. Exposed within the sides of the gorge is a remarkably
                    rich chronicle of human ancestry and the evolution of the
                    Serengeti ecosystem. The Olduvai Gorge, popularly referred
                    to as “The Cradle of Humankind”, is the site where in 1959
                    Dr. Louis Leakey discover the famous Synanthropes’
                    (Australopithecus boisei) skull, as well as remains of Homo
                    habilis, the presumed maker of the numerous early stone
                    tools found in deposits ranging in age from 1.6 to 1.8
                    million years ago, and Homo erectus, the larger- bodied and
                    larger- brained hominin that preceded the earliest modern
                    humans (Homo sapiens). The gorge may be visited year-round.
                  </p>
                </div>
              </div>
            ) : (
              <div className="col-12  d-flex">
                <div className="col-7 d-flex justify-content-end">
                  <div className="col-10 pt-3 mt-3 pe-2">
                    <BackgroundHome images={oldvaiImages} clr cHeight />
                  </div>
                </div>
                <div className="col-5  d-flex flex-column pe-3 pt-4 justify-content-center align-items-center">
                  <div className="col-11 text-center fst-italic  fs-4 fw-bold mb-2">
                    Olduvai Gorge
                  </div>
                  <div className="col-11 mb-3">
                    <p>
                      The Olduvai Gorge, which is a remarkable archaeological
                      site in East Africa and perhaps in the world. It was
                      officially recognized as a part of the UNESCO World
                      Heritage Site in 1979. Exposed within the sides of the
                      gorge is a remarkably rich chronicle of human ancestry and
                      the evolution of the Serengeti ecosystem. The Olduvai
                      Gorge, popularly referred to as “The Cradle of Humankind”,
                      is the site where in 1959 Dr. Louis Leakey discover the
                      famous Synanthropes’ (Australopithecus boisei) skull, as
                      well as remains of Homo habilis, the presumed maker of the
                      numerous early stone tools found in deposits ranging in
                      age from 1.6 to 1.8 million years ago, and Homo erectus,
                      the larger- bodied and larger- brained hominin that
                      preceded the earliest modern humans (Homo sapiens). The
                      gorge may be visited year-round.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="col-12 " style={{ marginTop: "30px" }}>
            {isMobile || isTablet ? (
              <div className="col-12 d-flex flex-column">
                <div className="col-12 text-center fs-3 fw-bold">
                  Night Game Drive
                </div>
                <div className="col-12 mt-2">
                  <BackgroundHome images={nightImages} clr cHeight />
                </div>
                <div className="col-12 text-center p-2">
                  <p>
                    This is a unique experience, beginning with a magnificent
                    bush dinner, and followed by a night game drive in open top
                    vehicles. A night safari in Lake Manyara National Park gives
                    you the opportunity to experience the sounds, sights and
                    smells of the African bush at night under the spectacular
                    southern skies. Nocturnal creatures such as bush babies,
                    aardvark, lion and leopard can be seen. This excursion can
                    be enjoyed by guests staying at all lodges and campsites
                    within reasonable distance of the park.
                  </p>
                </div>
              </div>
            ) : (
              <div className="col-12  d-flex  flex-wrap">
                <div className="col-12 d-flex justify-content-center align-items-center">
                  <div className="col-7 pe-2  mt-2 d-flex flex-wrap  justify-content-end">
                    <div className="col-12 mt-5 text-center fs-3 fw-bold  mb-2">
                      Night Game Drive
                    </div>
                    <div className="col-10 pe-2 mb-3">
                      <p>
                        This is a unique experience, beginning with a
                        magnificent bush dinner, and followed by a night game
                        drive in open top vehicles. A night safari in Lake
                        Manyara National Park gives you the opportunity to
                        experience the sounds, sights and smells of the African
                        bush at night under the spectacular southern skies.
                        Nocturnal creatures such as bush babies, aardvark, lion
                        and leopard can be seen. This excursion can be enjoyed
                        by guests staying at all lodges and campsites within
                        reasonable distance of the park.
                      </p>
                    </div>
                  </div>
                  <div className="col-5 ">
                    <div className="col-11">
                      <BackgroundHome images={nightImages} clr cHeight />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="col-12 mt-4">
            {isMobile || isTablet ? (
              <div className="col-12 d-flex flex-column">
                <div className="col-12 text-center fs-3 fw-bold">
                  Photographic Safari
                </div>
                <div className="col-12 mt-2">
                  <BackgroundHome images={photoImages} clr cHeight />
                </div>
                <div className="col-12 text-center p-2">
                  <p>
                    Whether it’s a safari, a forest, or an ancient village, 360
                    Extreme Adventures we can bring you there and you can
                    capture stunning images of wildlife, people, and landscapes
                    of Africa. Get a shot of the most elusive animals and
                    capture their magnificence with your camera. Through images,
                    you can let the people know of the life in Africa; its
                    people, culture, sceneries, flora, and fauna. This tour will
                    take you to Africa’s most famous heritage sites; Tarangire,
                    Lake Manyara, Serengeti and Ngorongoro Crater where you can
                    enjoy its blue-green vistas and unparalleled concentration
                    of wildlife. The world heritage sites and home to various
                    wildlife, including lions, cheetahs and at certain times of
                    the year, the wildebeest migration. Share with the world
                    your remarkable trip in Africa through photographs that
                    would last a lifetime.
                  </p>
                </div>
              </div>
            ) : (
              <div className="col-12 d-flex justify-content-center align-items-center">
                <div className="col-7 d-flex justify-content-end">
                  <div className="col-10 pt-3 mt-3 pe-2">
                    <BackgroundHome images={photoImages} clr cHeight />
                  </div>
                </div>
                <div className="col-5  d-flex flex-column pe-3 pt-4">
                  <div className="col-11 text-center fst-italic  fs-4 fw-bold mb-2">
                    Photographic Safari
                  </div>
                  <div className="col-11 mb-3">
                    <p>
                      Whether it’s a safari, a forest, or an ancient village,
                      360 Extreme Adventures we can bring you there and you can
                      capture stunning images of wildlife, people, and
                      landscapes of Africa. Get a shot of the most elusive
                      animals and capture their magnificence with your camera.
                      Through images, you can let the people know of the life in
                      Africa; its people, culture, sceneries, flora, and fauna.
                      This tour will take you to Africa’s most famous heritage
                      sites; Tarangire, Lake Manyara, Serengeti and Ngorongoro
                      Crater where you can enjoy its blue-green vistas and
                      unparalleled concentration of wildlife. The world heritage
                      sites and home to various wildlife, including lions,
                      cheetahs and at certain times of the year, the wildebeest
                      migration. Share with the world your remarkable trip in
                      Africa through photographs that would last a lifetime.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="col-12 fst-italic fs-5 text-center mt-3">
            For information on prices please fill out the inquiry form.
          </div>
          <div className="col-12 fst-italic fs-6 text-center mt-3">
            Simply Fill our booking form for confirmed bookings!!
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivitiesScreen;
