import * as AiIcons from "react-icons/ai";
import * as FcIcons from "react-icons/fc";
import * as FaIcons from "react-icons/fa";
import * as HiIcons from "react-icons/hi";
import * as GiIcons from "react-icons/gi";
import * as BsIcons from "react-icons/bs";
import * as MdIcons from "react-icons/md";

export const SidebarData = [
  {
    icon: MdIcons.MdOutlineForwardToInbox,
    heading: "inbox",
    path: "/messages-admin",
  },
  // {
  //   icon: AiIcons.AiFillHome,
  //   heading: "Home",
  //   path: "/homepage-admin",
  // },
  // {
  //   icon: FcIcons.FcServices,
  //   heading: "About Us",
  //   path: "/aboutUs-admin",
  // },
  // {
  //   icon: HiIcons.HiLocationMarker,
  //   heading: "Destinations",
  //   path: "/destination-admin",
  // },
  // {
  //   icon: FaIcons.FaSafari,
  //   heading: "Safaris",
  //   path: "/safaris-admin",
  // },
  // {
  //   icon: FaIcons.FaBed,
  //   heading: "Accommodations",
  //   path: "/accommodation-admin",
  // },
  // {
  //   icon: GiIcons.GiMountainClimbing,
  //   heading: "Climbing & Trekking",
  //   path: "/climbingAndTrekking-admin",
  // },
  // {
  //   icon: BsIcons.BsActivity,
  //   heading: "Activities",
  //   path: "/activities-admin",
  // },
  // {
  //   icon: BsIcons.BsAirplane,
  //   heading: "Travel Info",
  //   path: "/travelInfo-admin",
  // },
  // {
  //   icon: MdIcons.MdPermContactCalendar,
  //   heading: "Contact Us",
  //   path: "/contactUs-admin",
  // },
];
