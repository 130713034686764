import React, { useState, useEffect, useRef } from "react";
import "./climb.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import FooterImages from "../../Components/FooterImages";
import { Link } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);

const mtKmImages = [
  {
    imageID: 1,
    image: "mtKili/1.jpg",
  },
  {
    imageID: 2,
    image: "mtKili/2.jpg",
  },
  {
    imageID: 3,
    image: "mtKili/3.jpg",
  },
  {
    imageID: 4,
    image: "mtKili/4.jpg",
  },
  {
    imageID: 5,
    image: "mtKili/5.jpg",
  },
  {
    imageID: 6,
    image: "mtKili/6.jpg",
  },
  {
    imageID: 7,
    image: "mtKili/7.jpg",
  },
  {
    imageID: 8,
    image: "mtKili/8.jpg",
  },
  {
    imageID: 9,
    image: "mtKili/9.jpg",
  },
  {
    imageID: 10,
    image: "mtKili/10.jpg",
  },
  {
    imageID: 11,
    image: "mtKili/11.jpeg",
  },
  {
    imageID: 12,
    image: "mtKili/12.jpg",
  },
];

const mtMeruImages = [
  {
    imageID: 1,
    image: "mtMeru/1.jpg",
  },
  {
    imageID: 2,
    image: "mtMeru/2.jpg",
  },
  {
    imageID: 3,
    image: "mtMeru/3.jpg",
  },
  {
    imageID: 4,
    image: "mtMeru/4.jpg",
  },
  {
    imageID: 5,
    image: "mtMeru/5.jpg",
  },
];

const oldonyoImages = [
  {
    imageID: 1,
    image: "oldonyo/1.jpg",
  },
  {
    imageID: 2,
    image: "oldonyo/2.jpg",
  },
  {
    imageID: 3,
    image: "oldonyo/3.jpg",
  },
  {
    imageID: 4,
    image: "oldonyo/4.jpeg",
  },
];

const BackgroundHome = ({ images, heading, isMobile }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const imageElements = container.querySelectorAll(".imgt");

    const action = gsap.timeline({ repeat: -1, repeatDelay: 3 });

    imageElements.forEach((imageElement, index) => {
      action.to(imageElement, {
        autoAlpha: 1,
        duration: 1.5,
        delay: index === 0 ? 0 : 3,
      });
    });

    action.play();

    return () => {
      action.kill();
    };
  }, [images]);

  return (
    <div
      ref={containerRef}
      id="slideshow-container"
      className="img-container"
      style={{ height: "55vh", width: "100%", position: "relative" }}
    >
      {images?.map((imageSrc, index) => (
        <div
          key={index}
          className="imgt"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${require(`../../Images/climbing/${imageSrc.image}`)})`,
            backgroundSize: "fit",
            objectFit: "cover",
            backgroundRepeat: "no-repeat",
            opacity: index === 0 ? 1 : 0,
          }}
        >
          <div className="col-12 d-flex justify-content-center "></div>
          <div
            className="col-12 text-center text-light  fw-bold fs-2"
            style={{ padding: isMobile ? "60px" : "120px " }}
          >
            {heading}
          </div>
        </div>
      ))}
    </div>
  );
};

function ClimbingScreen({ data }) {
  const isBiggerScreen = useMediaQuery({ minWidth: 1824 });
  const isBigDesktop = useMediaQuery({ minWidth: 1224, maxWidth: 1823 });
  const isDesktop = useMediaQuery({ minWidth: 992, maxWidth: 1223 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const location = useLocation();
  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location]);

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  useEffect(() => {
    gsap.from(section1Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section1Ref.current,
        start: "top 80%", // Adjust the start position as needed
        end: "bottom 20%", // Adjust the end position as needed
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section2Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section2Ref.current,
        start: "top 100%",
        end: "bottom 100%",
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section3Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section3Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });
  }, []);

  const images = data?.images ? data?.images : [];

  return (
    <div
      className="climbingScreen "
      style={{
        paddingBottom: "10vw",
        minHeight: "100vh"
      }}
    >
      <BackgroundHome images={mtKmImages} heading="MOUNT KILIMANJARO" />
      <div
        // style={{
        //   left: 0,
        // }}
        className="col-12 "
      >
        <div className="col-12 d-flex flex-column align-items-center justify-content-center " id="mtKilimanjaro">
          <div
            className={`${
              isMobile ? "col-12 p-2" : isTablet ? "col-11" : "col-9"
            } mt-4 fs-4 fw-bold fst-italic" id="mount`}
          >
            About MT Kilimanjaro
          </div>
          <div
            className={`${
              isMobile ? "col-12 p-2" : isTablet ? "col-11" : "col-9"
            }  mt-2 `}
          >
            <p>
              In 1889, German geographer Hans Meyer and Austrian mountaineer
              Ludwig Purtscheller became the first people on record to reach the
              summit of Kilimanjaro. Since then, Kilimanjaro has become a
              popular hiking spot for locals and tourists. Because
              mountaineering gear and experience is not needed to reach the
              peak, tens of thousands of climbers ascend the mountain each year.
              It has three main volcanic peaks, Kibo, Mawenzi, and Shira. With
              its snow-capped peak and glaciers, it is the highest mountain in
              Africa. For this combination of features but mostly its height,
              its physical form and snow cap and its isolation above the
              surrounding plains, Mount Kilimanjaro is considered an outstanding
              example of a superlative natural phenomenon. There are endless
              surprises climbing mount Kilimanjaro, it will stimulate your
              senses as you savor a spectacular view of Africa - its skies,
              lands, among where abundant wildlife lives. You will witness
              majestic animals like buffalo, mongooses, blue monkeys, bush pigs,
              elephants and more. Along with your hike, you will see different
              species of plants and vegetations, ferns and humongous trees.
              October is the best month for a Kilimanjaro trip due to agreeable
              weather, which gives you a high chance to reach the summit. June
              to November, as well as February, are also suitable months to
              climb the mountain as there are fewer chances for rain.
            </p>
            <p className="mt-2">
              Mount Kilimanjaro features 6 routes, each with different trekking
              grades. This makes it more convenient for trekkers to choose a
              route that’s best for them, considering their experience, ability
              and number of days available. Here are the different routes to
              climb Kilimanjaro:
            </p>
          </div>
          <div
            className={`${
              isMobile ? "col-12 p-2" : isTablet ? "col-11" : "col-9"
            } d-flex  mt-3`}
          >
            <Link
              className="lnkkHover"
              to={`/safaris/6-Days-Mt-Kilimanjaro-Summit-Climbing-Marangu-Route`}
            >
              <span className="fw-bold  text-nowrap">• Marangu</span>
              <span className="ms-2">
                (5-6 days; Difficulty level: Low) – “The only one with hut
                accommodations”.
              </span>
            </Link>
          </div>
          <div
            className={`${
              isMobile ? "col-12 p-2" : isTablet ? "col-11" : "col-9"
            } d-flex mt-3`}
          >
            <Link
              className="lnkkHover"
              to={`/safaris/Mt-Kilimanjaro-Climbing-Summit–Machame-Route`}
            >
              <span className="fw-bold text-nowrap">• Machame</span>
              <span className="ms-2">
                (6-8 days; Difficulty level: Medium) – “The most popular”.
              </span>
            </Link>
          </div>
          <div
            className={`${
              isMobile ? "col-12 p-2" : isTablet ? "col-11" : "col-9"
            } d-flex  mt-3`}
          >
            <Link
              className="lnkkHover"
              to={`/safaris/Kilimanjaro-Summit-Climbing-Lemosho-Route`}
            >
              <span className="fw-bold text-nowrap">• Lemosho</span>
              <span className="ms-2">
                (7-8 days; Difficulty level: Medium) – “The most scenic”.
              </span>
            </Link>
          </div>
          <div
            className={`${
              isMobile ? "col-12 p-2" : isTablet ? "col-11" : "col-9"
            } d-flex  mt-3`}
          >
            <Link
              className="lnkkHover"
              to={`/safaris/7-Days-Mt-Kilimanjaro-Summit-Climbing-Shira-Route`}
            >
              <span className="fw-bold text-nowrap">• Shira </span>
              <span className="ms-2">
                (7-8 days; Difficulty level: Medium) – “The one with the highest
                starting point”.
              </span>
            </Link>
          </div>
          <div
            className={`${
              isMobile ? "col-12 p-2" : isTablet ? "col-11" : "col-9"
            } d-flex  mt-3`}
          >
            <Link
              className="lnkkHover"
              to={`/safaris/7-Days-Kilimanjaro-Summit-Climbing-Rongai-Route`}
            >
              <span className="fw-bold text-nowrap">• Rongai </span>
              <span className="ms-2">
                (6-7 days; Difficulty level: Low) – “The easiest”.
              </span>
            </Link>
          </div>

          <div
            className={`${
              isMobile ? "col-12 p-2" : isTablet ? "col-11" : "col-9"
            } d-flex  mt-3`}
          >
            <Link
              className="lnkkHover"
              to={`/safaris/7-Days-Mt-Kilimanjaro-Summit-Climbing-Umbwe-Route`}
            >
              <span className="fw-bold text-nowrap">• Umbwe </span>
              <span className="ms-2">
                (5-6 days; Difficulty level: High) – “The shortest, steepest,
                hardest”.
              </span>
            </Link>
          </div>
          <div
            ref={section2Ref}
            className="mt-5 col-12 d-flex justify-content-center align-items-center flex-column"
          >
            <BackgroundHome images={mtMeruImages} heading="MOUNT MERU" />

            <div
              className={`${
                isMobile ? "col-12 p-2" : isTablet ? "col-11" : "col-9"
              } mt-4 fs-4 fw-bold fst-italic `}
            >
              About MT Meru
            </div>
            <div
              className={`${
                isMobile ? "col-12 p-2" : isTablet ? "col-11" : "col-9"
              } mt-2 `}
            >
              <p>
                Mount Meru is the topographic centerpiece of Arusha National
                Park in north of the city of Arusha, in the Arusha Region of
                Tanzania. Its fertile slopes rise above the surrounding savanna
                and support a forest that hosts diverse wildlife, including
                nearly 400 species of birds, monkeys and antelopes. It is the
                second-highest mountain in Tanzania, after Mount Kilimanjaro. It
                is an exciting mountain to climb. The best time to climb the
                mountain is between October and February. Although Mt Meru is
                usually climbed from the western side at Olkokola, it can also
                be climbed via eastern side of the mountain at Momella gate.
              </p>
              <p className="mt-2">
                It really is no surprise to pick Mount Meru as your next
                adventure destination out of the other 1,000,809 mountains in
                the world. Its sheer beauty and amazing trekking opportunities
                make it an infallible holiday destination for anyone who’s
                seeking for great adventures.
              </p>
            </div>
          </div>
          <div
            ref={section3Ref}
            className="mt-5 col-12 d-flex justify-content-center align-items-center flex-column"
          >
            <BackgroundHome images={oldonyoImages} heading="OL DOINYO LENGAI" />
            <div
              className={`${
                isMobile ? "col-12 p-2" : isTablet ? "col-11" : "col-9"
              } mt-4 fs-4 fw-bold fst-italic`}
            >
              About Ol doinyo Lengai
            </div>
            <div
              className={`${
                isMobile ? "col-12 p-2" : isTablet ? "col-11" : "col-9"
              } mt-2 `}
            >
              <p>
                Ol doinyo Lengai is the only active volcanic mountain in the
                East African Rift Valley Region, this makes it special if you
                are keen on learning more on volcanic activities. Ol Doinyo
                Lengai (2890 metres) is located some 240 kilometres to the
                northwest of Arusha town, along the western escarpment of the
                Great Rift Valley, overlooking Lake Natron on the border with
                Kenya. Ol Doinyo Lengai, or Mountain of God in Maasai, is the
                only active volcano in Tanzania. The mountain is surrounded by
                the masai community which is one of Africa’s popular tribe,
                known for strong cultures and living a life outside of
                civilization. The mountain can be climbed in 6 to 8 hours,
                affording breathtaking views of the Great Rift Valley and
                volcanic formations in the Ngorongoro highlands as you get to
                the summit. It is an adventurous hike starting at dawn. Ol
                Doinyo Lengai can be trekked all year round, though the best
                time to tackle this mountain is during the dry season, in the
                rainy season should be avoided. Remember this is an active
                volcano, the mountain remains closed for climbing when we have
                eruptions.
              </p>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}

export default ClimbingScreen;
