import React, { useEffect, useState } from "react";
import "./adminPanel.css";
// import aaa from "../aaa.png";
// import aaaa from "../aaa.png";
import { UilEstate, UilSignOutAlt } from "@iconscout/react-unicons";
import { SidebarData } from "../Datas/AdmPanelData";
import { useDispatch, useSelector } from "react-redux";
// import { activeElmntAct } from "../../Actions/AdminPnAct";
// import { chngAdminThemAct, swtchModeAct } from "../../Actions/ThemeeAct";
// import { useHistory } from "react-router-dom";
// import lg from "./a.png";
import { userLogoutAct } from "../Actions/UsersAction";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
// import { SET_ACTIVE_ELEMEMT_LOADING } from "../../Constants/AdminPanelConst";
import { useLocation, Link } from "react-router-dom";
import logo from "../Logos/logo1.png";
const AdminSideBar = ({ height }) => {
  const [selctdItm, setselctdItm] = useState(0);
  const dispatch = useDispatch();

  const userInformation = useSelector((state) => state.userInformation);
  const { userInfo } = userInformation;

  const location = useLocation();
  const currentPath = location.pathname;
  // const AdminPanelItems = useSelector((state) => state.AdminPanelItems);
  // const { activeItem, success: actvSuccess } = AdminPanelItems;

  // const AdminThemeMode = useSelector((state) => state.AdminThemeMode);
  // const { adminMode, success: adminThemChngScss } = AdminThemeMode;

  // const UserAccessMode = useSelector((state) => state.UserAccessMode);
  // const { accessPage, success: accessModeScss } = UserAccessMode;

  const [actveItm, setactveItm] = useState("dashboard");

  const [drk, setdrk] = useState(false);

  //   let history = useHistory();

  //   const toUserModeHandler = (e) => {
  //     e.preventDefault();
  //     const v = "normalUser";
  //     dispatch(swtchModeAct(v));
  //     history.push("/dashboard");
  //   };

  //   const setActiveHandler = (e, indx) => {
  //     e.preventDefault();
  //     setselctdItm(indx);

  //     dispatch(
  //       activeElmntAct(
  //         indx == "0"
  //           ? "dashboard"
  //           : indx == "1"
  //           ? "orders"
  //           : indx == "2"
  //           ? "suppliers"
  //           : indx == "3"
  //           ? "customers"
  //           : indx == "4"
  //           ? "wareHouse"
  //           : indx == "5"
  //           ? "sales"
  //           : indx == "6"
  //           ? "expenses"
  //           : indx == "7"
  //           ? "employees"
  //           : indx == "8"
  //           ? "users"
  //           : indx == "9"
  //           ? "settings"
  //           : "dashboard"
  //       )
  //     );
  //   };

  //   useEffect(() => {
  //     if (!userInfo) {
  //       history.push("/login");
  //     } else {
  //       if (activeItem && activeItem.item == "dashboard") {
  //         setselctdItm(0);
  //       } else if (activeItem && activeItem.item == "orders") {
  //         setselctdItm(1);
  //       } else if (activeItem && activeItem.item == "suppliers") {
  //         setselctdItm(2);
  //       } else if (activeItem && activeItem.item == "customers") {
  //         setselctdItm(3);
  //       } else if (activeItem && activeItem.item == "wareHouse") {
  //         setselctdItm(4);
  //       } else if (activeItem && activeItem.item == "sales") {
  //         setselctdItm(5);
  //       } else if (activeItem && activeItem.item == "expenses") {
  //         setselctdItm(6);
  //       } else if (activeItem && activeItem.item == "employees") {
  //         setselctdItm(7);
  //       } else if (activeItem && activeItem.item == "users") {
  //         setselctdItm(8);
  //       } else if (activeItem && activeItem.item == "settings") {
  //         setselctdItm(9);
  //       }
  //     }
  //   }, [actvSuccess, SET_ACTIVE_ELEMEMT_LOADING]);

  //   useEffect(() => {
  //     // if (accessModeScss) {
  //     // }
  //     if (!userInfo) {
  //       history.push("/login");
  //     }
  //   }, [accessModeScss]);

  //   function refreshPage() {
  //     setTimeout(() => {
  //       window.location.reload(false);
  //     }, 1);
  //   }

  //   const chngAdmThmHndlr = (e) => {
  //     e.preventDefault();
  //     setdrk(!drk);
  //     dispatch(chngAdminThemAct(drk));
  //   };

  const logoutHandler = (e) => {
    e.preventDefault();
    dispatch(userLogoutAct());
    // refreshPage();
  };

  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 72,
    height: 34,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,

      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            "#fff"
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
      width: 32,
      height: 32,
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      borderRadius: 20 / 2,
    },
  }));

  console.log("adminHeight", height);

  return (
    <div>
      <div
        style={{ minHeight: `${height}vh` }}
        className={
          "darkpanelSidebarr"
          // "panelSidebarr"
        }
      >
        <div className="menu">
          <div className="menuItems mt-4 mb-5">
            <img src={logo} style={{ width: "6rem", height: "5.5rem" }} />
          </div>
          {SidebarData.map((it, index) => {
            return (
              <Link
                to={it.path}
                style={{ textDecoration: "none", all: "unset" }}
              >
                <div
                  // onClick={(e) => setActiveHandler(e, index)}
                  key={index}
                  className={`${
                    it.path == currentPath ? "darkactiveet" : ""
                  } menuItems`}
                >
                  <div className="pe-1">
                    <it.icon />{" "}
                  </div>
                  <span>{it.heading}</span>
                </div>
              </Link>
            );
          })}

          <div className="menuItems">
            <div className="" onClick={logoutHandler}>
              <i class="fa fa-sign-out" aria-hidden="true"></i> Logout
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSideBar;
