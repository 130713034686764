import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import logo from "../Logos/logoNw.png";
import ListGroup from "react-bootstrap/ListGroup";
import { useMediaQuery } from "react-responsive";
import { motion, AnimatePresence } from "framer-motion";
import "./navbar.css";
import { useLocation } from "react-router-dom";
import * as AiIcons from "react-icons/ai";
import * as FcIcons from "react-icons/fc";
import * as FaIcons from "react-icons/fa";
import * as HiIcons from "react-icons/hi";
import * as GiIcons from "react-icons/gi";
import * as BsIcons from "react-icons/bs";
import * as MdIcons from "react-icons/md";
import gsap from "gsap";
import whatsapp from "../Images/whatsappN.png";
const NavContainer = styled.nav`
  background-color: #f2f2f2;
  /* padding: 20px; */
`;

const NavList = styled.ul`
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    display: ${({ isMenuOpen }) => (isMenuOpen ? "flex" : "none")};
  }
`;

// const NavItem = styled.li`
//   margin: 0 10px;
// `;

const NavItem = styled(Link)`
  text-decoration: none;
  color: black;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid transparent;
  transition: border-bottom-color 0.3s;
  margin-right: 14px;
  margin-left: 14px;
  padding: 5px;
  font-size: 13px;
  white-space: no-wrap;
  &:hover {
    border-bottom-color: black;
  }
`;

const NavLink = styled.a`
  color: #333;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;

  &:hover {
    color: #000;
  }
`;

const ImageLink = styled.img`
  width: 100px;
  height: 80px;

  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
  }
`;

const MenuIcon = styled.span`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

const CloseIcon = styled.span`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: ${({ isMenuOpen }) => (isMenuOpen ? "block" : "none")};
  }
`;

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  popup: {
    width: 650,
    height: 400,
    backgroundColor: "#fff",
    borderRadius: 5,
    display: "flex",
    flexDirection: "column",
    paddingBottom: 20,
  },

  txtFontSize: {
    fontSize: "14px",
    color: "#fff",
    padding: "0 10px 0 10px",
    fontFamily: "Geneva, Tahoma, Verdana, sans-serif",
  },
  faMargin: {
    marginTop: "5px",
  },
};

const NavData = [
  {
    id: 1,
    name: "Home",
    icon: AiIcons.AiFillHome,
    path: "/",
  },
  {
    id: 2,
    icon: FcIcons.FcServices,
    name: "About Us",
    path: "/about-us",
  },
  {
    id: 3,
    name: "Destinations",
    icon: HiIcons.HiLocationMarker,
    path: "/destinations",
  },
  {
    id: 4,
    name: "Safaris",
    icon: FaIcons.FaSafari,
    path: "/safaris",
  },
  {
    id: 5,
    name: "Accommodations",
    icon: FaIcons.FaBed,
    path: "/accommodations",
  },
  {
    id: 6,
    name: "Climbing & Trekking",
    icon: GiIcons.GiMountainClimbing,
    path: "/climbing-trekking",
  },
  {
    id: 7,
    name: "Activities",
    icon: BsIcons.BsActivity,
    path: "/activities",
  },
  {
    id: 8,
    name: "Travel Info",
    icon: BsIcons.BsAirplane,
    path: "/travel-info",
  },
  {
    id: 9,
    name: "Contact Us",
    icon: MdIcons.MdPermContactCalendar,
    path: "/contactus",
  },
  {
    id: 10,
    name: "Recommended Gear",
    icon: MdIcons.MdPermContactCalendar,
    path: "/travel-essentials",
  },
];

const NavDataPhone = [
  {
    id: 1,
    name: "Home",
    icon: AiIcons.AiFillHome,
    path: "/",
  },
  {
    id: 2,
    icon: FcIcons.FcServices,
    name: "About Us",
    path: "/about-us",
  },
  {
    id: 3,
    name: "Destinations",
    icon: HiIcons.HiLocationMarker,
    path: "/destinations",
  },
  {
    id: 4,
    name: "Safaris",
    icon: FaIcons.FaSafari,
    path: "/safaris",
  },
  {
    id: 5,
    name: "Accommodations",
    icon: FaIcons.FaBed,
    path: "/accommodations",
  },
  {
    id: 6,
    name: "Climbing & Trekking",
    icon: GiIcons.GiMountainClimbing,
    path: "/climbing-trekking",
  },
  {
    id: 7,
    name: "Activities",
    icon: BsIcons.BsActivity,
    path: "/activities",
  },
  {
    id: 8,
    name: "Travel Info",
    icon: BsIcons.BsAirplane,
    path: "/travel-info",
  },
  {
    id: 9,
    name: "Contact Us",
    icon: MdIcons.MdPermContactCalendar,
    path: "/contactus",
  },
  {
    id: 10,
    name: "Inquire",
    icon: MdIcons.MdPermContactCalendar,
    path: "/inquire",
  },
  {
    id: 12,
    name: "Covid19",
    icon: MdIcons.MdPermContactCalendar,
    path: "/covid-updates",
  },
];

const Navbar = () => {
  const isBiggerScreen = useMediaQuery({ minWidth: 1824 });
  const isBigDesktop = useMediaQuery({ minWidth: 1224, maxWidth: 1823 });
  const isDesktop = useMediaQuery({ minWidth: 992, maxWidth: 1223 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isNotMobile = useMediaQuery({ minWidth: 768 });

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("/");

  const location = useLocation();
  const currentPath = location.pathname;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [currentPath]);

  const textRef = useRef(null);
  const text = "360 Extreme Adventures";
  let charIndex = 0;

  // const type = () => {
  //   if (charIndex < text.length && currentPath !== "/login") {

  //     textRef.current.textContent += text.charAt(charIndex);
  //     charIndex++;
  //   } else {
  //     clearInterval(intervalId);
  //     setTimeout(() => {
  //       textRef.current.textContent = ""; // Clear the typed text
  //       charIndex = 0; // Reset the character index
  //       intervalId = setInterval(type, 100); // Start typing again
  //     }, 5000); // Wait for 5 seconds before clearing and restarting
  //   }
  // };

  const type = () => {
    if (
      textRef.current &&
      charIndex < text.length &&
      currentPath !== "/login"
    ) {
      textRef.current.textContent += text.charAt(charIndex);
      charIndex++;
    } else {
      clearInterval(intervalId);
      setTimeout(() => {
        if (textRef.current) {
          textRef.current.textContent = ""; // Clear the typed text
          charIndex = 0; // Reset the character index
          intervalId = setInterval(type, 100); // Start typing again
        }
      }, 5000); // Wait for 5 seconds before clearing and restarting
    }
  };
  let intervalId;

  useEffect(() => {
    intervalId = setInterval(type, 100); // Start typing
    return () => {
      clearInterval(intervalId); // Clean up the interval when the component unmounts
    };
  }, []);

  return (
    <div>
      <AnimatePresence>
        {isMobile || isTablet ? (
          <>
            <div
              className="col-12   pt-2 pb-2   ps-3 pe-3 mobileScreen"
              style={{
                backgroundColor: "black",
                backgroundImage: `url(${require(`../Logos/logo1.png`)})`,
                backgroundPosition: "left",
                backgroundRepeat: "no-repeat",
                backgroundSize: "20%",
              }}
            >
              <div className="col-12 ps-4 d-flex ">
                <div className="col-8 text-light  d-flex justify-content-center align-items-center"></div>
                <div className="col-4 d-flex justify-content-end ">
                  <i
                    className="fa fa-bars text-light me-1 mt-1"
                    style={{ cursor: "pointer", fontSize: "20px" }}
                    onClick={toggleMenu}
                  ></i>
                </div>
              </div>
              <div
                // style={styles.txtFontSize}
                className="col-12 d-flex text-light pt-2 pb-2 justify-content-center"
              >
                <div className="col-12"></div>
              </div>
            </div>
            {isMenuOpen && (
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20, transition: { duration: 0.5 } }}
                style={{ marginTop: "20px", zIndex: "99" }}
                className="bg-secondary"
              >
                <ListGroup variant="flush" style={{ zIndex: "99" }}>
                  {NavDataPhone?.map((n, index) => {
                    return (
                      <>
                        <Link
                          key={index}
                          style={{
                            textDecoration: "none",
                            color: "black",
                          }}
                          onClick={() => setIsMenuOpen(false)}
                          to={`${n.path}`}
                        >
                          <ListGroup.Item key={n.name} className="hovListItems">
                            {n.name}
                          </ListGroup.Item>
                        </Link>
                      </>
                    );
                  })}
                </ListGroup>
              </motion.div>
            )}
            <div className="col-12 d-flex  justify-contents-center p-2">
              <div className="col-12 compName text-center">
                360 Xtreme Adventures
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className="col-12 bd-dark d-flex pt-1 pb-1  ps-3 pe-3"
              style={{ backgroundColor: "black" }}
            >
              <div className="col-4">
                <ImageLink src={logo} />
              </div>
              <div className="col-8 d-flex pt-4  justify-contents-end flex-row-reverse">
                <Link
                  className="lnkHover"
                  style={styles.stLink}
                  to="/covid-updates"
                >
                  <div className="d-flex lnkHover" style={styles.txtFontSize}>
                    <i
                      className="fa-solid fa-syringe me-1"
                      style={styles.faMargin}
                    ></i>
                    Covid 19 Update
                  </div>
                </Link>
                <Link className="lnkHover" style={styles.stLink} to="/inquire">
                  <div className="d-flex lnkHover" style={styles.txtFontSize}>
                    <i
                      className="far fa-comment-alt me-1"
                      style={styles.faMargin}
                    ></i>
                    Inquire Now
                  </div>
                </Link>
                <div
                  className="lnkHover d-flex me-2"
                  style={(styles.txtFontSize, styles.stLink)}
                >
                  <i
                    className="fa fa-envelope me-1 "
                    style={styles.faMargin}
                  ></i>
                  info@360xtremeadventures.com
                </div>
                <div
                  className="lnkHover d-flex text-light me-2 ms-0 ps-0 mt-1"
                  style={styles.txtFontSize}
                >
                  +255 744 790 003
                </div>

                <div
                  className="lnkHover d-flex text-light me-0 pe-0"
                  style={styles.txtFontSize}
                >
                  <img
                    src={whatsapp}
                    className="icnn"
                    style={{
                      width: isMobile ? "35px" : isTablet ? "40px" : "45px",
                      height: isMobile ? "20px" : isTablet ? "25px" : "30px",
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className="col-12 d-flex pt-2 pb-3 pb-0 pe-2 ps-1 "
              style={{ backgroundColor: " #f2f2f2" }}
            >
              <div className="col-3 ">
                <div
                  // ref={textRef}
                  className="text-start compName mt-1 fw-bold fs-4"
                >
                  360 Xtreme Adventures
                </div>
              </div>
              <div className="col-9 d-flex justify-content-end">
                <NavContainer>
                  <NavList isMenuOpen={isMenuOpen}>
                    {NavData?.map((n, index) => {
                      return (
                        <NavItem
                          key={index}
                          onClick={() => setActiveTab(n.path)}
                          className={`${
                            activeTab == n.path ? "activeLink" : ""
                          }  navItm text-nowrap`}
                          style={{
                            textDecoration: "none",
                            color: "black",
                          }}
                          to={`${n.path}`}
                        >
                          <span className="mt-1 text-nowrap">{n.name}</span>
                        </NavItem>
                      );
                    })}
                  </NavList>
                </NavContainer>
              </div>
            </div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Navbar;
