import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { BookSafarisAct } from "../../Actions/AdminPnAct";
import { CREATE_SAFARI_BOOKING_RESET } from "../../Constants/AdminPanelConst";
import { Spinner } from "react-bootstrap";
import fb from "../../Images/facebook.png";
import insta from "../../Images/instagram.png";
import pin from "../../Images/pinterest.png";
import twitter from "../../Images/twitter.png";
import whatsapp from "../../Images/whatsapp.png";
import utube from "../../Images/youtube.png";
import telegram from "../../Images/telegram.png";
import "./cont.css";
import FooterImages from "../../Components/FooterImages";
import { useMediaQuery } from "react-responsive";
import {
  allCountries,
  roomTypeList,
  accomodationTypeList,
} from "./ContactData";

function Label({ componentName, valueType, isProOnly }) {
  const content = <span className="ms-3">{componentName}</span>;

  if (isProOnly) {
    return (
      <Stack direction="row" spacing={0.5} component="span">
        <Tooltip title="Included on Pro package">
          <a href="/x/introduction/licensing/#pro-plan">
            <span className="plan-pro" />
          </a>
        </Tooltip>
        {content}
      </Stack>
    );
  }

  return content;
}

Label.propTypes = {
  componentName: PropTypes.string.isRequired,
  isProOnly: PropTypes.bool,
  valueType: PropTypes.string.isRequired,
};

function calculateHandoverDate(dateA, dateB) {
  const startDate = new Date(dateA);
  const endDate = new Date(dateB);

  // Check if start date is greater than end date
  if (startDate.getTime() > endDate.getTime()) {
    return "Invalid time";
  }

  // Calculate the period in days
  const periodInDays = Math.floor(
    (endDate - startDate) / (1000 * 60 * 60 * 24)
  );

  // Calculate the period in months
  const periodInMonths = Math.floor(periodInDays / 30);

  // Return the handover date based on the conditions
  if (periodInDays >= 1 && periodInDays <= 29) {
    return `${periodInDays}TDays`;
  } else if (periodInMonths >= 1) {
    return `${periodInMonths}TMonths`;
  }

  return "Invalid";
}

function InquireScreen() {
  const isBiggerScreen = useMediaQuery({ minWidth: 1824 });
  const isBigDesktop = useMediaQuery({ minWidth: 1224, maxWidth: 1823 });
  const isDesktop = useMediaQuery({ minWidth: 992, maxWidth: 1223 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isNotMobile = useMediaQuery({ minWidth: 768 });

  const CreateSafari = useSelector((state) => state.CreateSafari);
  const {
    loading: createSafariLoading,
    error: createSafariError,
    success: createSafariSuccess,
  } = CreateSafari;
  const dispatch = useDispatch();
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [createSafarSuccessSnck, setCreateSafarSuccessSnck] = useState(false);
  const [showInvalidDateSnck, setShowInvalidDateSnck] = useState(false);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);

  const [newsLetterName, setNewsLetterName] = useState(" ");
  const [newsLetterEmail, setNewsLetterEmail] = useState("");
  const [curreLoadng, setCurreLoadng] = useState("");
  const [newsLetterNameError, setNewsLetterNameError] = useState(false);
  const [newsLetterEmailError, setNewsLetterEmailError] = useState(false);
  const [newsLetterEmailTouched, setNewsLetterEmailTouched] = useState(false);
  const [newsLetterNameTouched, setNewsLetterNameTouched] = useState(false);
  const [phn, setPhn] = useState("");
  const [phnError, setPhnError] = useState("");

  const handleDateChange = (date) => {
    setSelectedEndDate(date);
  };

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  useEffect(() => {
    if (createSafariSuccess) {
      setCreateSafarSuccessSnck(true);
      dispatch({
        type: CREATE_SAFARI_BOOKING_RESET,
      });
      resetForm();
      setSelectedEndDate(null);
      setSelectedStartDate(null);
      setCurreLoadng("");
      setNewsLetterName("");
      setNewsLetterEmail("");
    }
  }, [createSafariSuccess]);

  const dateValid = calculateHandoverDate(selectedStartDate, selectedEndDate);

  const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    isValid,
    errors,
    touched,
    resetForm,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      numberOfChildren: 0,
      numberOfPeople: 0,
      accomodationType: "",
      roomType: "",
      country: "",
      moreDesc: "",
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      moreDesc: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email").required("Required"),
      numberOfPeople: Yup.string()
        .required("Required")
        .test("value", "number of people can't be 0", (value) => {
          return value && value == 0 ? false : true;
        }),
      numberOfChildren: Yup.string().required("Required"),

      accomodationType: Yup.string().required("Required"),
      roomType: Yup.string().required("Required"),
      country: Yup.string().required("Required"),
    }),

    onSubmit: ({
      firstName,
      lastName,
      numberOfChildren,
      numberOfPeople,
      accomodationType,
      roomType,
      email,
      moreDesc,
      country,
    }) => {
      if (!selectedStartDate) {
        setStartDateError(true);
      } else if (!selectedEndDate) {
        setEndDateError(true);
      } else if (dateValid == "Invalid time") {
        setShowInvalidDateSnck(true);
      } else if (phn == "") {
        setPhnError("required");
      } else if (phn.length < 9) {
        setPhnError("short");
      } else {
        setCurreLoadng("inq");
        const newPhone = selectCountryCode + " " + phn;
        dispatch(
          BookSafarisAct({
            firstName: firstName,
            lastName: lastName,
            phone: newPhone,
            email: email,
            noOfPeople: numberOfPeople,
            noOfChildren: numberOfChildren,
            roomType: roomType,
            accommodationType: accomodationType,
            moreDesc: moreDesc,
            country: country,
            dateFrom: selectedStartDate.toISOString().slice(0, 10),
            dateTo: selectedEndDate.toISOString().slice(0, 10),
            dType: "INQUIRY",
          })
        );
      }
    },
  });

  const selectCountryCode = `+${
    allCountries?.filter((c) => c.country == values.country)?.[0]?.code
  }`;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowInvalidDateSnck(false);
    if (createSafariError) {
      dispatch({
        type: CREATE_SAFARI_BOOKING_RESET,
      });
    }
    // setShowSnck(false);
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setCreateSafarSuccessSnck(false);
    // setShowSnck(false);
  };

  const newLetterHandler = (e) => {
    e.preventDefault();
    setCurreLoadng("news");
    dispatch(
      BookSafarisAct({
        name: newsLetterName,
        email: newsLetterEmail,
        dType: "newsLetter",
      })
    );
  };

  const handleChangePn = (event) => {
    const { value } = event.target;

    if (value.length === 1 && value.charAt(0) === "0") {
      event.target.value = ""; // Clear the input value if the first character is '0'
      return;
    }

    if (value.length > 9) {
      event.target.value = ""; // Clear the input value if the first character is '0'
      return;
    }

    // Replace non-numeric characters with an empty string
    event.target.value = value.replace(/[^0-9]/g, "");
    setPhn(event.target.value);

    if (value.length < 9) {
      setPhnError("too shnort"); // Clear the input value if the first character is '0'
      return;
    }

    if (value.length === 9) {
      setPhnError(""); // Clear the input value if the first character is '0'
    }
  };

  return (
    <div
      className={`${isMobile && "col-12 p-2"} bg-light contactScreen`}
      style={{
        backgroundImage: `url(${require(`../../Logos/logoT1.png`)})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <Snackbar
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showInvalidDateSnck || createSafariError}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          <AlertTitle>Error</AlertTitle>
          {createSafariError
            ? createSafariError
            : " From Date cant be higher than To Date please enter valid date"}
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={createSafarSuccessSnck}
        onClose={handleSuccessClose}
      >
        <Alert
          onClose={handleSuccessClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          <AlertTitle>Success</AlertTitle>
          Info submited successfully
        </Alert>
      </Snackbar>
      <div className="col-12 d-flex flex-column justify-content-center align-items-center">
        <div className="col-12 mt-4 d-flex flex-column justify-content-center align-items-center">
          <div className="col-12 text-center h4">BOOK INQUIRY</div>
          <div
            className={
              isMobile
                ? "col-12 d-flex flex-column p-2"
                : isTablet
                ? "col-11 d-flex flex-column ps1-"
                : "col-6  d-flex flex-column "
            }
          >
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div className="col-12 mt-2 d-flex flex-wrap">
                <div className={`${isMobile ? "col-12" : "col-6"} p-2 mb-3`}>
                  <TextField
                    className="col-12"
                    label={
                      <div className="d-flex m-0 p-0">
                        First Name
                        <FormHelperText className="text-danger m-0 p-0">
                          *
                        </FormHelperText>
                      </div>
                    }
                    name="firstName"
                    error={touched.firstName && errors.firstName}
                    helperText={touched.firstName && errors.firstName}
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      style: {
                        backgroundColor: "#fff",
                      },
                    }}
                    // InputLabelProps={{
                    //   style: labelStyle,
                    // }}
                    variant="outlined"
                  />
                </div>
                <div className={`${isMobile ? "col-12" : "col-6"} p-2 mb-3`}>
                  <TextField
                    className="col-12"
                    label={
                      <div className="d-flex m-0 p-0">
                        Last Name
                        <FormHelperText className="text-danger m-0 p-0">
                          *
                        </FormHelperText>
                      </div>
                    }
                    name="lastName"
                    value={values.lastName}
                    error={touched.lastName && errors.lastName}
                    helperText={touched.lastName && errors.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      style: {
                        backgroundColor: "#fff",
                      },
                    }}
                    // InputLabelProps={{
                    //   style: labelStyle,
                    // }}
                    variant="outlined"
                  />
                </div>

                <div
                  className={`${
                    isMobile ? "col-12" : !values.country ? "col-12" : "col-6"
                  } p-2 mb-3`}
                >
                  <TextField
                    select
                    className="col-12"
                    label={
                      <div className="d-flex m-0 p-0">
                        {!values.country ? "Select Country" : "Country"}
                        <FormHelperText className="text-danger m-0 p-0">
                          *
                        </FormHelperText>
                      </div>
                    }
                    name="country"
                    value={values.country}
                    error={touched.country && errors.country}
                    helperText={touched.country && errors.country}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      style: {
                        backgroundColor: "#fff",
                      },
                    }}
                    variant="outlined"
                  >
                    <MenuItem value="" style={{ color: "silver" }}>
                      Please Select
                    </MenuItem>
                    {allCountries?.map((option) => (
                      <MenuItem key={option.code} value={option.country}>
                        {option.country}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                {values.country && (
                  <div className={`${isMobile ? "col-12" : "col-6"} p-2 mb-3`}>
                    <TextField
                      className="col-12"
                      label={
                        <div className="d-flex m-0 p-0">
                          Phone
                          <FormHelperText className="text-danger m-0 p-0">
                            *
                          </FormHelperText>
                        </div>
                      }
                      name="phone"
                      error={phnError && phnError.length}
                      helperText={phnError}
                      value={phn}
                      onChange={handleChangePn}
                      InputProps={{
                        style: {
                          backgroundColor: "#fff",
                        },
                        startAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              backgroundColor: "#fff",
                              cursor: "pointer",
                              height: "5rem",
                              paddingRight: "0.7rem",
                              paddingLeft: "0.7rem",
                              paddingTop: "1.6rem",
                              paddingBottom: "1.6rem",
                              margin: "0",
                            }}
                          >
                            {values.country ? selectCountryCode : ""}
                          </InputAdornment>
                        ),
                      }}
                      // InputLabelProps={{
                      //   style: labelStyle,
                      // }}
                      variant="outlined"
                    />
                  </div>
                )}
                <div
                  className={`${
                    !values.country ? "col-12" : isMobile ? "col-12" : "col-12"
                  }  p-2 mb-3`}
                >
                  <TextField
                    className="col-12"
                    label={
                      <div className="d-flex m-0 p-0">
                        Email
                        <FormHelperText className="text-danger m-0 p-0">
                          *
                        </FormHelperText>
                      </div>
                    }
                    name="email"
                    error={touched.email && errors.email}
                    helperText={touched.email && errors.email}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      style: {
                        backgroundColor: "#fff",
                      },
                    }}
                    variant="outlined"
                  />
                </div>

                <div className={`${isMobile ? "col-12" : "col-6"} p-2 mb-3`}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={[
                        "DatePicker",
                        "TimePicker",
                        "DateTimePicker",
                        "DateRangePicker",
                      ]}
                    >
                      <DemoItem
                        label={
                          <Label
                            componentName="Travel Date From:"
                            valueType="date"
                          />
                        }
                      >
                        <DatePicker
                          className={
                            startDateError && !selectedStartDate
                              ? "customer-picker"
                              : ""
                          }
                          name="handover"
                          value={selectedStartDate}
                          onChange={handleStartDateChange}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                  {startDateError && !selectedStartDate ? (
                    <span className="text-danger">Select starting date </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className={`${isMobile ? "col-12" : "col-6"} p-2 mb-3`}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={[
                        "DatePicker",
                        "TimePicker",
                        "DateTimePicker",
                        "DateRangePicker",
                      ]}
                    >
                      <DemoItem
                        label={
                          <Label
                            componentName="Travel Date To"
                            valueType="date"
                          />
                        }
                      >
                        <DatePicker
                          className={
                            endDateError && !selectedEndDate
                              ? "customer-picker"
                              : ""
                          }
                          name="handover"
                          value={selectedEndDate}
                          onChange={handleDateChange}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                  {endDateError && !selectedEndDate ? (
                    <span className="text-danger">Select Ending date </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className={`${isMobile ? "col-12" : "col-6"} p-2 mb-3`}>
                  <TextField
                    className="col-12"
                    label={
                      <div className="d-flex m-0 p-0">
                        Number of people traveling in your group
                        <FormHelperText className="text-danger m-0 p-0">
                          *
                        </FormHelperText>
                      </div>
                    }
                    name="numberOfPeople"
                    error={touched.numberOfPeople && errors.numberOfPeople}
                    helperText={touched.numberOfPeople && errors.numberOfPeople}
                    value={values.numberOfPeople}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      style: {
                        backgroundColor: "#fff",
                      },
                    }}
                    // InputLabelProps={{
                    //   style: labelStyle,
                    // }}
                    variant="outlined"
                  />
                </div>
                <div className={`${isMobile ? "col-12" : "col-6"} p-2 mb-3`}>
                  <TextField
                    className="col-12"
                    label={
                      <div className="d-flex m-0 p-0">
                        Number of children (if any)
                        <FormHelperText className="text-danger m-0 p-0">
                          *
                        </FormHelperText>
                      </div>
                    }
                    name="numberOfChildren"
                    error={touched.numberOfChildren && errors.numberOfChildren}
                    helperText={
                      touched.numberOfChildren && errors.numberOfChildren
                    }
                    value={values.numberOfChildren}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      style: {
                        backgroundColor: "#fff",
                      },
                    }}
                    // InputLabelProps={{
                    //   style: labelStyle,
                    // }}
                    variant="outlined"
                  />
                </div>
                <div className={`${isMobile ? "col-12" : "col-6"} p-2 mb-3`}>
                  <TextField
                    select
                    className="col-12"
                    label={
                      <div className="d-flex m-0 p-0">
                        {!values.accomodationType
                          ? "Select Accommodation Type"
                          : "Accommodation Type"}
                        <FormHelperText className="text-danger m-0 p-0">
                          *
                        </FormHelperText>
                      </div>
                    }
                    name="accomodationType"
                    value={values.accomodationType}
                    error={touched.accomodationType && errors.accomodationType}
                    helperText={
                      touched.accomodationType && errors.accomodationType
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      style: {
                        backgroundColor: "#fff",
                      },
                    }}
                    variant="outlined"
                  >
                    <MenuItem value="" style={{ color: "silver" }}>
                      Please Select
                    </MenuItem>
                    {accomodationTypeList?.map((option) => (
                      <MenuItem key={option.id} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className={`${isMobile ? "col-12" : "col-6"} p-2 mb-3`}>
                  <TextField
                    select
                    className="col-12"
                    label={
                      <div className="d-flex m-0 p-0">
                        {!values.roomType ? "Select Room Type" : "Room Type"}
                        <FormHelperText className="text-danger m-0 p-0">
                          *
                        </FormHelperText>
                      </div>
                    }
                    name="roomType"
                    value={values.roomType}
                    error={touched.roomType && errors.roomType}
                    helperText={touched.roomType && errors.roomType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      style: {
                        backgroundColor: "#fff",
                      },
                    }}
                    variant="outlined"
                  >
                    <MenuItem value="" style={{ color: "silver" }}>
                      Please Select
                    </MenuItem>
                    {roomTypeList?.map((option) => (
                      <MenuItem key={option.id} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="col-12 p-2 mb-3">
                  <TextField
                    multiline
                    rows={8}
                    className="col-12"
                    label={
                      <div className="d-flex m-0 p-0">
                        Tell us More
                        <FormHelperText className="text-danger m-0 p-0">
                          *
                        </FormHelperText>
                      </div>
                    }
                    name="moreDesc"
                    error={touched.moreDesc && errors.moreDesc}
                    helperText={touched.moreDesc && errors.moreDesc}
                    value={values.moreDesc}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      style: {
                        backgroundColor: "#fff",
                      },
                    }}
                    // InputLabelProps={{
                    //   style: labelStyle,
                    // }}
                    variant="outlined"
                  />
                </div>
                <div className="col-12 d-flex mt-3 mb-2 justify-content-center align-items-center">
                  <button
                    className="btn-1"
                    type="submit"
                    style={{
                      opacitiy: (phnError?.length || !phn?.length) && 0.5,
                    }}
                  >
                    {createSafariLoading && curreLoadng == "inq" && (
                      <Spinner
                        variant="light"
                        border="animation"
                        size="sm"
                      ></Spinner>
                    )}{" "}
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div
          className={`${
            isMobile
              ? "col-12"
              : isTablet
              ? "col-8"
              : isDesktop
              ? "col-7"
              : isBigDesktop
              ? "col-6"
              : isBiggerScreen
              ? "col-5"
              : "col-12"
          } pt-4 pb-4 d-flex flex-column`}
        >
          <div className="col-12 mt-2 mb-2 h5 fw-bold text-center">
            Need help?
          </div>
          <div className="col-12  text-center">360 Extreme Adventures</div>
          <div className="col-12 mt-1   text-center">
            Headquarters: Arusha - Tanzania
          </div>
          <div className="col-12 mt-1  text-center">P.O.Box 10716</div>
          <div className="col-12 mt-1  text-center">Arusha -Tanzania</div>
          <div className="col-12 d-flex mt-1  d-flex justify-content-center align-items-center">
            <i className="fa fa-phone mt-1  text-center"></i>: +255 710 005 101
            / +255 744 790 003
          </div>
          <div className="col-12 d-flex mt-1 d-flex justify-content-center align-items-center">
            <i className="mt-1 fa fa-envelope"></i>:
            info@360xtremeadventures.com
          </div>
          <div className="col-12 mt-4 d-flex justify-content-center align-items-center">
            <div className="ps-2 pe-2">
              <a
                className="lnkHover"
                href="https://www.facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={fb}
                  className="icnn"
                  style={{ width: "25px", height: "25px" }}
                />
              </a>
            </div>
            <div className="ps-2 pe-2">
              <a
                className="lnkHover"
                href="https://www.instagram.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="icnn"
                  src={insta}
                  style={{ width: "25px", height: "25px" }}
                />
              </a>
            </div>
            <div className="ps-2 pe-2">
              <a
                className="lnkHover"
                href="https://www.twitter.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="icnn"
                  src={twitter}
                  style={{ width: "25px", height: "25px" }}
                />
              </a>
            </div>
            <div className="ps-2 pe-2">
              <a
                className="lnkHover"
                href="https://www.whatsapp.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={whatsapp}
                  className="icnn"
                  style={{ width: "25px", height: "25px" }}
                />
              </a>
            </div>
            <div className="ps-2 pe-2">
              <a
                className="lnkHover"
                href="https://www.youtube.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="icnn"
                  src={utube}
                  style={{ width: "25px", height: "25px" }}
                />
              </a>
            </div>
            <div className="ps-2 pe-2">
              <a
                className="lnkHover"
                href="https://www.telegram.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="icnn"
                  src={telegram}
                  style={{ width: "25px", height: "25px" }}
                />
              </a>
            </div>
            <div className="ps-2 pe-2">
              <a
                className="lnkHover"
                href="https://www.pinterest.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="icnn"
                  src={pin}
                  style={{ width: "25px", height: "25px" }}
                />
              </a>
            </div>
            {/* (Facebook, Instagram, Twitter, WhatsApp, YouTube, Telegram,
            Pinterest) */}
          </div>
        </div>
        <div className="col-12 pb-5 mt-5 pt-5 d-flex flex-column justify-content-center align-items-center">
          <div className="col-12 text-center h4">NEWSLETTER SIGN UP HERE</div>
          <div
            className={
              isMobile
                ? "col-12 mt-2 d-flex flex-column ps-2"
                : isTablet
                ? "col-11 d-flex flex-column ps-1 mt-2"
                : "col-6  d-flex flex-column mt-2"
            }
          >
            <TextField
              className="col-12"
              label={
                <div className="d-flex m-0 p-0">
                  Name
                  <FormHelperText className="text-danger m-0 p-0">
                    *
                  </FormHelperText>
                </div>
              }
              name="newsLetterName"
              onMouseEnter={() => setNewsLetterNameTouched(true)}
              onMouseLeave={() => {
                if (newsLetterName) {
                  setNewsLetterNameTouched(false);
                } else {
                  setNewsLetterNameTouched(true);
                }
              }}
              error={newsLetterNameTouched && !newsLetterName}
              helperText={
                newsLetterNameTouched && !newsLetterName && "required"
              }
              value={newsLetterName}
              onChange={(e) => setNewsLetterName(e.target.value)}
              variant="outlined"
            />
          </div>
          <div
            className={
              isMobile
                ? "col-12 mt-2 d-flex flex-column ps-2"
                : isTablet
                ? "col-11 d-flex flex-column ps-1 mt-2"
                : "col-6  d-flex flex-column mt-2"
            }
          >
            <TextField
              className="col-12"
              label={
                <div className="d-flex m-0 p-0">
                  Email
                  <FormHelperText className="text-danger m-0 p-0">
                    *
                  </FormHelperText>
                </div>
              }
              name="newsLetterEmail"
              onMouseEnter={() => setNewsLetterEmailTouched(true)}
              // onMouseLeave={() => {
              //   if (newsLetterEmail) {
              //     setNewsLetterEmailTouched(false);
              //   } else {
              //     setNewsLetterEmailTouched(true);
              //   }
              // }}
              error={
                (newsLetterEmailTouched && !newsLetterEmail) ||
                (newsLetterNameTouched &&
                  newsLetterEmail &&
                  !(
                    newsLetterEmail?.includes("@") ||
                    newsLetterEmail?.includes(".")
                  ))
              }
              helperText={
                newsLetterNameTouched && !newsLetterEmail
                  ? "required"
                  : newsLetterNameTouched &&
                    newsLetterEmail &&
                    !(
                      newsLetterEmail?.includes("@") ||
                      newsLetterEmail?.includes(".")
                    )
                  ? "invalid email"
                  : ""
              }
              value={newsLetterEmail}
              onChange={(e) => setNewsLetterEmail(e.target.value)}
              variant="outlined"
            />
          </div>
          <div className="col-6 mt-3 d-flex justify-content-center align-items-center">
            <button
              disabled={!(newsLetterEmail || newsLetterName)}
              onClick={newLetterHandler}
              className="btn-1 "
              style={{ opacitiy: !(newsLetterEmail || newsLetterName) && 0.6 }}
            >
              {createSafariLoading && curreLoadng == "news" && (
                <Spinner variant="light" border="animation" size="sm"></Spinner>
              )}
              subscribe
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InquireScreen;
