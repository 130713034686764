import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./trav.css";
import FooterImages from "../../Components/FooterImages";
import { useMediaQuery } from "react-responsive";
gsap.registerPlugin(ScrollTrigger);

const travImages = [
  {
    id: 1,
    image: "newTravelInfo.jpg",
  },
];

const BackgroundHome = ({ images }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const imageElements = container.querySelectorAll(".imgt");

    const action = gsap.timeline({ repeat: 0, repeatDelay: 0 });

    imageElements.forEach((imageElement, index) => {
      action.to(imageElement, {
        autoAlpha: 1,
        duration: 1.5,
        delay: index === 0 ? 0 : 3,
      });
    });

    action.play();

    return () => {
      action.kill();
    };
  }, [images]);

  return (
    <div
      ref={containerRef}
      id="slideshow-container"
      className="img-container"
      style={{ height: "60vh", width: "100%", position: "relative" }}
    >
      {images?.map((imageSrc, index) => (
        <div
          key={index}
          className="imgt d-flex justify-content-center align-items-center"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${require(`../../Images/11/${imageSrc.image}`)})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            opacity: index === 0 ? 1 : 0,
          }}
        >
          <span className="fw-bold text-light fs-4">
            Discover the Wonders of Travel, Embrace the Adventure.
          </span>
          {/* <div className="col-12 d-flex justify-content-center "></div>
          <div className="col-12 text-center text-light pt-5 fw-bold fs-2">
          </div> */}
        </div>
      ))}
    </div>
  );
};

const FAQ = ({ isMobile, isTablet }) => {
  const [activQN, setActivQN] = useState("");
  const [showMoreQNDesc, setShowMoreQNDesc] = useState(false);

  const expndQNDescHandler = (e, desc) => {
    e.preventDefault();
    if (!showMoreQNDesc) {
      setActivQN(desc);
      setShowMoreQNDesc(true);
    }
    if (showMoreQNDesc && activQN == desc) {
      // setActivITT("");
      setShowMoreQNDesc(false);
    }
    if (showMoreQNDesc && activQN !== desc) {
      setActivQN(desc);
      setShowMoreQNDesc(true);
    }
  };

  return (
    <>
      <div className="col-12 ">
        {/* <div className="col-12 text-center mt-3">
          <h2>FREQUENTLY ASKED QUESTIONS</h2>
        </div> */}
        <div
          className={`col-12  d-flex flex-column   mainDiv align-items-center justify-content-center`}
        >
          <div
            className={`${
              isMobile || isTablet ? "col-12 p-2" : "col-8 p-4"
            } d-flex mb-3  flex-column crdShadow2`}
          >
            <>
              <div className="col-12 d-flex ">
                <div className="col-9 d-flex">
                  {" "}
                  <h4 className="col-12 text-center">
                    How does a safari work?
                  </h4>
                </div>
                <div className="col-3">
                  <button
                    className="pt-1 pe-2 ps-2 pb-1 btnFont  btn-1"
                    onClick={(e) =>
                      expndQNDescHandler(e, "How does a safari work?")
                    }
                  >
                    {activQN == "How does a safari work?" && showMoreQNDesc ? (
                      <>
                        Close <i className="fa fa-angle-up"></i>
                      </>
                    ) : (
                      <>
                        Answer <i className="fa fa-angle-down"></i>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </>

            {activQN == "How does a safari work?" && showMoreQNDesc && (
              <div className="mt-4 col-12">
                <p>
                  During a safari, you wake up early and have breakfast. The
                  morning often starts with a game drive where you start driving
                  from 8am or so. The guide will drive you to ideal vantage
                  points to track down the animals following them in the wild.
                  Note that in an African safari, no one knows where a lion or
                  elephant would be hanging out that day. Of course, experience,
                  time of the year, and weather among other factors help our
                  guide identify plausible locations so you don’t drive the
                  whole day seeing nothing. But be ready for surprises as you’ll
                  never know if you’ll witness a cheetah hunting its meal or see
                  a family of lions. This is what makes a Tanzania safari truly
                  a once in a lifetime experience. Your guide will also help you
                  pick an excellent spot for your packed lunch and, later on,
                  snacks. After the day’s tour, you’ll get back to the camp in
                  the afternoon. At dinner, you get to eat heartily and enjoy
                  fireside drinks. Keep in mind that a safari is generally
                  unpredictable, and this plan may change depending on how the
                  events unfold.
                </p>
              </div>
            )}
          </div>
          <div
            className={`${
              isMobile || isTablet ? "col-12 p-2" : "col-8 p-4"
            } d-flex mb-3  flex-column crdShadow2`}
          >
            <>
              <div className="col-12 d-flex ">
                <div className="col-9 d-flex">
                  {" "}
                  <h4 className="col-12 text-center">
                    Is it a good idea to bring kids or elders?
                  </h4>
                </div>
                <div className="col-3">
                  <button
                    className="pt-1 pe-2 ps-2 pb-1 btnFont  btn-1"
                    onClick={(e) =>
                      expndQNDescHandler(
                        e,
                        "Is it a good idea to bring kids or elders?"
                      )
                    }
                  >
                    {activQN == "Is it a good idea to bring kids or elders?" &&
                    showMoreQNDesc ? (
                      <>
                        Close <i className="fa fa-angle-up"></i>
                      </>
                    ) : (
                      <>
                        Answer <i className="fa fa-angle-down"></i>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </>

            {activQN == "Is it a good idea to bring kids or elders?" &&
              showMoreQNDesc && (
                <div className="col-12 mt-4">
                  <p>
                    Absolutely! Kids will have fun witnessing life in the wild.
                    You will be in the vehicle most of the time and you can stop
                    as you wish or go on a lower pace as needed, which makes
                    this a great option for elders too. Similarly, most lodges
                    have family-friendly accommodation with no stairs and less
                    walking. The meals are usually a buffet, giving you ample
                    choices for all taste and preference.
                  </p>
                </div>
              )}
          </div>
          <div
            className={`${
              isMobile || isTablet ? "col-12 p-2" : "col-8 p-4"
            } d-flex mb-3  flex-column crdShadow2`}
          >
            <>
              <div className="col-12 d-flex ">
                <div className="col-9 d-flex">
                  {" "}
                  <h4 className="col-12 text-center">
                    What kind of food is served?
                  </h4>
                </div>
                <div className="col-3">
                  <button
                    className="pt-1 pe-2 ps-2 pb-1  btnFont btn-1"
                    onClick={(e) =>
                      expndQNDescHandler(e, "What kind of food is served?")
                    }
                  >
                    {activQN == "What kind of food is served?" &&
                    showMoreQNDesc ? (
                      <>
                        Close <i className="fa fa-angle-up"></i>
                      </>
                    ) : (
                      <>
                        Answer <i className="fa fa-angle-down"></i>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </>

            {activQN == "What kind of food is served?" && showMoreQNDesc && (
              <div className="col-12 mt-4">
                <p>
                  Meals provided in your accommodation is usually buffet style.
                  Breakfasts include eggs, potatoes, cereals, bacon/sausage, and
                  toast among others. For dinner, you can expect a variety of
                  soups, salads, local and international meals, rice, pasta,
                  fruits and desserts. At lunch, most guests are out in a safari
                  so they bring a lunchbox with a sandwich, raw veggies, yogurt,
                  juice, hard-boiled eggs, and cookies. However, you can also
                  opt for a hot meal where we can set-up a number of dishes
                  served in buffet trays in an ambient spot where you have a
                  view of the savannah.
                </p>
              </div>
            )}
          </div>
          <div
            className={`${
              isMobile || isTablet ? "col-12 p-2" : "col-8 p-4"
            } d-flex mb-3  flex-column crdShadow2`}
          >
            <>
              <div className="col-12 d-flex ">
                <div className="col-9 d-flex">
                  {" "}
                  <h4 className="col-12 text-center">
                    When is the best time to visit Tanzania?
                  </h4>
                </div>
                <div className="col-3">
                  <button
                    className="pt-1 pe-2 ps-2 pb-1 btnFont  btn-1"
                    onClick={(e) =>
                      expndQNDescHandler(
                        e,
                        "When is the best time to visit Tanzania?"
                      )
                    }
                  >
                    {activQN == "When is the best time to visit Tanzania?" &&
                    showMoreQNDesc ? (
                      <>
                        Close <i className="fa fa-angle-up"></i>
                      </>
                    ) : (
                      <>
                        Answer <i className="fa fa-angle-down"></i>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </>

            {activQN == "When is the best time to visit Tanzania?" &&
              showMoreQNDesc && (
                <div className="mt-4">
                  <div className="col-12">
                    <p>
                      Tanzania is situated fairly close to the equator and the
                      seasons can be divided loosely into rainy and dry seasons.
                      The best wildlife viewing months in Tanzania are during
                      the Dry season from late{" "}
                      <span style={{ color: "#F1C40F" }}>June</span> to{" "}
                      <span style={{ color: "#D4AC0D" }}>October</span>. The
                      best chance of seeing the wildebeest migration in the
                      Serengeti is during{" "}
                      <span style={{ color: "#F1C40F" }}>June</span> and{" "}
                      <span style={{ color: "#D4AC0D" }}>July</span> and the
                      time to see the wildebeest calving is late{" "}
                      <span style={{ color: "#4188C3" }}>January</span> to{" "}
                      <span style={{ color: "#6AA4D5" }}>February</span>.
                      Tanzania’s main rainy season, or the{" "}
                      <span style={{ color: "#1C880B" }}>‘long rains’</span>,
                      last during about{" "}
                      <span style={{ color: "#1C880B " }}>March, April,</span>{" "}
                      and <span style={{ color: "#1C880B " }}>May.</span> Short
                      rainy season in end of{" "}
                      <span style={{ color: "#A6ACAC" }}>October</span> to
                      middle of{" "}
                      <span style={{ color: "#C1C3C3" }}>December</span>.
                    </p>
                  </div>
                  <div className="col-12 mt-2">
                    <p>
                      Most visitors choose to come during the dry seasons, as
                      there is very little chance of rain and the wildlife
                      viewing is better. Whilst you may experience rain, the
                      benefits of coming during rainy season are that the
                      countryside is green, lush and spectacularly beautiful.
                      Crops are growing and the animals have good sources of
                      food and water. You will see plenty of wildlife during the
                      rainy seasons, but less than in dry seasons.
                    </p>
                  </div>
                </div>
              )}
          </div>
          <div
            className={`${
              isMobile || isTablet ? "col-12 p-2" : "col-8 p-4"
            } d-flex mb-3  flex-column crdShadow2 `}
          >
            <>
              <div className="col-12 d-flex ">
                <div className="col-9 d-flex">
                  {" "}
                  <h4 className="col-12 text-center">
                    Is accommodation included in the booking?
                  </h4>
                </div>
                <div className="col-3">
                  <button
                    className="pt-1 pe-2 ps-2 pb-1 btnFont  btn-1"
                    onClick={(e) =>
                      expndQNDescHandler(
                        e,
                        "Is accommodation included in the booking?"
                      )
                    }
                  >
                    {activQN == "Is accommodation included in the booking?" &&
                    showMoreQNDesc ? (
                      <>
                        Close <i className="fa fa-angle-up"></i>
                      </>
                    ) : (
                      <>
                        Answer <i className="fa fa-angle-down"></i>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </>

            {activQN == "Is accommodation included in the booking?" &&
              showMoreQNDesc && (
                <>
                  <div className="col-12 mt-4">
                    <p>
                      All our safaris are inclusive of meals and accommodation
                      for the entire duration of your safari as well as domestic
                      travels to the destination, park & camping fees.
                      Accommodation may be at different places. When we send a
                      proposed itinerary, it will include the lodging that we
                      recommend best based on your budget and preference. Should
                      you wish to receive other accommodation proposals offer or
                      have specific ones in mind, just let us know.
                    </p>
                  </div>
                </>
              )}
          </div>
          <div
            className={`${
              isMobile || isTablet ? "col-12 p-2" : "col-8 p-4"
            } d-flex mb-3  flex-column  crdShadow2  `}
          >
            <>
              <div className="col-12 d-flex ">
                <div className="col-9 d-flex">
                  {" "}
                  <h4 className="col-12 text-center">
                    How safe is Tanzania for tourists?
                  </h4>
                </div>
                <div className="col-3">
                  <button
                    className="pt-1 pe-2 ps-2 pb-1  btnFont btn-1"
                    onClick={(e) =>
                      expndQNDescHandler(
                        e,
                        "How safe is Tanzania for tourists?"
                      )
                    }
                  >
                    {activQN == "How safe is Tanzania for tourists?" &&
                    showMoreQNDesc ? (
                      <>
                        Close <i className="fa fa-angle-up"></i>
                      </>
                    ) : (
                      <>
                        Answer <i className="fa fa-angle-down"></i>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </>

            {activQN == "How safe is Tanzania for tourists?" &&
              showMoreQNDesc && (
                <>
                  <div className="col-12 mt-4">
                    <p>
                      Tanzania is generally considered to be a safe place for
                      tourists, especially as majority of visitors will be
                      looked after by their Tour Operator from the moment, they
                      land till depart. That said, you do need to take the usual
                      precautions and keep up with government travel advisories.
                    </p>
                    <p className="mt-2">
                      At night in towns, it’s best to use taxi rather than
                      walking around the streets. Wearing expensive looking
                      jewelry/watches and carrying cameras, will draw
                      unnecessary attention. Tanzania is quite a conservative
                      culture, so please avoid wearing ‘short shorts’ and
                      low-cut tops. These can attract unwanted attention and can
                      cause offence. All town and cities have their share of
                      petty crime and sensible precautions need to be taken.
                    </p>
                  </div>
                </>
              )}
          </div>
          <div
            className={`${
              isMobile || isTablet ? "col-12 p-2" : "col-8"
            } d-flex mb-3  flex-column crdShadow2 ps-4 pe-4 pt-2 pb-2`}
          >
            <>
              <div className="col-12 d-flex mt-3 mb-4">
                <div className="col-9 d-flex">
                  {" "}
                  <h4 className="col-12  text-center">
                    How can I lower the cost of my safari?
                  </h4>
                </div>
                <div className="col-3">
                  <button
                    className="pt-1 pe-2 ps-2 pb-1 btnFont  btn-1"
                    onClick={(e) =>
                      expndQNDescHandler(
                        e,
                        "How can I lower the cost of my safari?"
                      )
                    }
                  >
                    {activQN == "How can I lower the cost of my safari?" &&
                    showMoreQNDesc ? (
                      <>
                        Close <i className="fa fa-angle-up"></i>
                      </>
                    ) : (
                      <>
                        Answer <i className="fa fa-angle-down"></i>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </>
            {activQN == "How can I lower the cost of my safari?" &&
              showMoreQNDesc && (
                <>
                  <div className="col-12">
                    <p>
                      One of the best ways to keep your budget at bay is to
                      reduce the number of days of your safari as doing so means
                      you have less number of days to pay for accommodation,
                      food expense, and park fees, which are paid on a daily
                      basis. Also take in mind the type of accommodation
                      preferred. The midrange option is a great choice for those
                      on a budget. You still get to enjoy great buffet meals,
                      and mid level amenities. Luxury lodges are common in
                      Tanzania but they come with a price to avail amenities
                      such as bath-tub, pool etc…
                    </p>
                  </div>

                  <div className="text-center col-12 fw-bold">FACT </div>
                  <div>
                    {" "}
                    <h4>In Addition</h4>
                  </div>
                  <div className="col-12">
                    <p>
                      At 360 Extreme Adventures, we are honest and transparent
                      about our pricing. We feel it’s important for you to
                      understand how we arrive at the final price and the
                      different factors that can influence this
                    </p>
                  </div>
                  <div className="col-12 mt-3 h3 text-center">
                    ACCOMMODATION
                  </div>
                  <div className="col-12 mt-2">
                    <p>
                      The below information gives you a rough idea of the costs
                      involved e.g. 5 days Mid-Range Safari will cost you
                      somewhere in the region of 5 x US$ 350 = US$ 1,750 per
                      person.
                    </p>
                  </div>
                  <div className="col-12 mt-2">
                    <div className="col-12 h4">
                      Approximate daily cost of doing a Tanzania Safari
                    </div>
                    <div className="col-10 mt-2">
                      <table className="col-12 table table-sm table-bordered">
                        <thead>
                          <tr>
                            <th className="text-center">Type of Safari</th>
                            <th className="text-center">
                              Cost (per person per day)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="">
                              <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                                <div className="">Basic Camping</div>
                                <div className="">(in tents on the ground)</div>
                              </div>
                            </td>
                            <td className="text-center">US$ 200</td>
                          </tr>
                          <tr>
                            <td className="">
                              <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                                <div className="">Mid-Range</div>
                                <div className="">(lodges & tented camps)</div>
                              </div>
                            </td>
                            <td className="text-center">US$ 350</td>
                          </tr>
                          <tr>
                            <td className="">
                              <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                                <div className="">Luxury</div>
                                <div className="">(lodges & tented camps)</div>
                              </div>
                            </td>
                            <td className="text-center">US$ 500</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="col-12 h5">Duration</div>
                    <div className="col-12 ">
                      <p>
                        The price of your safari will of course be influence by
                        its length. Our most popular safari is 5 days. This
                        allows you to enjoy enough time to a chosen park.
                      </p>
                      <p className="mt-2">
                        If you want to increase the number of days on safari,
                        but are working to a strict budget, you may wish to
                        consider the following:
                      </p>
                      <div className="col-12">
                        <table className="col-12">
                          <tr>
                            <td>1.</td>
                            <td>
                              Change the category/ cost of your accommodation
                            </td>
                          </tr>
                          <tr>
                            <td>2.</td>
                            <td>
                              Ask additional people to join your safari (the
                              more people inside the jeep, the lower the cost)
                            </td>
                          </tr>
                          <tr>
                            <td>3.</td>
                            <td>
                              {" "}
                              Visit during the low season/s (some accommodation
                              offers lower rates during times)
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="mt-3 col-12">
                        <div className="col-12 h6">
                          Standard of accommodation
                        </div>
                        <div className="col-12 mt-2">
                          <p>
                            The kind of accommodation you choose to stay in will
                            be a significant factor in the final cost of your
                            Tanzania Safari. 360 Extreme Adventures can be
                            grouped into three different categories:
                          </p>
                        </div>
                      </div>
                      <div className="col-12 mt-3">
                        <div className="ps-2 h4 col-12 d-flex">
                          • Tanzania Basic Camping Safaris (Tents on the ground)
                        </div>
                        <div className="mt-2">
                          <p>
                            On a basic camping safari, guest sleeps in private
                            tent in designated campsites in or near national
                            parks. Our safari crew set up the camp and prepares
                            all the meals. Facilities are basic. They include
                            washrooms and shower. All your food is provided
                          </p>
                        </div>
                      </div>
                      <div className="col-12 mt-3 d-flex flex-column">
                        <div className="ps-2 h4 col-12 d-flex">
                          • Tanzania Mid-Range Safari (Lodges & Tented Camps)
                        </div>
                        <div className="mt-2 col-12">
                          <p>
                            Lodge are full-service facilities with private rooms
                            or cottages with private bathroom and have
                            restaurants and lounges. Some have swimming pools.
                            Many lodges are architecturally stunning made with
                            local material and inspired by local and the
                            environment.
                          </p>
                        </div>
                      </div>
                      <div className="col-12 mt-3 d-flex flex-column">
                        <div className="ps-2 h4 fst-italic  col-12 d-flex">
                          Tented Camps
                        </div>
                        <div className="mt-2 col-12">
                          <p>
                            Are permanent or semi-permanent camps sited in areas
                            of excellent game viewing. These provide many of the
                            comfort of a lodge within private spacious tents on
                            a solid permanent base with beds and private baths,
                            and high-quality meals. Tented camps are more
                            intimate than lodges as they generally accommodate
                            12 to 20 guests
                          </p>
                        </div>
                      </div>
                      <div className="col-12 mt-3 d-flex flex-column">
                        <div className="ps-2 h4 col-12 d-flex">
                          • Luxury Safaris (Lodge & Tented Camps)
                        </div>
                        <div className="mt-2 col-12">
                          <p>
                            The difference between Mid-Range and Luxury safaris
                            is primarily the level of accommodation you will
                            stay at. The difference between Mid-Range and Luxury
                            accommodation may include the level of service,
                            facilities, amenities, quality of food, views and of
                            course price
                          </p>
                          <p className="mt-2">
                            Your 360 Extreme Adventures Safari Consultant will
                            be happy to plan you an itinerary with all
                            accommodation of a similar standard, or we can mix
                            and match different categories of accommodation to
                            match your budget.
                          </p>
                        </div>
                      </div>
                      <div className="col-12 mt-4">
                        <div className="col-12 h6">
                          Sample pricing for mid-range accommodation whilst on
                          Safari
                        </div>
                        <div className="col-12">
                          <table className="col-10 table table-sm table-bordered">
                            <thead>
                              <th className="text-center pt-2 pb-2" colSpan="3">
                                Cost per person
                              </th>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="text-center">Area</td>
                                <td className="text-center">(per night)</td>
                                <td className="text-center">Meal Plan</td>
                              </tr>
                              <tr>
                                <td className="text-center">Arusha</td>
                                <td className="text-center">US$ 75</td>
                                <td className="text-center">Full Board</td>
                              </tr>
                              <tr>
                                <td className="text-center">Karatu</td>
                                <td className="text-center">US$ 100</td>
                                <td className="text-center">Full Board</td>
                              </tr>
                              <tr>
                                <td className="text-center">
                                  Ngorongoro Conservation Area
                                </td>
                                <td className="text-center">US$ 250</td>
                                <td className="text-center">Full Board</td>
                              </tr>
                              <tr>
                                <td className="text-center">
                                  Serengeti Central
                                </td>
                                <td className="text-center">US$ 240</td>
                                <td className="text-center">Full Board</td>
                              </tr>
                              <tr>
                                <td className="text-center">Serengeti North</td>
                                <td className="text-center">US$ 285</td>
                                <td className="text-center">Full Board</td>
                              </tr>
                              <tr>
                                <td className="text-center">
                                  Serengeti South/ Ndutu
                                </td>
                                <td className="text-center">US$ 285</td>
                                <td className="text-center">Full Board</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="col-12 mt-2">
                          <p>
                            These costs are rough guide only. Accommodation
                            prices do vary and our Safari Consultant will
                            provide you with a bespoke quote, based on your
                            request and/ or our recommendations
                          </p>
                        </div>
                      </div>
                      <div className="col-12 mt-3 d-flex flex-column">
                        <div className=" h5 col-12 d-flex">National Parks</div>
                        <div className="mt-2  col-12 d-flex">
                          Entrance fees to the National Parks vary
                        </div>
                        <div className="mt-2 col-12">
                          <p>
                            The fees that you pay are used in a variety of
                            conservation projects which support the National
                            Parks and surrounding communities. These include
                            wildlife protection, ecological monitoring, tourism
                            management and park infrastructure and security.
                          </p>
                        </div>
                        <div className="mt-2 col-12">
                          <table className="col-12 table table-sm table-bordered">
                            <thead>
                              <tr>
                                <th className="text-center">National Park</th>
                                <th className="text-center">
                                  Park Fees (per adult, per 24 hours)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="text-center">
                                  Arusha National Park
                                </td>
                                <td className="text-center">US$ 59</td>
                              </tr>
                              <tr>
                                <td className="text-center">
                                  Lake Manyara National Park
                                </td>
                                <td className="text-center">US$ 59</td>
                              </tr>
                              <tr>
                                <td className="text-center">
                                  Tarangire National Park
                                </td>
                                <td className="text-center">US$ 59</td>
                              </tr>
                              <tr>
                                <td className="text-center col-6">
                                  Ngorongoro Conservation Area (this also has to
                                  be paid if you are transiting through
                                  Ngorongoro Conservation Area to reach the
                                  Serengeti)
                                </td>
                                <td className="text-center">US$ 71</td>
                              </tr>
                              <tr>
                                <td className="text-center">
                                  Ngorongoro Crater Fee
                                </td>
                                <td className="text-center">
                                  US$ 259 (per vehicle)
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center">
                                  Serengeti National Park
                                </td>
                                <td className="text-center">US$ 83</td>
                              </tr>
                              <tr>
                                <td className="text-center">
                                  Special Camping Fee
                                </td>
                                <td className="text-center">
                                  US$ 71 (per night)
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="col-12 mt-2">
                          <p>
                            This fee has to be paid in addition to the daily
                            park fees. If you want to visit Crater, this makes
                            it one of the most expensive days on your safari.
                            However, it really worth it.
                          </p>
                          <p className="mt-3">
                            This additional fee is not charged by your
                            accommodation, but TANAPA (Tanzania National Parks
                            Authority). You do not have to pay this if you are
                            doing a basic safari and camping in tents on the
                            ground
                          </p>
                        </div>
                        <div className="col-12 mt-2">
                          <div className="col-12 h5">Optional Activities</div>
                          <p>
                            In your additional to your game drives, there are a
                            number of optional activities that can be included
                            in your safari. These include:
                          </p>
                          <p className="mt-3">
                            This additional fee is not charged by your
                            accommodation, but TANAPA (Tanzania National Parks
                            Authority). You do not have to pay this if you are
                            doing a basic safari and camping in tents on the
                            ground
                          </p>
                        </div>
                        <div className="col-12">
                          <table className="col-12 table table-sm table-bordered">
                            <thead>
                              <tr>
                                <th className="text-center">Activity</th>
                                <th className="text-center">
                                  Approximate Cost
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="text-center col-6">
                                  Walking Safari (Ngorongoro Crater rim, Arusha
                                  & Lake Manyara National Park)
                                </td>
                                <td className="text-center">
                                  US$ 25 per person
                                </td>
                              </tr>

                              <tr>
                                <td className="text-center col-6">
                                  Night Game Drive (Lake Manyara National Park)
                                </td>
                                <td className="text-center">
                                  US$ 170 per person
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center col-6">
                                  Cultural Visit (Maasai boma ‘’village’’
                                  Ngorongoro)
                                </td>
                                <td className="text-center">
                                  US$ 50 per person
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center col-6">
                                  Balloon Safari (Tarangire & Serengeti)
                                </td>
                                <td className="text-center">
                                  US$ 600 per person
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center col-6">
                                  Boat & Canoeing Safari (Saadani, Lake Manyara
                                  & Momella Lake within Arusha National Park)
                                </td>
                                <td className="text-center">
                                  US$ 50 per person
                                </td>
                              </tr>

                              <tr>
                                <td className="text-center col-6">
                                  Historical Sites (Olduvai Gorge & Shifting
                                  Sand)
                                </td>
                                <td className="text-center">
                                  US$ 35 per person
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="col-12">
                          <div className="col-12 text-center h6">Finally</div>
                          <div className="col-12  mt-2">
                            <p>
                              The tourism industry plays a very important part
                              in the lives of many Tanzanians. The money that
                              you pay helps to support a vast number of
                              industries, people and their families. This
                              includes guides, chefs, porters, office team,
                              hotel staff, local farmers, vendors and
                              craftspeople. As a final note, your money helps to
                              conserve Tanzania’s exceptionally precious natural
                              resources and wildlife. Tanzania is a country that
                              has so much to offer and we feel privileged share
                              it with the rest of the world. We hope to welcome
                              you soon. You will have an unforgettable trip.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
          </div>

          <div
            className={`${
              isMobile || isTablet ? "col-12 p-2" : "col-8"
            } d-flex mb-3  flex-column p-4  flex-column crdShadow2`}
          >
            <>
              <div className="col-12 d-flex">
                <div className="col-9 d-flex">
                  {" "}
                  <h4 className="col-12 text-center">
                    How many days should I stay?
                  </h4>
                </div>
                <div className="col-3">
                  <button
                    className="pt-1 pe-2 ps-2 pb-1 btnFont   btn-1"
                    onClick={(e) =>
                      expndQNDescHandler(e, "How many days should I stay?")
                    }
                  >
                    {activQN == "How many days should I stay?" &&
                    showMoreQNDesc ? (
                      <>
                        Close <i className="fa fa-angle-up"></i>
                      </>
                    ) : (
                      <>
                        Answer <i className="fa fa-angle-down"></i>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </>

            {activQN == "How many days should I stay?" && showMoreQNDesc && (
              <>
                <div className="col-12  mt-4">
                  <p>
                    Three to five days is our recommended minimum stay to
                    provide enough time to enjoy a chosen park, for example, the
                    Serengeti. Our ideal duration would be from 5 to 7 days and
                    longer if you wish to see the top parks (around 2-4 parks)
                    without rushing to the next one as well as enjoy your
                    accommodation’s amenities. Note that transfers between parks
                    alone take time.
                  </p>
                </div>
              </>
            )}
          </div>

          <div
            className={`${
              isMobile || isTablet ? "col-12 p-2" : "col-8"
            } d-flex mb-3  flex-column p-4  flex-column crdShadow2`}
          >
            <>
              <div className="col-12 d-flex">
                <div className="col-9 d-flex">
                  {" "}
                  <h4 className="col-12 text-center">
                    What Can I Expect In Tanzania Safari?
                  </h4>
                </div>
                <div className="col-3">
                  <button
                    className="pt-1 pe-2 ps-2 pb-1 btnFont   btn-1"
                    onClick={(e) =>
                      expndQNDescHandler(
                        e,
                        "WHAT CAN I EXPECT IN TANZANIA SAFARI?"
                      )
                    }
                  >
                    {activQN == "WHAT CAN I EXPECT IN TANZANIA SAFARI?" &&
                    showMoreQNDesc ? (
                      <>
                        Close <i className="fa fa-angle-up"></i>
                      </>
                    ) : (
                      <>
                        Answer <i className="fa fa-angle-down"></i>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </>
            {activQN == "WHAT CAN I EXPECT IN TANZANIA SAFARI?" &&
              showMoreQNDesc && (
                <>
                  <div className="col-12  mt-4">
                    <p>
                      Almost everyone dreams of going to a safari in Tanzania,
                      arguably the best safari destination in the world. To
                      some, it’s another tick off the bucket list; to others,
                      it’s a dream wildlife experience; and to some others, it’s
                      passion. Whatever the reason for traveling, there is
                      always that inner anxiety on what to expect in a safari in
                      Tanzania. Here as follow:
                    </p>
                  </div>

                  <div className="col-12 mt-3">
                    <div className="col-12 fw-bold h6">
                      LONG DRIVES AN EARLY START
                    </div>
                    <div className="col-12  mt-2">
                      <p>
                        Since each park is huge and you’ll need some time to
                        find a multitude of animals, you’re going to be inside
                        the safari vehicle for up to 4 to 5 hours. Most animals
                        are super active early in the morning just before
                        sunrise and so typically, your safari starts that early
                        in the morning.
                      </p>
                      <p className="mt-2">
                        You will be basked under the hot African sun for hours
                        and because of the road’s conditions, there will be a
                        lot of bumping and bouncing around. Unlike in the zoo
                        where animals are contained in one area, animals in
                        Africa are free to roam anywhere. This means that if you
                        are set to see lions, giraffes, zebras, cheetahs, and
                        other animals, it might take some time. No worries,
                        though, because the wilderness will always make you go
                        wow. Also, there are so many bird species around
                        Tanzania.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <div className="col-12 fw-bold h6">MEALS</div>
                    <div className="col-12  mt-2">
                      <p>
                        Food in safaris is glorious! It’s either you eat on your
                        lodge/camp or dine among the animals in a picnic mat, a
                        picnic table or even on your safari vehicle! You will
                        have the schedule/itinerary beforehand so you would know
                        what time your meals and snacks will be served. You also
                        don’t have to worry because chefs prepare your meals
                        special and you can also ask for any diet restrictions
                        you may have.
                      </p>
                      <p className="mt-2">
                        Food in the lodges are great but when you go on a
                        safari, you bring a “lunchbox” where options can be
                        limited as it has to last several hrs before being
                        consumed. Typical options include sandwiches often white
                        bread, fried foods (chicken), boiled egg and some
                        fruits. But expect breakfast and dinner to make up for
                        it as lodges often prepare both local and international
                        cuisine and it is normally served buffet style so you’re
                        sure to have lots of options
                      </p>
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <div className="col-12 fw-bold h6">
                      DIVERSITY & CONCETRATION LANDSCAPES
                    </div>
                    <div className="col-12  mt-2">
                      <p>
                        Tanzania is blessed with a widely diverse ecosystem that
                        ranges from golden savannas and plains to mountains and
                        forests to waterfalls and walled cities to islands that
                        offer a tropical paradise of exquisite proportions. In a
                        typical safari, you will be able to witness a glorious
                        display of these landscapes. You will be able to drive
                        through rough roads, some almost impassable during
                        certain times, and you will be able to enjoy a striking
                        natural scenery all throughout. One of the most visited
                        parks that offers a unique landscape is the Ngorongoro
                        Conservation Area. Its main highlight is the UNESCO
                        World Heritage Site and Africa’s “Garden of Eden”,
                        Ngorongoro crater, which is a massive volcanic caldera
                        that provides a safe haven to 25,000 large animals
                        including 26 black rhinoceros and about 62 strong lions.
                      </p>
                      <p className="mt-2">
                        Water is never scarce in the area and its gorgeous
                        vistas just make it impossible not to be included in any
                        African destinations checklist. Another iconic
                        attraction that boasts of a majestic landscape is Mount
                        Kilimanjaro. It is Africa’s highest peak and the world’s
                        tallest free-standing mountain. So if you are on a
                        safari in Tanzania, it is wise to expect many different
                        landscapes.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <div className="col-12 fw-bold h6">EXCITING ADVENTURE</div>
                    <div className="col-12  mt-2">
                      <p>
                        Tanzania has always been popular for its impressive
                        concentration and diversity of wildlife (and birdlife as
                        well). It is normal to find a herd of zebras along with
                        wildebeests in one corner and then a pride of lions or
                        hyenas on another. You have to remember that a good
                        guide is essential for a successful safari. Even though
                        Tanzania is teeming with wildlife, the animals can still
                        be hard to spot especially if you don’t know where and
                        how to look. Thanks to the drivers/guides/rangers, you
                        will be able to go to “where the action happens.”
                      </p>
                      <p className="mt-2">
                        One iconic wildlife event in Tanzania is the Great
                        Wildebeest Migration, where millions of wildebeests,
                        zebras, gazelles, and kudus move in a clockwise manner
                        around the East African plains in anticipation of the
                        rains and better grazing. This event happens all
                        year-round so whatever month you visit the Serengeti,
                        you will surely be able to experience this said
                        migration as long as you have an experienced guide to
                        navigate your tour.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <div className="col-12 fw-bold h6">CHANGING WEATHER</div>
                    <div className="col-12  mt-2">
                      <p>
                        Tanzania is just near the equator so it only has two
                        seasons: wet and dry. However, weather can be pretty
                        much unpredictable, too. It is mostly sunny, yes, but
                        some days, it’s rainy. Sometimes rains can go as short
                        as 5 minutes or a few hours, other times it can rain all
                        day. Some days it’s cold, other times it’s hot and humid
                        and some other days it’s a blend of these temperatures.
                        So prepare comfortable clothes, sunblock, a hat/cap and
                        consider bringing a rainy gear.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <div className="col-12 fw-bold h6">NETWORK COVERAGE</div>
                    <div className="col-12  mt-2">
                      <p>
                        You cannot expect a marvelous phone coverage on a
                        safari. Most areas, especially remote camps, offer zero
                        mobile phone coverage. When it comes to internet
                        connectivity, almost all camps and lodges (even the
                        remote ones) have WiFi so you can still go online, check
                        e-mails and post photos on social media platforms.
                        However, internet connection can sometimes be
                        intermittent or patchy so don’t keep your hopes up if
                        you want to stream a movie, play an online game or do
                        something that requires loads of internet bandwidth.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <div className="col-12 fw-bold h6">SOUVENIRS</div>
                    <div className="col-12  mt-2">
                      <p>
                        Tanzania offers a myriad of opportunities for souvenirs,
                        including rare gemstones like Tanzanite. Tanzanite, the
                        native gemstone of Tanzania, is sold in most gemstone
                        shops alongside other locally mined stones like Ruby,
                        Rhodolite and Tourmaline. Tanzanite was named after
                        Tanzania, and was first discovered in 1967, in Arusha
                        which, to date, remains the only commercially viable
                        source in the world. Local curios like the bold Makonde
                        carvings, Tinga-Tinga works, batiks and the famous
                        miniature Zanzibar chests are available at very
                        reasonable prices.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <div className="col-12 fw-bold h6">
                      REAL WILD DESTINATION
                    </div>
                    <div className="col-12  mt-2">
                      <p>
                        Being away from the city and in the heart of the wild,
                        you will be surrounded by the amazing sounds of nature
                        and at night, you will be able to experience “being
                        close to the stars” as those twinkling lights appear to
                        be just within your reach. Being here might have you
                        exposed to tsetse flies and mosquitoes but that comes
                        with the authentic experience.
                      </p>
                      <p className="mt-2">
                        When you’re out on the road during your safari and
                        nature suddenly calls, you can’t expect a luxurious
                        restroom in the middle of the wild, can you? Since there
                        are no toilets around, you’ll have to “do it in the
                        bush.” When you’re in small charter flights, there will
                        be no onboard toilet either. So, bring lots of toilet
                        paper and a hand sanitizer!
                      </p>
                      <p className="mt-2">
                        Another thing that’ll make you experience the wild is
                        waking up to animal sounds. Most camps are strategically
                        located near waterholes where animals congregate or
                        around areas where herds gather around. Since animals
                        are most active in the early part of the day, it is
                        totally normal to experience waking up to some zebras by
                        your terrace or hippos snorting by your window or even
                        birds chirping
                      </p>
                      <p className="mt-2">
                        During your game drives, you will be aboard a customized
                        safari vehicle. Depending on your tour operator, you
                        will be in closed safari vehicles which feature large
                        windows and a pop-up roof; or in an open-sided safari
                        vehicle which features a non-walled vehicle.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <div className="col-12 fw-bold h6">
                      AMAZING ACCOMMODATIONS
                    </div>
                    <div className="col-12  mt-2">
                      <p>
                        Accommodations in Tanzania range from budget camping
                        tents that are famous for backpackers and travelers who
                        want to rough it out to standard lodges or tented camps
                        that feature basic facilities, to luxurious hotel-style
                        lodges and extravagant tented camps that are just
                        exquisite in every corner.
                      </p>
                      <p className="mt-3">
                        Depending on the type of accommodation you choose, you
                        will be staying in an exclusive area in the wilderness
                        where you will be surrounded by stunning vistas and
                        freely roaming animals or you will be staying in
                        camps/lodges that are strategically located along areas
                        very accessible to wildlife encounters or attractions.
                      </p>
                    </div>
                  </div>

                  <div className="col-12 mt-3">
                    <div className="col-12 fw-bold h6">
                      UNIQUE & EXCITING ACTIVITIES
                    </div>
                    <div className="col-12  mt-2">
                      <p>
                        Going in a safari doesn’t only mean you only do the game
                        driving and game viewing. There are other activities you
                        can certainly enjoy as well, whether you travel solo, as
                        a couple, or as a family/group! Unique add-ons include
                        hot air balloon rides where you can float around iconic
                        parks and spot incredible wildlife peppered across the
                        plains; bonfires in the wild where you can chill and
                        exchange stories with other fellow travelers; mountain
                        trekking; and even an escape to the beach to cap off
                        your African vacation!
                      </p>
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <div className="col-12 fst-italic text-center h6">
                      REMINDER!!
                    </div>
                    <div className="col-12  mt-2">
                      <p>
                        When we talk about safaris in Tanzania, we are usually
                        referring to the safari parks in the northern part of
                        the country and the ones in the southern region. The
                        northern safari park is the more popular area which
                        covers world-famous parks like Serengeti National Park,
                        Ngorongoro Crater, Lake Manyara, Tarangire, and Mount
                        Kilimanjaro. Southern safari circuit, on the other hand,
                        is the more remote area of Tanzania which usually offers
                        a more raw and untouched landscape. Parks in this side
                        of Tanzania include the renowned Selous Game Reserve as
                        well as other huge parks like Ruaha National Park and
                        Mikumi National Park.
                      </p>
                    </div>
                  </div>

                  <div className="col-12 mt-3">
                    <div className="col-12 fw-bold text-center h6">
                      MODE OF SAFARI
                    </div>
                    <div className="col-12  mt-2">
                      <p>
                        You wake up early in the morning and hear an interesting
                        choir of animal sounds. Breakfast will be served at your
                        chalet or at the common dining area of your camp/lodge.
                        Sometimes, you will also have breakfast on the road (all
                        food preparations will be handled by your tour
                        operator). Then, you will start your day by driving to a
                        wildlife sanctuary or park, enjoying a gorgeous sunrise
                        slowly touching the savannah with the sun’s golden rays.
                      </p>
                      <p className="mt-2">
                        Your guide/driver will take you to the most amazing
                        spots to see beautiful vistas and spectacular animal
                        sightings. If you’re lucky, you can even catch
                        predator-prey or predator-predator interactions. Come
                        lunchtime, you will have a picnic in the bush. Food will
                        again be prepared by a chef and you will be able to
                        enjoy a hearty meal right in the heart of the wild.
                        After lunch, you will continue with your game drive to
                        see more animals and beautiful sights and then finally
                        arrive at your lodge/camp usually just before dinner.
                        Dinner will then be served at the lodge and then you can
                        either sojourn at your own room/chalet or you can join
                        other travelers for a bonfire, a small gathering or a
                        few drinks at the bar.
                      </p>
                      <p className="mt-2">
                        On other times, safaris can also end after lunch time
                        and you can go back to your lodge early in the afternoon
                        for your own leisure time. You can go biking, hiking, do
                        some add-on activities or even just go to sleep!
                      </p>
                    </div>
                  </div>
                </>
              )}
          </div>
          <div
            className={`${
              isMobile || isTablet ? "col-12 p-2" : "col-8"
            } d-flex mb-3  flex-column p-3  flex-column crdShadow2`}
          >
            <>
              <div className="col-12 d-flex">
                <div className="col-9 d-flex">
                  {" "}
                  <h4 className="col-12 text-center">
                    What To Wear On Your Tanzania Safari?
                  </h4>
                </div>
                <div className="col-3">
                  <button
                    className="pt-1 pe-2 ps-2 pb-1 btnFont  btn-1"
                    onClick={(e) =>
                      expndQNDescHandler(
                        e,
                        "WHAT TO WEAR ON YOUR TANZANIA SAFARI?"
                      )
                    }
                  >
                    {activQN == "WHAT TO WEAR ON YOUR TANZANIA SAFARI?" &&
                    showMoreQNDesc ? (
                      <>
                        Close <i className="fa fa-angle-up"></i>
                      </>
                    ) : (
                      <>
                        Answer <i className="fa fa-angle-down"></i>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </>
            {activQN == "WHAT TO WEAR ON YOUR TANZANIA SAFARI?" &&
              showMoreQNDesc && (
                <>
                  <div className="col-12">
                    <p>
                      Planning what to wear and essentials to bring on your
                      upcoming safari in Tanzania is not all that complicated.
                      Everything you need is most likely in your closet already!
                      Our clothing suggestions apply for all seasons on a
                      Tanzania safari while different guidelines apply if your
                      route is to the peak of Mt. Kilimanjaro or heading down to
                      the famous Zanzibar beach.
                    </p>
                  </div>
                </>
              )}
          </div>

          <div
            className={`${
              isMobile || isTablet ? "col-12 p-2" : "col-8"
            } d-flex mb-3 p-3  flex-column crdShadow2`}
            value="WHAT KINDS OF CLOTHES ARE RECOMMENDED FOR SAFARI?"
          >
            <>
              <div className="col-12 d-flex">
                <div className="col-9 d-flex">
                  {" "}
                  <h4 className="col-12 text-center">
                    What Kinds Of Clothes are recommended For Safari?
                  </h4>
                </div>
                <div className="col-3">
                  <button
                    className="pt-1 pe-2 ps-2 pb-1 btnFont  btn-1"
                    onClick={(e) =>
                      expndQNDescHandler(
                        e,
                        "WHAT KINDS OF CLOTHES ARE RECOMMENDED FOR SAFARI?"
                      )
                    }
                  >
                    {activQN ==
                      "WHAT KINDS OF CLOTHES ARE RECOMMENDED FOR SAFARI?" &&
                    showMoreQNDesc ? (
                      <>
                        Close <i className="fa fa-angle-up"></i>
                      </>
                    ) : (
                      <>
                        Answer <i className="fa fa-angle-down"></i>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </>
            {activQN == "WHAT KINDS OF CLOTHES ARE RECOMMENDED FOR SAFARI?" &&
              showMoreQNDesc && (
                <>
                  <div className="col-12">
                    <p>
                      Casual, comfortable and easy to wash clothes are
                      recommended. The goal is to pack lightly as most lodges
                      and camps will launder clothes for a small fee. Safaris
                      are informal, and there is no need to dress up. Do plan to
                      dress in layers so you can adjust to temperature changes
                      as early morning game drives can be quite cold depending
                      on the time of year while daytime temperatures can get
                      quite warm. Stick to neutral or khaki colors and avoid
                      wearing dark blue or black clothes as these colors tend to
                      attract tsetse flies. Wide brimmed hats, sunglasses,
                      sunscreen, insect repellent are a necessity. After sunset,
                      we recommend that you wear long pants, long-sleeved
                      shirts, and socks and spray insect repellent to avoid
                      being bitten by mosquitoes.
                    </p>
                  </div>
                  <div className="col-12 mt-2">
                    <div className="col-12 text-center h4 fw-bold mb-2">
                      RECOMMENDED SAFARI CLOTHING
                    </div>
                    <div className="col-12">
                      <ul>
                        <li>-shirts and tank tops</li>
                        <li>
                          Long-sleeved shirts (for protection from sunburns)
                        </li>
                        <li>
                          Light long pants or convertible long-short pants
                        </li>
                        <li>Warm fleece pullover or jacket</li>
                        <li>One swimsuit</li>
                        <li>Cotton socks and underwear</li>
                        <li>Pajamas</li>
                        <li>One pair of comfortable walking shoes</li>
                        <li>One pair of sandals or flip-flops</li>
                        <li>Wide-brimmed hat</li>
                        <li>andana (for dust)</li>
                        <li>
                          • Heavy sweater, gloves, and toque if traveling in the
                          cold season (June – August)
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="mt-3 col-12">
                    {" "}
                    <h4 className="col-12 text-center">
                      CLOTHING FOR ZANZIBAR
                    </h4>
                  </div>
                  <div className="col-12">
                    <p>
                      Please dress modestly when sightseeing in Stone Town or
                      visiting anywhere outside of the beach resorts in
                      Zanzibar. Women should not wear sleeveless or revealing
                      tops and short skirts or thigh-high shorts (Capri trousers
                      or at least knee-length shorts are fine). Men should wear
                      shirts, pants or knee-length shorts.
                    </p>
                  </div>
                  <div className="mt-3 col-12">
                    {" "}
                    <h4 className="col-12 text-center">OTHER ITEMS TO PACK</h4>
                  </div>
                  <div className="col-12">
                    <p>
                      We strongly recommend that you carry-on all important
                      items such as prescription medication, camera equipment,
                      essential toiletries (please keep in mind many airlines
                      have liquid restrictions) and one or two changes of
                      clothes in case of checked luggage delays or loss.
                    </p>
                  </div>
                  <div className="col-12 mt-2">
                    <div className="col-12 text-center h4 fw-bold mb-2">
                      TOP THINGS TO BRING WITH YOU ON A SAFARI
                    </div>
                    <div className="col-12">
                      <ul>
                        <li>Passport</li>
                        <li>Plane tickets</li>
                        <li>
                          Travel Insurance Policy & Emergency Contact Numbers
                        </li>
                        <li>
                          Yellow Fever Vaccination Certificate (if applicable)
                        </li>
                        <li>Safari itinerary</li>
                        <li>
                          U.S. dollars in large and small denominations ($20,
                          $50 & $100 bills should be issued after 2005)
                        </li>
                        <li>Credit cards and ATM bank cards</li>
                        <li>Sunglasses</li>
                        <li>Sunscreen and lip balm</li>
                        <li>Small flashlight or headlamp</li>
                        <li>Insect/Mosquito repellent</li>
                        <li>
                          Eye drops and extra contact lenses or spare glasses
                          (if applicable)
                        </li>
                        <li>Anti-bacterial hand sanitizer</li>
                        <li>
                          Tissue paper and small travel towel (washrooms in
                          Tanzania often don’t have toilet paper or paper
                          towels)
                        </li>
                        <li>
                          Camera, extra memory cards, batteries, and charger, if
                          applicable
                        </li>
                        <li>
                          UK plug adapter and transformer/voltage converter, if
                          applicable
                        </li>
                        <li>
                          Ziplock bags for toiletries, camera equipment, snacks,
                          etc.
                        </li>
                        <li>Personal toiletries</li>
                        <li>
                          Small first aid medical kit including Tylenol or
                          ibuprofen, antimalarial pills, cold and allergy
                          medicine, itch medication, band-aids, stomach ache
                          remedy, antibiotic cream, diarrhea medicine,
                          prescription antibiotic for travelers diarrhea
                          (Ciprofloxacin) and any prescription medications.{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

const WhenToVisit = () => {
  return (
    <div className="col-12 d-flex flex-column justify-content-center align-items-center">
      <div className="col-9">
        <div className="col-12 h4 text-center">WHEN TO VISIT TANZANIA</div>
        <div className="col-12 mt-2">
          <p>
            Tanzania is situated fairly close to the equator and the seasons can
            be divided loosely into rainy and dry seasons. The best wildlife
            viewing months in Tanzania are during the Dry season from late June
            to October. The best chance of seeing the wildebeest migration in
            the Serengeti is during June and July and the time to see the
            wildebeest calving is late January to February. Tanzania’s main
            rainy season, or the ‘long rains’, last during about March, April,
            and May. Short rainy season in end of October to middle of December.
          </p>
          <p className="mt-2">
            Most visitors choose to come during the dry seasons, as there is
            very little chance of rain and the wildlife viewing is better.
            Whilst you may experience rain, the benefits of coming during rainy
            season are that the countryside is green, lush and spectacularly
            beautiful. Crops are growing and the animals have good sources of
            food and water. You will see plenty of wildlife during the rainy
            seasons, but less than in dry seasons.
          </p>
        </div>
      </div>
      <div className="col-9 mt-4 d-flex flex-column">
        <div className="text-center col-12 d-flex">
          <span className="h5 fw-bold">DECEMBER</span>
          <span> - WILDEBEEST MIGRATION, CENTRAL SERENGETI</span>
        </div>
        <div>
          <p>
            Witness millions of large animals including wildebeests and zebras
            flock and move from one place to another with their calves during
            the great wildebeest migration in the Central Serengeti during
            December. It will be an unbelievable experience to see before your
            eyes the overwhelming amount of animals, which are the strongest
            ones left after facing tons of predators along their migration
            journey. Shortly after the rain begins in November, the mega herd of
            the great wildebeest migration arrives on the short-grass plains of
            the Central Serengeti in December.
          </p>
        </div>
      </div>
      <div className="col-9 mt-4 d-flex flex-column">
        <div className="text-center col-12 d-flex">
          <span className="h5 fw-bold">NOVEMBER </span>
          <span> - SERENGETI NATIONAL PARK</span>
        </div>
        <div>
          <p>
            Serengeti National Park is a great place to see all-year-round.
            However, if you want to go against the current, and face a lesser
            number of crowds, November is the best time to plan your adventure.
            During this month, fewer people head to the park, but it bears
            equally-agreeable weather for games and exploring. It’s a low season
            during November at the Serengeti National Park, and there’s a small
            chance of precipitation. You will enjoy the park more if you visit
            at this time of year.
          </p>
        </div>
      </div>
      <div className="col-9 mt-4 d-flex flex-column">
        <div className="text-center col-12 d-flex">
          <span className="h5 fw-bold">OCTOBER </span>
          <span>- TARANGIRE NATIONAL PARK</span>
        </div>
        <div>
          <p>
            This month bears a drier climate, which makes exploration,
            activities, and games a lot more comfortable and enjoyable than the
            rainy season. Visitors head to this park and never miss witnessing
            its most dominant feature, the Tarangire River, which is surrounded
            by baobab trees, acacias, and other vegetation. If you're an avid
            birdwatcher, you'll love what you will see at the park such as more
            than 550 different bird species strewn across Tarangire. The park
            looks great during this time.
          </p>
        </div>
        <div className=" mt-2 col-12 d-flex">MT. KILIMANJARO, TANZANIA</div>
        <div>
          <p>
            October is the best month for a Kilimanjaro trip due to agreeable
            weather, which gives you a high chance to reach the summit. Tons of
            hikers include its summit on top of their lists. June to November,
            as well as February, are also suitable months to climb the mountain
            as there are fewer chances for rain. There are endless surprises
            climbing mount Kilimanjaro, it will stimulate your senses as you
            savor a spectacular view of Africa; its skies, lands, among where
            abundant wildlife lives.
          </p>
        </div>
      </div>
      <div className="col-9 mt-4 d-flex flex-column">
        <div className="text-center col-12 d-flex">
          <span className="h5 fw-bold">SEPTEMBER </span>
          <span>– SERENGETI - FINALE OF GREAT MIGRATION </span>
        </div>
        <div>
          <p>
            You shouldn’t miss this significant phenomenon happening on the
            plains of the park. The action is abundant during this time at the
            Serengeti National Park. Mega herds of animals dash, move and fight
            over predators for survival during the finale of the Serengeti
            migration. The Mara River is no exception for the migrating animals.
            Crocodiles lurk the river after the passing packs where they
            stealthily catch their preys. As the last month of the river
            crossing migration, September is when you would want to head to the
            Serengeti National Park if you plan to witness the final moments of
            the Great Migration.
          </p>
        </div>
      </div>
      <div className="col-9 mt-4 d-flex flex-column">
        <div className="text-center col-12 d-flex">
          <span className="h5 fw-bold">AUGUST</span>
          <span>- LAKE MANYARA NATIONAL PARK </span>
        </div>
        <div>
          <p>
            A dry season implies you have a high chance of encountering wildlife
            everywhere you go, as animals roam free around the park and near the
            lakes. The Lake Manyara National Park is alive in August, as herds
            of animals gather near bodies of water. August also gives way to
            unique landscapes because trees like acacia and baobab fully
            flourish for this season. We suggest you plan your African safari
            adventure during this time of year as accommodation rates are
            cheaper in lodges due to a fewer number of tourists than earlier in
            the year.
          </p>
        </div>
        <div className="h6 col-12 mt-2 fw-bold d-flex">
          GREAT MIGRATION RIVER CROSSING - KOGATENDE SERENGETI
        </div>
        <div>
          <p>
            River crossing is at its height in August and lasts until September.
            The mega herds move non-stop as they follow the rain, and head to
            greener pastures. You will witness predators running after the
            crowds as they prey on large animals. The height of action in
            Kogatende is at its peak during August. Thus, if you want a
            remarkable safari tour, this is the best time to head to the Mara
            River.
          </p>
        </div>
      </div>
      <div className="col-9 mt-4 d-flex flex-column">
        <div className="text-center col-12 d-flex">
          <span className="h5 fw-bold">JULY </span>
          <span> - GREAT MIGRATION, SERENGETI NATIONAL PARKK</span>
        </div>
        <div>
          <p>
            Wildebeest migration peaks as July arrives in Serengeti National
            Park. Thick herds of this species, as well as other wildlife such as
            zebras, will be seen during the great migration. This month is the
            best time for drives, as there would be little to no precipitation,
            and wildlife can be easily spotted near bodies of water. At the
            Seronera River Valley, you can usually find groups of animals like
            elephants and gazelles, as well as predators like lions, hyenas,
            cheetahs, and leopards
          </p>
        </div>
      </div>
      <div className="col-9 mt-4 d-flex flex-column">
        <div className="text-center col-12 d-flex">
          <span className="h5 fw-bold">JUNE</span>
          <span> - NGORONGORO CRATER</span>
        </div>
        <div>
          <p>
            Visiting the Ngorongoro crater in the Ngorongoro Conservation Area
            during this time of year is not only delightful to the eyes, but it
            also fills your heart and mind with remarkable adventures. During
            the dry season in June has the coldest climate in the area of the
            Ngorongoro Crater, the place is filled with beautiful trees like
            acacias as well as vegetation where rare animals feed. Besides the
            abundant wildlife that dwells in the area, there are as well many
            attractions in the vicinity which also means it's a perfect time for
            a trip.
          </p>
        </div>
      </div>
      <div className="col-9 mt-4 d-flex flex-column">
        <div className="text-center col-12 d-flex fw-bold h5">
          MARCH, APRIL, MAY – LOW SEASON ‘long rains’
        </div>
      </div>
      <div className="col-9 mt-4 d-flex flex-column">
        <div className="text-center col-12 d-flex">
          <span className="h5 fw-bold">FEBRUARY </span>
          <span>- WILDEBEEST CALVING SEASON, NDUTU SERENGETI</span>
        </div>
        <div>
          <p>
            Visiting Serengeti National Park wouldn't be complete without
            witnessing the wonders in Ndutu area with your own eyes. Calving
            season peaks in February and the best place to catch this striking
            phenomenon is at Ndutu Area, where migrating animals like zebras and
            wildebeest give birth to their offspring. It's typical to see
            animals giving birth, as well as predators catching their prey.
            Also, there are tons of mammals to look out for such as giraffes,
            elephants, hyenas, antelopes and many more.
          </p>
        </div>
      </div>
      <div className="col-9 mt-4 d-flex flex-column">
        <div className="text-center col-12 d-flex">
          <span className="h5 fw-bold">JANUARY</span>
          <span>- ZANZIBAR</span>
        </div>
        <div>
          <p>
            January is the best time to visit this part of Africa to make the
            most of that summer heat. Take a dip in Zanzibar’s sparkling tides,
            and witness the precious marine life under its salty seas, red
            colobus monkey and various birdlife at Jozani Forest. Clear ocean
            waters, and historical destinations are the main highlights in
            Zanzibar.
          </p>
        </div>
      </div>
      <div className="col-9 mt-4 d-flex flex-column">
        <div className="col-12">
          <table className="col-12 table table-sm table-bordered">
            <thead>
              <tr>
                <th
                  className="pt-3 pb-3"
                  style={{ backgroundColor: "#B3F2FF" }}
                ></th>
                <th
                  className="pt-3 pb-3"
                  style={{ backgroundColor: "#C0F5FF" }}
                ></th>
                <th
                  className="pt-3 pb-3"
                  style={{ backgroundColor: "#80B443" }}
                ></th>
                <th
                  className="pt-3 pb-3"
                  style={{ backgroundColor: "#9DCA68" }}
                ></th>
                <th
                  className="pt-3 pb-3"
                  style={{ backgroundColor: "#B2DE7C" }}
                ></th>
                <th
                  className="pt-3 pb-3"
                  style={{ backgroundColor: "#F4CC28" }}
                ></th>
                <th
                  className="pt-3 pb-3"
                  style={{ backgroundColor: "#FBDA51" }}
                ></th>
                <th
                  className="pt-3 pb-3"
                  style={{ backgroundColor: "#FCDB57" }}
                ></th>
                <th
                  className="pt-3 pb-3"
                  style={{ backgroundColor: "#FBE37F" }}
                ></th>
                <th
                  className="pt-3 pb-3"
                  style={{ backgroundColor: "#FCEAA0" }}
                ></th>
                <th
                  className="pt-3 pb-3"
                  style={{ backgroundColor: "#BBBBBB" }}
                ></th>
                <th
                  className="pt-3 pb-3"
                  style={{ backgroundColor: "#DADADA" }}
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">Jan</td>
                <td className="text-center">Feb</td>
                <td className="text-center">Mar</td>
                <td className="text-center">Apr</td>
                <td className="text-center">Jun</td>
                <td className="text-center">Jul</td>
                <td className="text-center">Aug</td>
                <td className="text-center">Sep</td>
                <td className="text-center">Sep</td>
                <td className="text-center">Oct</td>
                <td className="text-center">Nov</td>
                <td className="text-center">Dec</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="mt-2 d-flex col-12 justify-content-between pb-4">
          <div className="d-flex col-">
            <div
              className=""
              style={{
                backgroundColor: "#F4CC28",
                height: "20px",
                width: "20px",
                borderRadius: "50%",
              }}
            ></div>
            <div className="ms-1">Excellent</div>
          </div>

          <div className="d-flex ms-2 col-">
            <div
              className=""
              style={{
                backgroundColor: "#B3F2FF",
                height: "20px",
                width: "20px",
                borderRadius: "50%",
              }}
            ></div>
            <div className="ms-1">Good</div>
          </div>

          <div className="d-flex ms-2 col-">
            <div
              className=""
              style={{
                backgroundColor: "#BBBBBB",
                height: "20px",
                width: "20px",
                borderRadius: "50%",
              }}
            ></div>
            <div className="ms-1">Fair</div>
          </div>

          <div className="d-flex ms-2 col-">
            <div
              className=""
              style={{
                backgroundColor: "#80B443",
                height: "20px",
                width: "20px",
                borderRadius: "50%",
              }}
            ></div>
            <div className="ms-1">Poor</div>
          </div>

          <div className="d-flex ms-2 col-">
            <div
              className=""
              style={{
                backgroundColor: "#D80C09",
                height: "20px",
                width: "20px",
                borderRadius: "50%",
              }}
            ></div>
            <div className="ms-1">Bad</div>
          </div>
        </div>
      </div>
    </div>
  );
};

function TravelInfoScreen({ data }) {
  const isBiggerScreen = useMediaQuery({ minWidth: 1824 });
  const isBigDesktop = useMediaQuery({ minWidth: 1224, maxWidth: 1823 });
  const isDesktop = useMediaQuery({ minWidth: 992, maxWidth: 1223 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = data?.images ? data?.images : [];

  const [activeId, setActiveId] = useState("faq");

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [images.length]);

  const variants = {
    open: { opacity: 1, y: "10%" },
    closed: { opacity: 0, y: "-90%" },
  };

  const currentImage = images[currentImageIndex]?.image;
  const nextImageIndex = (currentImageIndex + 1) % images.length;
  const nextImage = images[nextImageIndex]?.image;

  return (
    <div
      className="travelScreen"
      style={{
        minHeight: "100vh",
      }}
    >
      <BackgroundHome images={travImages} />
      <div
        // style={{
        //   position: "absolute",
        //   top: 350,
        //   left: 0,
        // }}
        className="col-12 mt-3"
      >
        <div className="col-12">
          <div className="col-12   d-flex justify-content-center align-items-center">
            <div
              className={`${
                isMobile || isTablet ? "col-12 m-1" : "col-8"
              }  crdShadow2  p-4 d-flex justify-content-center `}
            >
              <div className="col-12 d-flex justify-content-center align-items-center">
                <div
                  className="me-3 ms-3 pb-2"
                  onClick={() => setActiveId("faq")}
                  style={{ cursor: "pointer" }}
                >
                  <span
                    className={
                      activeId == "faq" ? "activeCls p-2 " : "ps-2 pe-2 pb-2"
                    }
                  >
                    FAQ
                  </span>
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  className="me-3 ms-3 pb-2"
                  onClick={() => setActiveId("whenToVisit")}
                >
                  <span
                    className={
                      activeId == "whenToVisit" ? "activeCls p-2" : "p-2"
                    }
                  >
                    WHEN TO VISIT TANZANIA
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-3">
          {activeId == "faq" ? (
            <FAQ isMobile={isMobile} isTablet={isTablet} />
          ) : (
            <WhenToVisit isMobile={isMobile} isTablet={isTablet} />
          )}
        </div>
      </div>
    </div>
  );
}

export default TravelInfoScreen;
