import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { getBookedSafarByIdAct } from "../Actions/AdminPnAct";
import ListGroup from "react-bootstrap/ListGroup";

function AdminInquireById({ isMobile, isTablet, onHeightChange }) {
  const { id, inType } = useParams();
  const screenRef = useRef(null);

  const BookedSFById = useSelector((state) => state.BookedSFById);
  const {
    loading: getByIdLoading,
    success: getByIdSuccess,
    error: getByIdError,
    bookedSafariById,
  } = BookedSFById;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInformation = useSelector((state) => state.userInformation);
  const { userInfo, isLoggedIn } = userInformation;
  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    } else {
      dispatch(
        getBookedSafarByIdAct({
          typ: inType,
          tId: id,
        })
      );
    }
  }, []);

  useLayoutEffect(() => {
    const height = screenRef.current.offsetHeight;
    onHeightChange(height);
  }, [onHeightChange]);

  return (
    <div
      className="pb-5"
      style={{ width: "90vw", overFlowX: "auto" }}
      ref={screenRef}
    >
      <div className="col-11 adminScreen pt-4">
        <div className={`${isMobile ? "ps-2" : "p-3"} col-12 d-flex`}>
          <div className="col-7 d-flex pb-3 borderBottom">
            <Link to="/messages-admin">
              <i className="fa fa-circle-arrow-left fs-4 me-2 mt-2"></i>
            </Link>
            <span className="headingh1">
              {inType == "safari" ? "Safari Detail" : "Inquire Detail"}
            </span>
            <span className="ms-2">
              <i className="fa fa-star mt-2 headingH1Fa"></i>
            </span>
          </div>
        </div>
        <div className="col-12 mt-2">
          <div
            className={`${
              isMobile ? "ps-2 col-12" : isTablet ? "ps-2 col-11" : "col-8 "
            }  d-flex paddingStart flex-column borderBottom`}
          >
            <div className="headingh3 fw-bold">General </div>
            <div className="">
              <p>{inType} Infos such as name,email,phone etc</p>
            </div>
          </div>
        </div>
        <div className={`${isMobile ? "ps-2" : "p-3"} col-12`}>
          <div className="col-12">
            <ListGroup variant="flush" className="col-12">
              {inType == "safari" && (
                <ListGroup.Item>
                  <div className="col-12 d-flex">
                    <div className="col-6 ">Booked Safari</div>
                    <div className="col-6 fw-bold">
                      {bookedSafariById?.safariName}
                    </div>
                  </div>
                </ListGroup.Item>
              )}
              {inType == "safari" && (
                <ListGroup.Item>
                  <div className="col-12 d-flex">
                    <div className="col-6">Booked Date</div>
                    <div className="col-6">{bookedSafariById?.createdAt}</div>
                  </div>
                </ListGroup.Item>
              )}
              <ListGroup.Item>
                <div className="col-12 d-flex">
                  <div className="col-6">First Name</div>
                  <div className="col-6">{bookedSafariById?.firstName}</div>
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="mt-2">
                <div className="col-12 d-flex">
                  <div className="col-6">Last Name</div>
                  <div className="col-6">{bookedSafariById?.lastName}</div>
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="mt-2">
                <div className="col-12 d-flex">
                  <div className="col-6">Email</div>
                  <div className="col-6">{bookedSafariById?.email}</div>
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="mt-2">
                <div className="col-12 d-flex">
                  <div className="col-6">Country</div>
                  <div className="col-6">{bookedSafariById?.country}</div>
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="mt-2">
                <div className="col-12 d-flex">
                  <div className="col-6">Travel Date From</div>
                  <div className="col-6">
                    {bookedSafariById?.travelDateFrom}
                  </div>
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="mt-2">
                <div className="col-12 d-flex">
                  <div className="col-6">Travel Date To</div>
                  <div className="col-6">{bookedSafariById?.travelDateTo}</div>
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="mt-2">
                <div className="col-12 d-flex">
                  <div className="col-6">Number Of People</div>
                  <div className="col-6">{bookedSafariById?.noOfPeople}</div>
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="mt-2">
                <div className="col-12 d-flex">
                  <div className="col-6">Number Of Children</div>
                  <div className="col-6">{bookedSafariById?.noOfChildren}</div>
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="mt-2">
                <div className="col-12 d-flex">
                  <div className="col-6">Accommodations</div>
                  <div className="col-6">
                    {bookedSafariById?.accommodationType}
                  </div>
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="mt-2">
                <div className="col-12 d-flex">
                  <div className="col-6">Room Type</div>
                  <div className="col-6">{bookedSafariById?.roomType}</div>
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="mt-2">
                <div className="col-12 d-flex flex-column flex-wrap">
                  <div className="col-12">Descriptions</div>
                  <div className="col-12 d-flex flex-wrap pe-1">
                    <p>{bookedSafariById?.description}</p>
                  </div>
                </div>
              </ListGroup.Item>
            </ListGroup>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminInquireById;
