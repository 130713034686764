import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
} from "../Constants/userConstants";
import axios from "axios";
import { useSelector } from "react-redux";

export const userLoginAct = (name, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const { config } = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      "https://360xtremeadventures.com/api/usersAdventure/login/",
      // "http://127.0.0.1:8000/api/usersAdventure/login/",
      { userName: name, password: password },
      config
    );

    // const newSafar = [...bookedSafaris?.safaris];
    // const newInqs = [...(inqs && inqs)]?.reverse();
    // const newNws = [...(news && news)]?.reverse();

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });
    sessionStorage.setItem("userInfo", JSON.stringify(data));
    // localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const userLogoutAct = () => (dispatch) => {
  sessionStorage.removeItem("userInfo");
  dispatch({
    type: USER_LOGOUT,
  });
};
