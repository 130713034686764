import React, { useState, useEffect, useRef } from "react";
import "./acco.css";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useMediaQuery } from "react-responsive";
import FooterImages from "../../Components/FooterImages";

gsap.registerPlugin(ScrollTrigger);

const DialogImage = styled.img`
  width: 100%;
  height: ${(props) =>
    props.isMobile ? "35vw" : props.isTablet ? "20vw" : "15vw"};
  object-fit: cover;
  border-radius: 10px 10px 0 0;
`;

const accoCats = [
  "All",
  "Arusha",
  "Karatu",
  "Ngorongoro",
  "Lake Eyasi",
  "Lake Narton",
  "Serengeti",
  "Dar es salam",
  "Zanzibar",
  "Pemba Island",
  "Mafia Island",
];

const accomodataionImgs = [
  {
    id: 1,
    image: "1.jpg",
  },
  {
    id: 2,
    image: "2.jpg",
  },
  {
    id: 3,
    image: "3.jpg",
  },
  {
    id: 4,
    image: "4.jpg",
  },
  {
    id: 5,
    image: "5.jpg",
  },
  {
    id: 6,
    image: "6.jpg",
  },
  {
    id: 7,
    image: "7.jpg",
  },
  {
    id: 7,
    image: "7.jpg",
  },
  {
    id: 8,
    image: "8.jpg",
  },
  {
    id: 10,
    image: "10.jpg",
  },
  {
    id: 11,
    image: "11.jpg",
  },
  {
    id: 13,
    image: "13.jpg",
  },
  {
    id: 14,
    image: "14.jpg",
  },
  {
    id: 15,
    image: "15.jpg",
  },
  {
    id: 16,
    image: "16.jpg",
  },
  {
    id: 17,
    image: "17.jpg",
  },
  {
    id: 18,
    image: "18.jpg",
  },
  {
    id: 19,
    image: "19.jpg",
  },
];

const BackgroundHome = ({ images, isMobile }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const imageElements = container.querySelectorAll(".imgt");

    const action = gsap.timeline({ repeat: -1, repeatDelay: 3 });

    imageElements.forEach((imageElement, index) => {
      action.to(imageElement, {
        autoAlpha: 1,
        duration: 1.5,
        delay: index === 0 ? 0 : 3,
      });
    });

    action.play();

    return () => {
      action.kill();
    };
  }, [images]);

  return (
    <div
      ref={containerRef}
      id="slideshow-container"
      className="img-container"
      style={{ height: "60vh", width: "100%", position: "relative" }}
    >
      {images?.map((imageSrc, index) => (
        <div
          key={index}
          className="imgt d-flex justify-content-center align-items-center col-12"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${require(`../../Images/accomodataions/${imageSrc.image}`)})`,
            backgroundSize: "fit",
            backgroundPosition: "center center",
            objectFit: "cover",
            backgroundRepeat: "no-repeat",
            opacity: index === 0 ? 1 : 0,
          }}
        >
          <div
            className={`${
              isMobile ? "fs-4" : "fs-2"
            } text-light fw-bold  text-wrap`}
          >
            Personalized Experiences, Memorable Moments
          </div>
        </div>
      ))}
    </div>
  );
};

function Accomodations({ data, accos }) {
  const isBiggerScreen = useMediaQuery({ minWidth: 1824 });
  const isBigDesktop = useMediaQuery({ minWidth: 1224, maxWidth: 1823 });
  const isDesktop = useMediaQuery({ minWidth: 992, maxWidth: 1223 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isNotMobile = useMediaQuery({ minWidth: 768 });

  const [showAnimations, setShowAnimations] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = data?.images ? data?.images : [];

  const [activeTab, setActiveTab] = useState("All");

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);

  useEffect(() => {
    gsap.from(section1Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section1Ref.current,
        start: "top 80%", // Adjust the start position as needed
        end: "bottom 20%", // Adjust the end position as needed
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section2Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section2Ref.current,
        start: "top 100%",
        end: "bottom 100%",
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section3Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section3Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });
  }, [activeTab]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [images.length]);

  const currentImage = images[currentImageIndex]?.image;
  const nextImageIndex = (currentImageIndex + 1) % images.length;
  const nextImage = images[nextImageIndex]?.image;

  useEffect(() => {
    if (showAnimations) {
      const animations = gsap.utils.toArray(".crdShadow").map((element) => {
        return gsap.from(element, {
          opacity: 0,
          y: 100,
          scrollTrigger: {
            trigger: element,
            start: "top 80%",
            end: "bottom 20%",
            toggleActions: "play none none reverse",
          },
        });
      });

      return () => {
        animations.forEach((animation) => animation.kill());
      };
    }
  }, [showAnimations]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAnimations(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const arushaAccos = accos?.[0]?.arushaAcco;
  const arushaLuxLodges = arushaAccos?.filter(
    (a) => a.category == "Luxury Lodges"
  )?.[0]?.accoList;
  const arushaMidLodges = arushaAccos?.filter(
    (a) => a.category == "Mid-range Lodges"
  )?.[0]?.accoList;
  const arushaBudgLodges = arushaAccos?.filter(
    (a) => a.category == "Budget Lodges"
  )?.[0]?.accoList;

  const karatuAccos = accos?.[0]?.karatuAcco;
  const karatuExtraLuxLodges = karatuAccos?.filter(
    (a) => a.category == "Extra Luxury Lodges"
  )?.[0]?.accoList;

  const karatuLuxLodges = karatuAccos?.filter(
    (a) => a.category == "Luxury Lodges"
  )?.[0]?.accoList;

  const karatuMidLodges = karatuAccos?.filter(
    (a) => a.category == "Mid-range Lodges"
  )?.[0]?.accoList;

  const karatuBudgLodges = karatuAccos?.filter(
    (a) => a.category == "Budget Lodges"
  )?.[0]?.accoList;

  const insideNgororoAccos = accos?.[0]?.insideNgorongoro;
  const insideNgororoExtraLuxLodges = insideNgororoAccos?.filter(
    (a) => a.category == "Extra Luxury Lodges"
  )?.[0]?.accoList;

  const insideNgororoLuxLodges = insideNgororoAccos?.filter(
    (a) => a.category == "Luxury Lodges"
  )?.[0]?.accoList;

  const insideNgororoMidLodges = insideNgororoAccos?.filter(
    (a) => a.category == "Mid-range Lodges"
  )?.[0]?.accoList;

  const ndutuNgorongoroAccos = accos?.[0]?.ndutuNgorongoro;

  const ndutuNgorongoroLuxLodges = ndutuNgorongoroAccos?.filter(
    (a) => a.category == "Luxury Lodges"
  )?.[0]?.accoList;

  const ndutuNgorongoroMidLodges = ndutuNgorongoroAccos?.filter(
    (a) => a.category == "Mid-range Lodges"
  )?.[0]?.accoList;

  const ndutuNgorongoroBudgLodges = ndutuNgorongoroAccos?.filter(
    (a) => a.category == "Budget Lodges"
  )?.[0]?.accoList;

  const lakeEyasiAccos = accos?.[0]?.lakeEyasi;

  const lakeEyasiLuxLodges = lakeEyasiAccos?.filter(
    (a) => a.category == "Luxury Lodges"
  )?.[0]?.accoList;

  const lakeEyasiMidLodges = lakeEyasiAccos?.filter(
    (a) => a.category == "Mid-range Lodges"
  )?.[0]?.accoList;

  const lakeNartonAccos = accos?.[0]?.lakeNarton;

  const lakeNartonMidLodges = lakeNartonAccos?.filter(
    (a) => a.category == "Mid-range Lodges"
  )?.[0]?.accoList;

  const serengetiAccos = accos?.[0]?.serengeti;

  const serengetiExtraLuxLodges = serengetiAccos?.filter(
    (a) => a.category == "Extra Luxury Lodges"
  )?.[0]?.accoList;

  const serengetiLuxLodges = serengetiAccos?.filter(
    (a) => a.category == "Luxury Lodges"
  )?.[0]?.accoList;

  const serengetiMidRangeLodges = serengetiAccos?.filter(
    (a) => a.category == "Mid-range Lodges"
  )?.[0]?.accoList;

  const serengetiBudgetCampsite = serengetiAccos?.filter(
    (a) => a.category == "Budget Campsite"
  )?.[0]?.accoList;

  const zanzibarAccos = accos?.[0]?.zanzibar;

  const zanzibarStoneTwnLux = zanzibarAccos?.filter(
    (a) => a.category == "Stone Town - Luxury Accommodation"
  )?.[0]?.accoList;

  const zanzibarStoneTwnMidrange = zanzibarAccos?.filter(
    (a) => a.category == "Stone Town – Mid-range - Accommodation"
  )?.[0]?.accoList;

  const zanzibarNungwiLux = zanzibarAccos?.filter(
    (a) => a.category == "Nungwi - Luxury Accommodation"
  )?.[0]?.accoList;

  const zanzibarNungwiMidRange = zanzibarAccos?.filter(
    (a) => a.category == "Nungwi – Mid-range Accommodation"
  )?.[0]?.accoList;

  const zanzibarKiwengwa = zanzibarAccos?.filter(
    (a) => a.category == "Kiwengwa - Accommodation"
  )?.[0]?.accoList;

  const zanzibarJambianiLux = zanzibarAccos?.filter(
    (a) => a.category == "Jambiani – Luxury Accommodation"
  )?.[0]?.accoList;

  const zanzibarJambianiMidRange = zanzibarAccos?.filter(
    (a) => a.category == "Jambiani – Mid-range Accommodation"
  )?.[0]?.accoList;

  const pembaAccos = accos?.[0]?.pembaIsland;

  const pembaIsland = pembaAccos?.filter((a) => a.category == "Lodges")?.[0]
    ?.accoList;

  const dzmAccos = accos?.[0]?.daresalaam;

  const dzmLux = dzmAccos?.filter(
    (a) => a.category == "Luxury Accommodation"
  )?.[0]?.accoList;

  const dzmMidrange = dzmAccos?.filter(
    (a) => a.category == "Mid-range Accommodation"
  )?.[0]?.accoList;

  const mafiaAccos = accos?.[0]?.mafiaIsland;

  const mafiaIsland = mafiaAccos?.filter((a) => a.category == "Lodges")?.[0]
    ?.accoList;

  const activeTabHndler = (e, name) => {
    e.preventDefault();
    setActiveTab(name);
  };

  return (
    <div
      className=" pb-3 accoScreen"
    >
      <BackgroundHome images={accomodataionImgs} isMobile={isMobile} />
      <div
        style={{
          left: 0,
          paddingBottom: 0,

          // backgroundSize: "cover",
          // backgroundRepeat: "repeat",
        }}
        className="col-12 "
      >
        <div className="col-12 d-flex justify-content-center align-items-center flex-column">
          <div
            className={`${
              isMobile ? "col-12 p-2" : isTablet ? "col-11 p-2" : "col-8"
            } mt-4 text-center`}
          >
            <p>
              Tanzania has a diverse mix of accommodation that suit all budget
              and types of travelers from couples, families with small children,
              elders, and people with disability. You will find Hotels and
              Lodges both inside and outside of the National Parks. Tanzania
              Safari accommodations range from budget camping tents that are
              famous for backpackers and travelers who want to rough it out, to
              standard lodges or tented camps that feature basic facilities, to
              luxurious hotel-style lodges and extravagant tented camps.
              Depending on the type of accommodation you choose, you will be
              staying in an exclusive area in the wilderness where you will be
              surrounded by stunning vistas and freely roaming animals or you
              will be staying in camps/lodges that are strategically located
              along areas very accessible to wildlife encounters or attractions.
            </p>
          </div>
          <div
            className={`${
              isMobile ? "col-12" : isTablet ? "col-11" : "col-8"
            } mt-4`}
            ref={section2Ref}
          >
            <div className="col-12 text-center h2 mb-2">
              TAILORMAKE YOUR STAY
            </div>
            <p className={!isMobile ? "text-center ps-3" : "text-center"}>
              Our Safari Experts will be happy to help you plan an itinerary
              with different categories of accommodation to match your
              preferences
            </p>
          </div>
          <div className="col-12 accoCategorories justify-content-center align-items-center d-flex flex-wrap">
            <div className="col-10 justify-content-center align-items-center d-flex">
              {accoCats?.map((c) => {
                return (
                  <div
                    key={c}
                    onClick={(e) => activeTabHndler(e, c)}
                    className={`${
                      activeTab == c ? "activeTabb" : ""
                    } me-2 accoItem `}
                  >
                    {c}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-12 mb-5 pb-4" ref={section2Ref}>
            {(activeTab == "All" || activeTab == "Arusha") && (
              <div className="col-12 Arush justify-content-center align-items-center d-flex flex-wrap">
                <div className="col-10 text-center fw-bold h1 mb-2">
                  ARUSHA TOWN
                </div>
                <div className="col-10 mt-4">
                  <div className="col-12 text-center h3 mb-2">
                    Luxury Lodges
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap justify-content-center">
                    {arushaLuxLodges?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2`}
                          key={s.name2}
                        >
                          <Link
                            to={`/accommodation/${s.name2}`}
                            style={{ all: "unset" }}
                          >
                            <div
                              style={{
                                visibility: showAnimations
                                  ? "visible"
                                  : "hidden",
                              }}
                              className="crdShadowww crdHover card shadow  d-flex flex-column"
                            >
                              <div>
                                <DialogImage
                                  isMobile={isMobile}
                                  isTablet={isTablet}
                                  src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                />
                              </div>
                              <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                <div className="col-12 text-center mt-2 fw-bold ">
                                  {s.name}
                                </div>
                              </div>
                              <div
                                className="col-12 ps-2 mt-2 pe-2 text-center"
                                style={{ height: "150px", overFlowY: "auto" }}
                              >
                                {s.overview?.substring(0, 200)} ..
                              </div>
                              <div className="col-12 pb-3 d-flex justify-content-center">
                                <div className="mt-3">
                                  <button
                                    className={`${
                                      isMobile ? "pt-1 pb-1 ps-2 pe-2 fs-6" : ""
                                    } btn-1`}
                                  >
                                    More Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-10 mt-4">
                  <div className="col-12 text-center h3 mb-2">
                    Mid-range Lodges
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap  justify-content-center">
                    {arushaMidLodges?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2`}
                          key={s.name2}
                        >
                          <Link
                            to={`/accommodation/${s.name2}`}
                            style={{ all: "unset" }}
                          >
                            <div
                              style={{
                                visibility: showAnimations
                                  ? "visible"
                                  : "hidden",
                              }}
                              // style={{ cursor: "pointer" }}
                              // ref={section1Ref}

                              className=" card shadow crdHover d-flex flex-column"
                            >
                              <div>
                                <DialogImage
                                  src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                />
                              </div>
                              <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                <div className="col-12 text-center mt-2 fw-bold ">
                                  {s.name}
                                </div>
                              </div>
                              <div
                                className="col-12 ps-2 mt-2 pe-2"
                                style={{ height: "150px", overFlowY: "auto" }}
                              >
                                {s.overview?.substring(0, 200)} ..
                              </div>
                              <div className="col-12 pb-3 d-flex justify-content-center">
                                <div className="mt-3">
                                  <button className="btn-1">
                                    More Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-10 mt-4">
                  <div className="col-12 text-center h3 mb-2">
                    Budget Lodges
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap  justify-content-center">
                    {arushaBudgLodges?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2`}
                          key={s.name2}
                        >
                          <Link
                            to={`/accommodation/${s.name2}`}
                            style={{ all: "unset" }}
                          >
                            <div
                              style={{
                                visibility: showAnimations
                                  ? "visible"
                                  : "hidden",
                              }}
                              // style={{ cursor: "pointer" }}
                              // ref={section1Ref}

                              className="card shadow crdHover d-flex flex-column"
                            >
                              <div>
                                <DialogImage
                                  src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                />
                              </div>
                              <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                <div className="col-12 text-center mt-2 fw-bold ">
                                  {s.name}
                                </div>
                              </div>
                              <div
                                className="col-12 ps-2 mt-2 pe-2"
                                style={{ height: "150px", overFlowY: "auto" }}
                              >
                                {s.overview?.substring(0, 200)} ..
                              </div>
                              <div className="col-12 pb-3 d-flex justify-content-center">
                                <div className="mt-3">
                                  <button className="btn-1">
                                    More Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
            {(activeTab == "All" || activeTab == "Karatu") && (
              <div className="col-12 karatu justify-content-center align-items-center d-flex flex-wrap">
                <div className="col-10 mt-4">
                  <div className="col-12 text-center mt-4 fw-bold h3 mb-2">
                    KARATU AREA – ‘‘AROUND’’ MANYARA, TARANGIRE & NGORONGORO
                  </div>
                  <div className="col-12 text-center h3 mb-2">
                    Extra Luxury Lodges
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap   justify-content-center">
                    {karatuExtraLuxLodges?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2`}
                          key={s.name2}
                        >
                          <Link
                            to={`/accommodation/${s.name2}`}
                            style={{ all: "unset" }}
                          >
                            <div
                              style={{
                                visibility: showAnimations
                                  ? "visible"
                                  : "hidden",
                              }}
                              // style={{ cursor: "pointer" }}
                              // ref={section1Ref}

                              className="card shadow crdHover d-flex flex-column"
                            >
                              <div>
                                <DialogImage
                                  src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                />
                              </div>
                              <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                <div className="col-12 text-center mt-2 fw-bold ">
                                  {s.name}
                                </div>
                              </div>
                              <div
                                className="col-12 ps-2 mt-2 pe-2"
                                style={{ height: "150px", overFlowY: "auto" }}
                              >
                                {s.overview?.substring(0, 200)} ..
                              </div>
                              <div className="col-12 pb-3 d-flex justify-content-center">
                                <div className="mt-3">
                                  <button className="btn-1">
                                    More Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-10 mt-4">
                  <div className="col-12 text-center h3 mb-2">
                    Luxury Lodges
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap  justify-content-center">
                    {karatuLuxLodges?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2`}
                          key={index}
                        >
                          <Link
                            to={`/accommodation/${s.name2}`}
                            style={{ all: "unset" }}
                          >
                            <div
                              style={{
                                visibility: showAnimations
                                  ? "visible"
                                  : "hidden",
                              }}
                              // style={{ cursor: "pointer" }}
                              // ref={section1Ref}

                              className="card shadow crdHover d-flex flex-column"
                            >
                              <div>
                                <DialogImage
                                  src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                />
                              </div>
                              <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                <div className="col-12 text-center mt-2 fw-bold ">
                                  {s.name}
                                </div>
                              </div>
                              <div
                                className="col-12 ps-2 mt-2 pe-2"
                                style={{ height: "150px", overFlowY: "auto" }}
                              >
                                {s.overview?.substring(0, 200)} ..
                              </div>
                              <div className="col-12 pb-3 d-flex justify-content-center">
                                <div className="mt-3">
                                  <button className="btn-1">
                                    More Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-10 mt-4">
                  <div className="col-12 text-center h3 mb-2">
                    Mid-range Lodges
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap  justify-content-center">
                    {karatuMidLodges?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2`}
                          key={s.name2}
                        >
                          <Link
                            to={`/accommodation/${s.name2}`}
                            style={{ all: "unset" }}
                          >
                            <div
                              style={{
                                visibility: showAnimations
                                  ? "visible"
                                  : "hidden",
                              }}
                              // style={{ cursor: "pointer" }}
                              // ref={section1Ref}

                              className="card shadow crdHover d-flex flex-column"
                            >
                              <div>
                                <DialogImage
                                  src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                />
                              </div>
                              <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                <div className="col-12 text-center mt-2 fw-bold ">
                                  {s.name}
                                </div>
                              </div>
                              <div
                                className="col-12 ps-2 mt-2 pe-2"
                                style={{ height: "150px", overFlowY: "auto" }}
                              >
                                {s.overview?.substring(0, 200)} ..
                              </div>
                              <div className="col-12 pb-3 d-flex justify-content-center">
                                <div className="mt-3">
                                  <button className="btn-1">
                                    More Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-10 mt-4">
                  <div className="col-12 text-center h3 mb-2">
                    Budget Campsite
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap  justify-content-center">
                    {karatuBudgLodges?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2 col-12 d-flex justify-content-center `}
                          key={s.name2}
                        >
                          <div className="col-8">
                            <Link
                              to={`/accommodation/${s.name2}`}
                              style={{ all: "unset" }}
                            >
                              <div
                                style={{
                                  visibility: showAnimations
                                    ? "visible"
                                    : "hidden",
                                }}
                                // style={{ cursor: "pointer" }}
                                // ref={section1Ref}

                                className="card shadow crdHover  d-flex flex-column"
                              >
                                <div>
                                  <DialogImage
                                    src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                  />
                                </div>
                                <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                  <div className="col-12 text-center mt-2 fw-bold ">
                                    {s.name}
                                  </div>
                                </div>
                                <div
                                  className="col-12 ps-5 mt-2 pe-5"
                                  style={{ height: "150px", overFlowY: "auto" }}
                                >
                                  {s.overview?.substring(0, 200)} ..
                                </div>
                                <div className="col-12 pb-3 d-flex justify-content-center">
                                  <div className="mt-3">
                                    <button className="btn-1">
                                      More Details
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}

            {(activeTab == "All" || activeTab == "Ngorongoro") && (
              <div className="col-12 Ngororo justify-content-center align-items-center d-flex flex-wrap">
                <div className="col-10 text-center mt-4 fw-bold h1 mb-2">
                  INSIDE NGORONGORO
                </div>
                <div className="col-10 mt-4">
                  <div className="col-12 text-center h3 mb-2">
                    Extra Luxury Lodges
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap   justify-content-center">
                    {insideNgororoExtraLuxLodges?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2`}
                          key={s.name2}
                        >
                          <Link
                            to={`/accommodation/${s.name2}`}
                            style={{ all: "unset" }}
                          >
                            <div
                              style={{
                                visibility: showAnimations
                                  ? "visible"
                                  : "hidden",
                              }}
                              // style={{ cursor: "pointer" }}
                              // ref={section1Ref}

                              className="card shadow crdHover d-flex flex-column"
                            >
                              <div>
                                <DialogImage
                                  src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                />
                              </div>
                              <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                <div className="col-12 text-center mt-2 fw-bold ">
                                  {s.name}
                                </div>
                              </div>
                              <div
                                className="col-12 ps-2 mt-2 pe-2"
                                style={{ height: "150px", overFlowY: "auto" }}
                              >
                                {s.overview?.substring(0, 200)} ..
                              </div>
                              <div className="col-12 pb-3 d-flex justify-content-center">
                                <div className="mt-3">
                                  <button className="btn-1">
                                    More Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-10 mt-4">
                  <div className="col-12 text-center h3 mb-2">
                    Luxury Lodges
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap  justify-content-center">
                    {insideNgororoLuxLodges?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2`}
                          key={s.name2}
                        >
                          <Link
                            to={`/accommodation/${s.name2}`}
                            style={{ all: "unset" }}
                          >
                            <div
                              style={{
                                visibility: showAnimations
                                  ? "visible"
                                  : "hidden",
                              }}
                              // style={{ cursor: "pointer" }}
                              // ref={section1Ref}

                              className="card shadow crdHover d-flex flex-column"
                            >
                              <div>
                                <DialogImage
                                  src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                />
                              </div>
                              <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                <div className="col-12 text-center mt-2 fw-bold ">
                                  {s.name}
                                </div>
                              </div>
                              <div
                                className="col-12 ps-2 mt-2 pe-2"
                                style={{ height: "150px", overFlowY: "auto" }}
                              >
                                {s.overview?.substring(0, 200)} ..
                              </div>
                              <div className="col-12 pb-3 d-flex justify-content-center">
                                <div className="mt-3">
                                  <button className="btn-1">
                                    More Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-10 mt-4">
                  <div className="col-12 text-center h3 mb-2">
                    Mid-range Lodges
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap  justify-content-center">
                    {insideNgororoMidLodges?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2`}
                          key={s.name2}
                        >
                          <Link
                            to={`/accommodation/${s.name2}`}
                            style={{ all: "unset" }}
                          >
                            <div
                              style={{
                                visibility: showAnimations
                                  ? "visible"
                                  : "hidden",
                              }}
                              // style={{ cursor: "pointer" }}
                              // ref={section1Ref}

                              className="card shadow crdHover d-flex flex-column"
                            >
                              <div>
                                <DialogImage
                                  src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                />
                              </div>
                              <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                <div className="col-12 text-center mt-2 fw-bold ">
                                  {s.name}
                                </div>
                              </div>
                              <div
                                className="col-12 ps-2 mt-2 pe-2"
                                style={{ height: "150px", overFlowY: "auto" }}
                              >
                                {s.overview?.substring(0, 200)} ..
                              </div>
                              <div className="col-12 pb-3 d-flex justify-content-center">
                                <div className="mt-3">
                                  <button className="btn-1">
                                    More Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-10 text-center mt-4 fw-bold h1 mb-2">
                  Ngorongoro (Ndutu Area)
                </div>
                <div className="col-10 mt-4">
                  <div className="col-12 text-center h3 mb-2">
                    Luxury Lodges
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap  justify-content-center">
                    {ndutuNgorongoroLuxLodges?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2`}
                          key={s.name2}
                        >
                          <Link
                            to={`/accommodation/${s.name2}`}
                            style={{ all: "unset" }}
                          >
                            <div
                              style={{
                                visibility: showAnimations
                                  ? "visible"
                                  : "hidden",
                              }}
                              // style={{ cursor: "pointer" }}
                              // ref={section1Ref}

                              className="card shadow crdHover d-flex flex-column"
                            >
                              <div>
                                <DialogImage
                                  isMobile={isMobile}
                                  isTablet={isTablet}
                                  src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                />
                              </div>
                              <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                <div className="col-12 text-center mt-2 fw-bold ">
                                  {s.name}
                                </div>
                              </div>
                              <div
                                className="col-12 ps-2 mt-2 pe-2"
                                style={{ height: "150px", overFlowY: "auto" }}
                              >
                                {s.overview?.substring(0, 200)} ..
                              </div>
                              <div className="col-12 pb-3 d-flex justify-content-center">
                                <div className="mt-3">
                                  <button className="btn-1">
                                    More Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-10 mt-4">
                  <div className="col-12 text-center h3 mb-2">
                    Mid-range Lodges
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap  justify-content-center">
                    {ndutuNgorongoroMidLodges?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2`}
                          key={s.name2}
                        >
                          <Link
                            to={`/accommodation/${s.name2}`}
                            style={{ all: "unset" }}
                          >
                            <div
                              style={{
                                visibility: showAnimations
                                  ? "visible"
                                  : "hidden",
                              }}
                              // style={{ cursor: "pointer" }}
                              // ref={section1Ref}

                              className="card shadow crdHover d-flex flex-column"
                            >
                              <div>
                                <DialogImage
                                  src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                />
                              </div>
                              <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                <div className="col-12 text-center mt-2 fw-bold ">
                                  {s.name}
                                </div>
                              </div>
                              <div
                                className="col-12 ps-2 mt-2 pe-2"
                                style={{ height: "150px", overFlowY: "auto" }}
                              >
                                {s.overview?.substring(0, 200)} ..
                              </div>
                              <div className="col-12 pb-3 d-flex justify-content-center">
                                <div className="mt-3">
                                  <button className="btn-1">
                                    More Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-10 mt-4">
                  <div className="col-12 text-center h3 mb-2">
                    Budget Lodges
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap  justify-content-center">
                    {ndutuNgorongoroBudgLodges?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2`}
                          key={s.name2}
                        >
                          <Link
                            to={`/accommodation/${s.name2}`}
                            style={{ all: "unset" }}
                          >
                            <div
                              style={{
                                visibility: showAnimations
                                  ? "visible"
                                  : "hidden",
                              }}
                              // style={{ cursor: "pointer" }}
                              // ref={section1Ref}

                              className="card shadow crdHover d-flex flex-column"
                            >
                              <div>
                                <DialogImage
                                  src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                />
                              </div>
                              <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                <div className="col-12 text-center mt-2 fw-bold ">
                                  {s.name}
                                </div>
                              </div>
                              <div
                                className="col-12 ps-2 mt-2 pe-2"
                                style={{ height: "150px", overFlowY: "auto" }}
                              >
                                {s.overview?.substring(0, 200)} ..
                              </div>
                              <div className="col-12 pb-3 d-flex justify-content-center">
                                <div className="mt-3">
                                  <button className="btn-1">
                                    More Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}

            {(activeTab == "All" || activeTab == "Lake Eyasi") && (
              <div className="col-12 Ngororo justify-content-center align-items-center d-flex flex-wrap">
                <div className="col-10 text-center mt-4 fw-bold h1 mb-2">
                  LAKE EYASI
                </div>
                <div className="col-10 mt-4">
                  <div className="col-12 text-center h3 mb-2">
                    Luxury Lodges
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap  justify-content-center">
                    {lakeEyasiLuxLodges?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2`}
                          key={s.name2}
                        >
                          <Link
                            to={`/accommodation/${s.name2}`}
                            style={{ all: "unset" }}
                          >
                            <div
                              style={{
                                visibility: showAnimations
                                  ? "visible"
                                  : "hidden",
                              }}
                              // style={{ cursor: "pointer" }}
                              // ref={section1Ref}

                              className="card shadow crdHover d-flex flex-column"
                            >
                              <div>
                                <DialogImage
                                  src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                />
                              </div>
                              <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                <div className="col-12 text-center mt-2 fw-bold ">
                                  {s.name}
                                </div>
                              </div>
                              <div
                                className="col-12 ps-2 mt-2 pe-2"
                                style={{ height: "150px", overFlowY: "auto" }}
                              >
                                {s.overview?.substring(0, 200)} ..
                              </div>
                              <div className="col-12 pb-3 d-flex justify-content-center">
                                <div className="mt-3">
                                  <button className="btn-1">
                                    More Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-10 mt-4">
                  <div className="col-12 text-center h3 mb-2">
                    Mid-range Lodges
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap  justify-content-center">
                    {lakeEyasiMidLodges?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2`}
                          key={s.name2}
                        >
                          <Link
                            to={`/accommodation/${s.name2}`}
                            style={{ all: "unset" }}
                          >
                            <div
                              style={{
                                visibility: showAnimations
                                  ? "visible"
                                  : "hidden",
                              }}
                              // style={{ cursor: "pointer" }}
                              // ref={section1Ref}

                              className="card shadow crdHover d-flex flex-column"
                            >
                              <div>
                                <DialogImage
                                  src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                />
                              </div>
                              <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                <div className="col-12 text-center mt-2 fw-bold ">
                                  {s.name}
                                </div>
                              </div>
                              <div
                                className="col-12 ps-2 mt-2 pe-2"
                                style={{ height: "150px", overFlowY: "auto" }}
                              >
                                {s.overview?.substring(0, 200)} ..
                              </div>
                              <div className="col-12 pb-3 d-flex justify-content-center">
                                <div className="mt-3">
                                  <button className="btn-1">
                                    More Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}

            {(activeTab == "All" || activeTab == "Lake Narton") && (
              <div className="col-12 Narton justify-content-center align-items-center d-flex flex-wrap">
                {" "}
                <div className="col-10 text-center mt-4 fw-bold h1 mb-2">
                  LAKE NATRON
                </div>
                <div className="col-10 mt-4">
                  <div className="col-12 text-center h3 mb-2">
                    Mid-range Lodges
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap  justify-content-center">
                    {lakeNartonMidLodges?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2`}
                          key={s.name2}
                        >
                          <Link
                            to={`/accommodation/${s.name2}`}
                            style={{ all: "unset" }}
                          >
                            <div
                              style={{
                                visibility: showAnimations
                                  ? "visible"
                                  : "hidden",
                              }}
                              // style={{ cursor: "pointer" }}
                              // ref={section1Ref}

                              className="card shadow crdHover d-flex flex-column"
                            >
                              <div>
                                <DialogImage
                                  src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                />
                              </div>
                              <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                <div className="col-12 text-center mt-2 fw-bold ">
                                  {s.name}
                                </div>
                              </div>
                              <div
                                className="col-12 ps-2 mt-2 pe-2"
                                style={{ height: "150px", overFlowY: "auto" }}
                              >
                                {s.overview?.substring(0, 200)} ..
                              </div>
                              <div className="col-12 pb-3 d-flex justify-content-center">
                                <div className="mt-3">
                                  <button className="btn-1">
                                    More Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}

            {(activeTab == "All" || activeTab == "Serengeti") && (
              <div className="col-12 Ngororo justify-content-center align-items-center d-flex flex-wrap">
                <div className="col-10 text-center mt-4 fw-bold h1 mb-2">
                  Serengeti
                </div>
                <div className="col-10 mt-4">
                  <div className="col-12 text-center h3 mb-2">
                    Extra Luxury Lodges
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap  justify-content-center">
                    {serengetiExtraLuxLodges?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2`}
                          key={s.name2}
                        >
                          <Link
                            to={`/accommodation/${s.name2}`}
                            style={{ all: "unset" }}
                          >
                            <div
                              style={{
                                visibility: showAnimations
                                  ? "visible"
                                  : "hidden",
                              }}
                              // style={{ cursor: "pointer" }}
                              // ref={section1Ref}

                              className="card shadow crdHover d-flex flex-column"
                            >
                              <div>
                                <DialogImage
                                  src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                />
                              </div>
                              <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                <div className="col-12 text-center mt-2 fw-bold ">
                                  {s.name}
                                </div>
                              </div>
                              <div
                                className="col-12 ps-2 mt-2 pe-2"
                                style={{ height: "150px", overFlowY: "auto" }}
                              >
                                {s.overview?.substring(0, 200)} ..
                              </div>
                              <div className="col-12 pb-3 d-flex justify-content-center">
                                <div className="mt-3">
                                  <button className="btn-1">
                                    More Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                  <div className="col-12 text-center h3 mt-3 mb-2">
                    Luxury Lodges
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap  justify-content-center">
                    {serengetiLuxLodges?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2`}
                          key={s.name2}
                        >
                          <Link
                            to={`/accommodation/${s.name2}`}
                            style={{ all: "unset" }}
                          >
                            <div
                              style={{
                                visibility: showAnimations
                                  ? "visible"
                                  : "hidden",
                              }}
                              // style={{ cursor: "pointer" }}
                              // ref={section1Ref}

                              className="card shadow crdHover d-flex flex-column"
                            >
                              <div>
                                <DialogImage
                                  src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                />
                              </div>
                              <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                <div className="col-12 text-center mt-2 fw-bold ">
                                  {s.name}
                                </div>
                              </div>
                              <div
                                className="col-12 ps-2 mt-2 pe-2"
                                style={{ height: "150px", overFlowY: "auto" }}
                              >
                                {s.overview?.substring(0, 200)} ..
                              </div>
                              <div className="col-12 pb-3 d-flex justify-content-center">
                                <div className="mt-3">
                                  <button className="btn-1">
                                    More Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                  <div className="col-12 text-center h3 mt-3 mb-2">
                    Mid-range Lodges
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap  justify-content-center">
                    {serengetiMidRangeLodges?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2`}
                          key={s.name2}
                        >
                          <Link
                            to={`/accommodation/${s.name2}`}
                            style={{ all: "unset" }}
                          >
                            <div
                              style={{
                                visibility: showAnimations
                                  ? "visible"
                                  : "hidden",
                              }}
                              // style={{ cursor: "pointer" }}
                              // ref={section1Ref}

                              className="card shadow crdHover d-flex flex-column"
                            >
                              <div>
                                <DialogImage
                                  src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                />
                              </div>
                              <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                <div className="col-12 text-center mt-2 fw-bold ">
                                  {s.name}
                                </div>
                              </div>
                              <div
                                className="col-12 ps-2 mt-2 pe-2"
                                style={{ height: "150px", overFlowY: "auto" }}
                              >
                                {s.overview?.substring(0, 200)} ..
                              </div>
                              <div className="col-12 pb-3 d-flex justify-content-center">
                                <div className="mt-3">
                                  <button className="btn-1">
                                    More Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                  <div className="col-12 text-center h3 mt-3 mb-2">
                    Budget Campsite
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap  justify-content-center">
                    {serengetiBudgetCampsite?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2`}
                          key={s.name2}
                        >
                          <Link
                            to={`/accommodation/${s.name2}`}
                            style={{ all: "unset" }}
                          >
                            <div
                              style={{
                                visibility: showAnimations
                                  ? "visible"
                                  : "hidden",
                              }}
                              // style={{ cursor: "pointer" }}
                              // ref={section1Ref}

                              className="card shadow crdHover d-flex flex-column"
                            >
                              <div>
                                <DialogImage
                                  src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                />
                              </div>
                              <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                <div className="col-12 text-center mt-2 fw-bold ">
                                  {s.name}
                                </div>
                              </div>
                              <div
                                className="col-12 ps-2 mt-2 pe-2"
                                style={{ height: "150px", overFlowY: "auto" }}
                              >
                                {s.overview?.substring(0, 200)} ..
                              </div>
                              <div className="col-12 pb-3 d-flex justify-content-center">
                                <div className="mt-3">
                                  <button className="btn-1">
                                    More Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}

            {(activeTab == "All" || activeTab == "Zanzibar") && (
              <div className="col-12 Ngororo justify-content-center align-items-center d-flex flex-wrap">
                {" "}
                <div className="col-10 mt-4">
                  <div className="col-12 text-center h1 fw-bold  mb-4">
                    ZANZIBAR
                  </div>
                  <div className="col-12 text-center h3 mb-2">
                    Stone Town - Luxury Accommodation
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap  justify-content-center">
                    {zanzibarStoneTwnLux?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2`}
                          key={s.name2}
                        >
                          <Link
                            to={`/accommodation/${s.name2}`}
                            style={{ all: "unset" }}
                          >
                            <div
                              style={{
                                visibility: showAnimations
                                  ? "visible"
                                  : "hidden",
                              }}
                              // style={{ cursor: "pointer" }}
                              // ref={section1Ref}

                              className="card shadow crdHover d-flex flex-column"
                            >
                              <div>
                                <DialogImage
                                  src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                />
                              </div>
                              <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                <div className="col-12 text-center mt-2 fw-bold ">
                                  {s.name}
                                </div>
                              </div>
                              <div
                                className="col-12 ps-2 mt-2 pe-2"
                                style={{ height: "150px", overFlowY: "auto" }}
                              >
                                {s.overview?.substring(0, 200)} ..
                              </div>
                              <div className="col-12 pb-3 d-flex justify-content-center">
                                <div className="mt-3">
                                  <button className="btn-1">
                                    More Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                  <div className="col-12 text-center h3 mb-2">
                    Stone Town – Mid-range - Accommodation
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap  justify-content-center">
                    {zanzibarStoneTwnMidrange?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2`}
                          key={s.name2}
                        >
                          <Link
                            to={`/accommodation/${s.name2}`}
                            style={{ all: "unset" }}
                          >
                            <div
                              style={{
                                visibility: showAnimations
                                  ? "visible"
                                  : "hidden",
                              }}
                              // style={{ cursor: "pointer" }}
                              // ref={section1Ref}

                              className="card shadow crdHover d-flex flex-column"
                            >
                              <div>
                                <DialogImage
                                  src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                />
                              </div>
                              <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                <div className="col-12 text-center mt-2 fw-bold ">
                                  {s.name}
                                </div>
                              </div>
                              <div
                                className="col-12 ps-2 mt-2 pe-2"
                                style={{ height: "150px", overFlowY: "auto" }}
                              >
                                {s.overview?.substring(0, 200)} ..
                              </div>
                              <div className="col-12 pb-3 d-flex justify-content-center">
                                <div className="mt-3">
                                  <button className="btn-1">
                                    More Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                  <div className="col-12 text-center h3 mb-2">
                    Nungwi - Luxury Accommodation
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap  justify-content-center">
                    {zanzibarNungwiLux?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2`}
                          key={s.name2}
                        >
                          <Link
                            to={`/accommodation/${s.name2}`}
                            style={{ all: "unset" }}
                          >
                            <div
                              style={{
                                visibility: showAnimations
                                  ? "visible"
                                  : "hidden",
                              }}
                              // style={{ cursor: "pointer" }}
                              // ref={section1Ref}

                              className="card shadow crdHover d-flex flex-column"
                            >
                              <div>
                                <DialogImage
                                  src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                />
                              </div>
                              <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                <div className="col-12 text-center mt-2 fw-bold ">
                                  {s.name}
                                </div>
                              </div>
                              <div
                                className="col-12 ps-2 mt-2 pe-2"
                                style={{ height: "150px", overFlowY: "auto" }}
                              >
                                {s.overview?.substring(0, 200)} ..
                              </div>
                              <div className="col-12 pb-3 d-flex justify-content-center">
                                <div className="mt-3">
                                  <button className="btn-1">
                                    More Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                  <div className="col-12 text-center h3 mb-2">
                    Nungwi – Mid-range Accommodation
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap  justify-content-center">
                    {zanzibarNungwiMidRange?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2`}
                          key={s.name2}
                        >
                          <Link
                            to={`/accommodation/${s.name2}`}
                            style={{ all: "unset" }}
                          >
                            <div
                              style={{
                                visibility: showAnimations
                                  ? "visible"
                                  : "hidden",
                              }}
                              // style={{ cursor: "pointer" }}
                              // ref={section1Ref}

                              className="card shadow crdHover d-flex flex-column"
                            >
                              <div>
                                <DialogImage
                                  src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                />
                              </div>
                              <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                <div className="col-12 text-center mt-2 fw-bold ">
                                  {s.name}
                                </div>
                              </div>
                              <div
                                className="col-12 ps-2 mt-2 pe-2"
                                style={{ height: "150px", overFlowY: "auto" }}
                              >
                                {s.overview?.substring(0, 200)} ..
                              </div>
                              <div className="col-12 pb-3 d-flex justify-content-center">
                                <div className="mt-3">
                                  <button className="btn-1">
                                    More Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                  <div className="col-12 text-center h3 mb-2">
                    Kiwengwa - Accommodation
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap  justify-content-center">
                    {zanzibarKiwengwa?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2`}
                          key={s.name2}
                        >
                          <Link
                            to={`/accommodation/${s.name2}`}
                            style={{ all: "unset" }}
                          >
                            <div
                              style={{
                                visibility: showAnimations
                                  ? "visible"
                                  : "hidden",
                              }}
                              // style={{ cursor: "pointer" }}
                              // ref={section1Ref}

                              className="card shadow crdHover d-flex flex-column"
                            >
                              <div>
                                <DialogImage
                                  src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                />
                              </div>
                              <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                <div className="col-12 text-center mt-2 fw-bold ">
                                  {s.name}
                                </div>
                              </div>
                              <div
                                className="col-12 ps-2 mt-2 pe-2"
                                style={{ height: "150px", overFlowY: "auto" }}
                              >
                                {s.overview?.substring(0, 200)} ..
                              </div>
                              <div className="col-12 pb-3 d-flex justify-content-center">
                                <div className="mt-3">
                                  <button className="btn-1">
                                    More Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                  <div className="col-12 text-center h3 mb-2">
                    Jambiani – Luxury Accommodation
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap  justify-content-center">
                    {zanzibarJambianiLux?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2`}
                          key={s.name2}
                        >
                          <Link
                            to={`/accommodation/${s.name2}`}
                            style={{ all: "unset" }}
                          >
                            <div
                              style={{
                                visibility: showAnimations
                                  ? "visible"
                                  : "hidden",
                              }}
                              // style={{ cursor: "pointer" }}
                              // ref={section1Ref}

                              className="card shadow crdHover d-flex flex-column"
                            >
                              <div>
                                <DialogImage
                                  src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                />
                              </div>
                              <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                <div className="col-12 text-center mt-2 fw-bold ">
                                  {s.name}
                                </div>
                              </div>
                              <div
                                className="col-12 ps-2 mt-2 pe-2"
                                style={{ height: "150px", overFlowY: "auto" }}
                              >
                                {s.overview?.substring(0, 200)} ..
                              </div>
                              <div className="col-12 pb-3 d-flex justify-content-center">
                                <div className="mt-3">
                                  <button className="btn-1">
                                    More Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                  <div className="col-12 text-center h3 mb-2">
                    Jambiani – Mid-range Accommodation
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap  justify-content-center">
                    {zanzibarJambianiMidRange?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2`}
                          key={s.name2}
                        >
                          <Link
                            to={`/accommodation/${s.name2}`}
                            style={{ all: "unset" }}
                          >
                            <div
                              style={{
                                visibility: showAnimations
                                  ? "visible"
                                  : "hidden",
                              }}
                              // style={{ cursor: "pointer" }}
                              // ref={section1Ref}

                              className="card shadow crdHover d-flex flex-column"
                            >
                              <div>
                                <DialogImage
                                  src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                />
                              </div>
                              <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                <div className="col-12 text-center mt-2 fw-bold ">
                                  {s.name}
                                </div>
                              </div>
                              <div
                                className="col-12 ps-2 mt-2 pe-2"
                                style={{ height: "150px", overFlowY: "auto" }}
                              >
                                {s.overview?.substring(0, 200)} ..
                              </div>
                              <div className="col-12 pb-3 d-flex justify-content-center">
                                <div className="mt-3">
                                  <button className="btn-1">
                                    More Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
            {(activeTab == "All" || activeTab == "Pemba Island") && (
              <div className="col-12 Ngororo justify-content-center align-items-center d-flex flex-wrap">
                <div className="col-10 mt-4">
                  <div className="col-12 text-center h1 fw-bold mt-3 mb-2">
                    PEMBA ISLAND
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap  justify-content-center">
                    {pembaIsland?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2`}
                          key={s.name2}
                        >
                          <Link
                            to={`/accommodation/${s.name2}`}
                            style={{ all: "unset" }}
                          >
                            <div
                              style={{
                                visibility: showAnimations
                                  ? "visible"
                                  : "hidden",
                              }}
                              // style={{ cursor: "pointer" }}
                              // ref={section1Ref}

                              className="card shadow crdHover d-flex flex-column"
                            >
                              <div>
                                <DialogImage
                                  src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                />
                              </div>
                              <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                <div className="col-12 text-center mt-2 fw-bold ">
                                  {s.name}
                                </div>
                              </div>
                              <div
                                className="col-12 ps-2 mt-2 pe-2"
                                style={{ height: "150px", overFlowY: "auto" }}
                              >
                                {s.overview?.substring(0, 200)} ..
                              </div>
                              <div className="col-12 pb-3 d-flex justify-content-center">
                                <div className="mt-3">
                                  <button className="btn-1">
                                    More Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
            {(activeTab == "All" || activeTab == "Dar es salam") && (
              <div className="col-12 Ngororo justify-content-center align-items-center d-flex flex-wrap">
                <div className="col-10 mt-4">
                  <div className="col-12 text-center h1 fw-bold mt-3 mb-2">
                    DAR ES SALAAM
                  </div>
                  <div className="col-12 text-center h3  mt-2 mb-2">
                    Luxury Accommodation
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap  justify-content-center">
                    {dzmLux?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2`}
                          key={s.name2}
                        >
                          <Link
                            to={`/accommodation/${s.name2}`}
                            style={{ all: "unset" }}
                          >
                            <div
                              style={{
                                visibility: showAnimations
                                  ? "visible"
                                  : "hidden",
                              }}
                              // style={{ cursor: "pointer" }}
                              // ref={section1Ref}

                              className="card shadow crdHover d-flex flex-column"
                            >
                              <div>
                                <DialogImage
                                  src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                />
                              </div>
                              <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                <div className="col-12 text-center mt-2 fw-bold ">
                                  {s.name}
                                </div>
                              </div>
                              <div
                                className="col-12 ps-2 mt-2 pe-2"
                                style={{ height: "150px", overFlowY: "auto" }}
                              >
                                {s.overview?.substring(0, 200)} ..
                              </div>
                              <div className="col-12 pb-3 d-flex justify-content-center">
                                <div className="mt-3">
                                  <button className="btn-1">
                                    More Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                  <div className="col-12 text-center h3  mt-2 mb-2">
                    Mid-range Accommodation
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap  justify-content-center">
                    {dzmMidrange?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2`}
                          key={s.name2}
                        >
                          <Link
                            to={`/accommodation/${s.name2}`}
                            style={{ all: "unset" }}
                          >
                            <div
                              style={{
                                visibility: showAnimations
                                  ? "visible"
                                  : "hidden",
                              }}
                              // style={{ cursor: "pointer" }}
                              // ref={section1Ref}

                              className="card shadow crdHover d-flex flex-column"
                            >
                              <div>
                                <DialogImage
                                  src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                />
                              </div>
                              <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                <div className="col-12 text-center mt-2 fw-bold ">
                                  {s.name}
                                </div>
                              </div>
                              <div
                                className="col-12 ps-2 mt-2 pe-2"
                                style={{ height: "150px", overFlowY: "auto" }}
                              >
                                {s.overview?.substring(0, 200)} ..
                              </div>
                              <div className="col-12 pb-3 d-flex justify-content-center">
                                <div className="mt-3">
                                  <button className="btn-1">
                                    More Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
            {(activeTab == "All" || activeTab == "Mafia Island") && (
              <div className="col-12 Ngororo justify-content-center align-items-center d-flex flex-wrap">
                <div className="col-10 mt-4 ">
                  <div className="col-12 text-center h1 fw-bold  mt-4 mb-2">
                    Mafia Island
                  </div>
                  <div className="mt-2 col-12 p-3  d-flex flex-wrap  justify-content-center">
                    {mafiaIsland?.map((s, index) => {
                      return (
                        <div
                          className={`${
                            isMobile
                              ? "col-12"
                              : isTablet
                              ? "col-6"
                              : isDesktop
                              ? "col-4"
                              : isBigDesktop
                              ? "col-3"
                              : isBiggerScreen
                              ? "col-2"
                              : "col-12"
                          }  p-2`}
                          key={s.name2}
                        >
                          <Link
                            to={`/accommodation/${s.name2}`}
                            style={{ all: "unset" }}
                          >
                            <div
                              style={{
                                visibility: showAnimations
                                  ? "visible"
                                  : "hidden",
                              }}
                              // style={{ cursor: "pointer" }}
                              // ref={section1Ref}

                              className="card shadow crdHover d-flex flex-column"
                            >
                              <div>
                                <DialogImage
                                  src={require(`../../Images/accomodataions/${s?.images[0]?.image}`)}
                                />
                              </div>
                              <div className="col-12 fw-bold d-flex justify-content-center mt-2">
                                <div className="col-12 text-center mt-2 fw-bold ">
                                  {s.name}
                                </div>
                              </div>
                              <div
                                className="col-12 ps-2 mt-2 pe-2"
                                style={{ height: "150px", overFlowY: "auto" }}
                              >
                                {s.overview?.substring(0, 200)} ..
                              </div>
                              <div className="col-12 pb-3 d-flex justify-content-center">
                                <div className="mt-3">
                                  <button className="btn-1">
                                    More Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Accomodations;
