import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { userLoginRd } from "./Reducers/UserAndPhnRd";
import {
  getAllOtherInfoRd,
  editAllOtherInfoRd,
  createSafariRd,
  getAllBookedSafarisRd,
  getBookedSafariByIdRd,
  deleteByIdRd,
} from "./Reducers/AdminPnRd";

const reducer = combineReducers({
  userInformation: userLoginRd,
  GetAllOtherInfo: getAllOtherInfoRd,
  EditAllInfo: editAllOtherInfoRd,
  CreateSafari: createSafariRd,
  AllBookedSafaris: getAllBookedSafarisRd,
  BookedSFById: getBookedSafariByIdRd,
  deleteSFById: deleteByIdRd,
});

const userFromStorage = sessionStorage.getItem("userInfo")
  ? JSON.parse(sessionStorage.getItem("userInfo"))
  : null;

const getAllOtherInfoFromStorage = sessionStorage.getItem("allOtherInfo")
  ? JSON.parse(sessionStorage.getItem("allOtherInfo"))
  : [];

const getAllBookedSafarisFromStorage = sessionStorage.getItem(
  "allBookedSafaris"
)
  ? JSON.parse(sessionStorage.getItem("allBookedSafaris"))
  : [];

const initialstate = {
  userInformation: { userInfo: userFromStorage },
  GetAllOtherInfo: { allInfos: getAllOtherInfoFromStorage },
  AllBookedSafaris: { bookedSafaris: getAllBookedSafarisFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialstate,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
