import React from 'react'
import { Alert } from 'react-bootstrap'


function Message({variant, children, pt,pb}) {
    return (
        <Alert className="" variant ={variant} style={{paddingTop:`${pt}px`,paddingBottom:`${pb}px`}}>
            {children}
        </Alert>
    )
}

export default Message
