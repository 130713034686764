import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

const DialogImage = styled.img`
  width: 100%;
  height: 15vw;
  object-fit: cover;
  border-radius: 10px 10px 0 0;

  @media (max-width: 768px) {
    /* Mobile devices */
    height: 40vw;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    /* Tablets */
    height: 30vw;
  }
`;

function Recommend({ products }) {
  const isBiggerScreen = useMediaQuery({ minWidth: 1824 });
  const isBigDesktop = useMediaQuery({ minWidth: 1224, maxWidth: 1823 });
  const isDesktop = useMediaQuery({ minWidth: 992, maxWidth: 1223 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <div className="bg-light safariScreen mt-0">
      <div className="col-12 d-flex flex-column align-items-center justify-contents-center">
        <div className="col-10 text-center mt-2 h2">
          Explore the World with Recommended Backpacks
        </div>
        <div className="col-10 text-center mt-1">
          <p>
            Embarking on unforgettable journeys requires more than just a spirit
            of adventure; it demands the right tools and accessories to make
            each moment memorable. At [Your Website Name], we understand the
            essence of equipping yourself with quality gear, especially when it
            comes to tourism and mountain climbing. That's why we've
            meticulously curated a collection of premium travel essentials
            designed to enhance your experiences and ensure you're well-prepared
            for every step of the way.
          </p>
        </div>
        <div className="col-10 text-center mt-2 h4">
          The Essence of Quality Accessories:
        </div>
        <div className="col-10 text-center mt-1">
          <p>
            Certainly, let's expand on the introduction and focus more on the
            essence of good and original accessories for tourism and mountain
            climbing: Heading: Elevate Your Adventures with Premium Travel
            Essentials! Introduction: Embarking on unforgettable journeys
            requires more than just a spirit of adventure; it demands the right
            tools and accessories to make each moment memorable. At [Your
            Website Name], we understand the essence of equipping yourself with
            quality gear, especially when it comes to tourism and mountain
            climbing. That's why we've meticulously curated a collection of
            premium travel essentials designed to enhance your experiences and
            ensure you're well-prepared for every step of the way. The Essence
            of Quality Accessories: When it comes to tourism and conquering
            mountain peaks, the right accessories can be the difference between
            a good trip and an extraordinary one. Our selection goes beyond the
            ordinary, focusing on the essence of durability, functionality, and
            style. From rugged backpacks that withstand the toughest terrains to
            innovative accessories that simplify your journey, we've prioritized
            items that embody the spirit of adventure.
          </p>
        </div>
        <div className="col-10 text-center mt-2 h4">
          Unleash Your Adventurous Spirit:
        </div>
        <div className="col-10 text-center mt-1">
          <p>
            Whether you're a seasoned traveler or a novice mountaineer, having
            the right equipment is vital. Our recommended collection is a
            celebration of the adventurous spirit within you. Each accessory has
            been carefully chosen to complement your travel style, providing not
            just utility, but a touch of originality that sets your journey
            apart.
          </p>
        </div>
        <div className="col-10 text-center mt-2 h4">
          Originality in Every Detail
        </div>
        <div className="col-10 text-center mt-1">
          <p>
            We believe that authenticity matters. That's why our recommended
            travel essentials aren't just practical; they're a statement of your
            unique approach to exploration. From handcrafted details to
            innovative designs, our recommendations embody the essence of
            authenticity, ensuring that every item you choose reflects your
            individuality.
          </p>
        </div>
        <div className="col-10 text-center mt-2 h4">
          Navigating the Peaks and Valleys
        </div>
        <div className="col-10 text-center mt-1">
          <p>
            For those drawn to the allure of mountain climbing, our
            recommendations are a tribute to conquering heights. We've sourced
            accessories that not only withstand the challenges of elevation but
            also enhance your performance. From moisture-wicking apparel to
            ergonomically designed backpacks, our offerings are a testament to
            the meticulous planning required for a successful ascent.
          </p>
        </div>
        <div className="col-10 text-center mt-2 h4">Begin Your Adventure</div>
        <div className="col-10 text-center mt-1">
          <p>
            Explore our extensive list of recommended premium travel essentials
            and discover a world where quality meets adventure. Click on the
            links to delve deeper into the details of each product, and let your
            journey be defined by the essence of excellence.
          </p>
        </div>

        <div className="col-10 d-flex flex-wrap">
          {products?.map((p) => {
            return (
              <div
                className={`${
                  isMobile
                    ? "col-12"
                    : isTablet
                    ? "col-6"
                    : isDesktop
                    ? "col-4"
                    : "col-3"
                } p-4`}
              >
                <div className="card flex-column">
                  <a
                    href={`${p.imageLink}`}
                    // className="col-12 d-flex justify-contents-center"
                    target="_blank"
                  >
                    <DialogImage src={`${p.image}`} />
                    {/* <img width="400" height="350" src={`${p.image}`} />
                     */}
                  </a>
                  <a
                    style={{ textDecoration: "none" }}
                    className="text-secondary mt-2 p-2"
                    href={`${p.linkText}`}
                    target="_blank"
                  >
                    <p className="text-center">{p.name}</p>
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Recommend;
