import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import tz from "../../pdff/tz.pdf";
import znz from "../../pdff/znz.pdf";
import pdf from "../../pdff/pdf.png";
import FooterImages from "../../Components/FooterImages";

const styles = {
  container: {
    width: "100%",
    height: "600px",
  },
};
function CovidScreen() {
  // const [numPages, setNumPages] = useState(null);
  // const [pageNumber, setPageNumber] = useState(1);

  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  // }

  // const pdfFileNameA = "tz.pdf"; // Replace with your actual PDF file name

  const pdfFileNameA = "Travel-Advisory-No-12-Tanzania-Mainland.pdf"; // Replace with your actual PDF file name
  const pdfFileNameB = "Travel-Advisory-No-15-Zanzibar.pdf"; // Replace with your actual PDF file name

  const handleOpenPDFA = () => {
    window.open(`${tz}`, "_blank");
    // window.open(`../../pdff/${pdfFileNameA}`, "_blank");
  };

  const handleOpenPDFB = () => {
    window.open(`${znz}`, "_blank");
  };

  return (
    <div
      className="col-12"
      style={{
        backgroundImage: `url(${require(`../../Logos/logoT1.png`)})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "60%",
      }}
    >
      <div className="col-12 " style={{ minHeight: "100vh" }}>
        <div className="col-12 d-flex justify-content-center align-items-center">
          <div className="col-8 mt-4 card card-shadow p-4">
            <table className="col-12 table">
              <thead>
                <tr>
                  <th className="text-center h4 pb-4" colSpan="2">
                    Update on Corona Virus Disease (COVID-19) in Tanzania
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td classNmae="pb-2">
                    <div classNmae="col-12 d-flex">
                      <img
                        src={pdf}
                        style={{ height: "20px", width: "25px" }}
                      />{" "}
                      <span>{pdfFileNameA}</span>
                    </div>
                  </td>
                  <td classNmae="pb-2">
                    <div className="col-12 d-flex justify-content-end">
                      <button className="btn-1" onClick={handleOpenPDFA}>
                        Read
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div classNmae="col-12 d-flex">
                      <img
                        src={pdf}
                        style={{ height: "20px", width: "25px" }}
                      />{" "}
                      <span>{pdfFileNameB}</span>
                    </div>
                  </td>
                  <td>
                    <div className="col-12 d-flex justify-content-end">
                      <button className="btn-1" onClick={handleOpenPDFB}>
                        Read
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CovidScreen;
