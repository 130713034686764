import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useMediaQuery } from "react-responsive";
import "./dest.css";
import { useLocation } from "react-router-dom";
import FooterImages from "../../Components/FooterImages";

gsap.registerPlugin(ScrollTrigger);

const DialogImage = styled.img`
  width: 100%;
  height: 23vw;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
`;

const BackgroundHome = ({ images }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const imageElements = container.querySelectorAll(".imgt");

    const action = gsap.timeline({ repeat: -1, repeatDelay: 3 });

    imageElements.forEach((imageElement, index) => {
      action.to(imageElement, {
        autoAlpha: 1,
        duration: 1.5,
        delay: index === 0 ? 0 : 3,
      });
    });

    action.play();

    return () => {
      action.kill();
    };
  }, [images]);

  return (
    <div
      ref={containerRef}
      id="slideshow-container"
      className="img-container"
      style={{ height: "45vh", width: "100%", position: "relative" }}
    >
      {images?.map((imageSrc, index) => (
        <div
          key={index}
          className="imgt"
          style={{
            backgroundImage: `url(${require(`../../Images/Destinations/${imageSrc.image}`)})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            opacity: index === 0 ? 1 : 0,
          }}
        ></div>
      ))}
    </div>
  );
};

function DestinationMainScreen({ destinations }) {
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const slideshowRef = useRef(null);

  const [showAnimations, setShowAnimations] = useState(false);
  const isBiggerScreen = useMediaQuery({ minWidth: 1824 });
  const isBigDesktop = useMediaQuery({ minWidth: 1224, maxWidth: 1823 });
  const isDesktop = useMediaQuery({ minWidth: 992, maxWidth: 1223 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    gsap.from(section1Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section1Ref.current,
        start: "top 80%", // Adjust the start position as needed
        end: "bottom 20%", // Adjust the end position as needed
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section2Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section2Ref.current,
        start: "top 100%",
        end: "bottom 100%",
        toggleActions: "play none none reverse",
      },
    });

    gsap.from(section3Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section3Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });
    gsap.from(section4Ref.current, {
      opacity: 0,
      y: 100,
      scrollTrigger: {
        trigger: section4Ref.current,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });
  }, []);

  const location = useLocation();
  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location]);
  return (
    <div
      className="bg-light destinationScreen col-12"
      style={{
        paddingBottom: "10vw",
        minHeight: "100vh"
      }}
    >
      <div className="col-12 ">
        <div className="col-12 d-flex flex-column align-items-center justify-content-center">
          <div
            className={`${
              isMobile ? "col-12 p-2" : isTablet ? "col-10" : "col-7"
            } mt-5`}
            ref={section1Ref}
          >
            <div className="col-12 text-center m h3">THE AFRICAN CONTINENT</div>
            <div className="col-12 mt-2">
              <p>
                Africa is the world’s second largest continent in both
                population and area that covers 6% of the earth’s total surface,
                and 20% of the planet's land area. 54 is the most accurate count
                of globally-recognized states and territories on the continent,
                48 countries share the area of mainland Africa, plus six island
                nations are considered to be part of the continent. The largest
                country in Africa is Algeria, while the smallest is Seychelles.
                The continent also hosts diverse ethnicities, cultures,
                tradition, and languages. Africa is widely accepted as the
                origin of the first human species. The earliest hominids and its
                ancestors, as well as the earliest Homo sapiens, were found in
                Ethiopia about 200,000 years ago. Africa has the largest wild
                animal populations. The continent boasts an overwhelming density
                and diversity of wildlife, as well as a wide selection of plant
                species. Massive populations of carnivores and herbivores range
                freely on open plains. Africa values nature and wildlife with
                its more than 2000 protected areas, which consist of many
                biosphere reserves, wetland reserves, and marine protected areas
              </p>
            </div>
          </div>
          <div
            className={`${
              isMobile ? "col-12 p-2" : isTablet ? "col-10" : "col-7"
            } mt-4`}
            ref={section2Ref}
          >
            <div className="col-12 h3 text-center">TANZANIA</div>
            <div className="col-12 mt-2">
              <span>
                Formerly known as Tanganyika. In 1964, Tanganyika united with
                the Island of Zanzibar to form the United Republic of Tanzania,
                the largest of the East African countries. Tanzania is home to
                an exceptionally rich array of wildlife. Large populations of
                carnivores and herbivores are found in most of the country’s
                numerous game parks. Crocodiles, hippopotamuses, Rhinoceroses,
                elephants, chimpanzees, over 1,500 varieties of birds, and
                numerous species of snakes and lizards are also in Tanzania. In
                all, about one-fourth of Tanzania’s land has been set aside to
                form an extensive network of reserves, conservation areas, and
                national parks, a number of which including Serengeti National
                Park, Maswa Game Reserve, Ngorongoro Conservation Area, and
                others have been designated UNESCO World Heritage sites.
                Tanzania offers something for everyone, spectacular wildlife and
                landscapes, cultural history, tropical paradise islands with
                turquoise waters, opportunities for climbing the “Roof of
                Africa” Mount Kilimanjaro. The highest mountain in Africa, has a
                height of 5,895 m (19,340 ft). The mountain has three volcanic
                cones Kibo, Mawenzi, and Shira. Uhuru Peak on Kibo is the
                mountain's highest point. In Tanzania there are countless
                opportunities for adventure, luxury and forging unforgettable
                memories.
              </span>
            </div>
          </div>
          <div
            className={`${
              isMobile ? "col-12 p-2" : isTablet ? "col-10" : "col-7"
            } pt-4`}
            // id="Zanzibar"
            ref={section3Ref}
          >
            <div className="col-12 text-center h3">
              WHAT YOU NEED TO ENTER TANZANIA
            </div>
            <div className="col-12 mt-2">
              <p>
                As part of World, the requirements for entry to Tanzania are in
                line with the any other country in the world entry requirements.
                A valid visa or e-visa printout, a passport which is valid for
                not less than six months and with at least 2 empty pages upon
                arrival.
              </p>
            </div>
            <div className="col-12 mt-2">
              <div className="col-12 mb-1">
                There are 3 types of Visas available for tourists
              </div>
              <div className="col-12 mb-1 ms-0 ps-0">
                <ul className="col-12" style={{ listStyle: "square" }}>
                  <li className="">
                    <span className="fw-bold me-1">Transit Visa: </span>
                    <span className="">
                      Short-time visas granted to travelers who will visit
                      Tanzania for only a short time or are stopping over at
                      Tanzania on their way to other countries/destination.
                      Validity for this type of visa is 14 days. Visa fee: $30.{" "}
                    </span>
                  </li>
                  <li className="">
                    <span className="fw-bold me-1">Regular Visa: </span>
                    <span className="">
                      {" "}
                      Single-entry visa that is valid for three (3) months only.
                      Visa fee: $50.
                    </span>
                  </li>
                  <li className="">
                    <span className="fw-bold me-1">Multiple-Entry Visa: </span>
                    <span className="">
                      {" "}
                      Visa issued for visitors who wish to visit frequently for
                      leisure and holiday purposes. This may be issued for a
                      period of 3, 6 or 12 months wherein the visa holder may be
                      allowed to enter and re-enter the country provided that a
                      single stay of the holder must not exceed 3 months. Visa
                      fee: $100.
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-12 mt-4 text-center fw-bold">
                YOU ARE WARMLY WELCOME
              </div>
              <div className="col-12 mt-4 text-center">
                Let's embark on a journey together to uncover the vibrant
                colours of our world. Join us as we explore friendly
                destinations that embrace love and diversity worldwide.
                Experience awe-inspiring places including the most enriching
                cultural events, captivating activities and a spectacular view
                from the roof of Africa - Mount Kilimanjaro. It's time to let
                your Pride shine brightly!
              </div>
            </div>
          </div>
          <div className="col-10 mt-5" ref={section4Ref}>
            <div className="col-12 text-center h2 mb-4 mt-3">
              POPULAR DESTINATIONS
            </div>
            <div className="col-12">
              <table className="col-12">
                {destinations?.map((d, index) => {
                  return isMobile || isTablet ? (
                    <div className="col-12 d-flex flex-column">
                      <div className="ol-12 text-center fs-3 fw-bold mb-3">
                        {d.title}
                      </div>
                      <div className="col-12  mb-3">
                        {" "}
                        <BackgroundHome images={d?.Images} />
                      </div>
                      <div className="col-12 text-center p-2">
                        <p>{d.description}</p>
                      </div>
                    </div>
                  ) : index % 2 === 0 ? (
                    <tr id={`${d.title.replace(/\s/g, "")}`}>
                      <td className="col-7 pt-5 pb-5">
                        <div className="col-12 d-flex flex-column">
                          <div className="col-12 mb-3 text-center">
                            <h3>{d.title}</h3>
                          </div>
                          <div className="col-12">
                            <p>{d.description}</p>
                          </div>
                        </div>
                      </td>
                      <td className="col-5 pt-5 pb-5">
                        <BackgroundHome images={d?.Images} />
                      </td>
                    </tr>
                  ) : (
                    <tr id={`${d.title.replace(/\s/g, "")}`}>
                      <td className="col-5 pt-5 pb-5 pe-3">
                        <BackgroundHome images={d?.Images} />
                      </td>
                      <td className="col-7 pt-5 pb-5 ">
                        <div className="col-12 d-flex flex-column">
                          <div className="col-12 mb-3 text-center">
                            <h3>{d.title}</h3>
                          </div>
                          <div className="col-12">
                            <p>{d.description}</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DestinationMainScreen;
