import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { userLoginAct } from "../../Actions/UsersAction";
import { USER_LOGOUT } from "../../Constants/userConstants";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import logo from "../../Logos/logo1.png";
import TextField from "@mui/material/TextField";
import Message from "../../Components/Message";

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled(TextField)`
  width: 100% !important;
  padding: 1rem 1rem 1rem 2.4rem;
  border-radius: 10px !important;
  border: 1px solid #ccc;
  font-size: 1rem;
  outline: none;
  background-color: #f9f9f9;
  margin-bottom: 1rem;
  &:focus {
    border-color: #007bff;
  }

  @media only screen and (max-width: 768px) {
    padding: 1.5rem 1rem 1.5rem 2.4rem;
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    padding: 1.5rem 1rem 1.5rem 2.4rem;
  }
`;

const Icon = styled.i`
  position: absolute;
  left: 0.5rem;
  top: 40%;
  transform: translateY(-50%);
`;

const PasswordInput = ({ iconA, iconB, pwd, setPwd, ...rest }) => {
  return (
    <InputWrapper>
      <Icon className={iconA} />
      <Input type={pwd ? "password" : "text"} {...rest} />
      <Icon
        onClick={(e) => setPwd(!pwd)}
        className={`${iconB} float-end d-flex`}
        style={{ left: "90%", cursor: "pointer" }}
      />
    </InputWrapper>
  );
};

function LoginPage() {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const inputStyle = {
    color: "black",
    backgroundColor: "white",
    marginBottom: isMobile ? "2px" : "10px",
    borderRadius: "10px",
  };

  const labelStyle = {
    color: "grey",
    fontSize: "17px",
  };
  const userInformation = useSelector((state) => state.userInformation);
  const { error, success, loading: loginLoading, userInfo } = userInformation;

  const dispatch = useDispatch();

  const [pwd, setPwd] = useState(true);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo) {
      navigate("/messages-admin");
    }
  }, [userInfo, USER_LOGOUT]);

  //   const userName = name;

  const loginHandler = (e) => {
    e.preventDefault();
    dispatch(userLoginAct(userName, password));
  };

  return (
    <div className="mainScreen p-3">
      <div
        className="col-12  d-flex justify-content-center align-items-center"
        style={{ marginTop: isMobile ? "30px" : "130px" }}
      >
        <div className="col-4 bg-light card card-shadow p-3">
          <div className="col-12 d-flex justify-content-center">
            <img src={logo} style={{ width: "5rem", height: "4.5rem" }} />
          </div>
          <div className=" mt-2 mb-2 col-12 d-flex justify-content-center">
            <h3>Login</h3>
          </div>
          {error && <Message variant="danger">{error}</Message>}
          <div className="col-12 mt-2">
            <TextField
              type="text"
              className="regInput col-12"
              mode="outline"
              label="User Name"
              InputProps={{
                style: inputStyle,
              }}
              InputLabelProps={{
                style: labelStyle,
              }}
              name="userName"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </div>
          <div className="col-12 mt-4">
            <PasswordInput
              iconA="fa fa-lock"
              iconB={pwd ? "fa fa-eye" : "fa fa-eye-slash"}
              pwd={pwd}
              setPwd={setPwd}
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="col-12 mt-4 d-flex pb-3 justify-content-center">
            <button className="btn btn-primary" onClick={loginHandler}>
              {loginLoading && (
                <Spinner variant="light" size="sm" animation="border"></Spinner>
              )}
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
