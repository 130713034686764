import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import logo from "../Logos/logo1.png";
import fb from "../Images/facebook.png";
import insta from "../Images/instagram.png";
import pin from "../Images/pinterest.png";
import twitter from "../Images/twitter.png";
import whatsapp from "../Images/whatsapp.png";
import utube from "../Images/youtube.png";
import telegram from "../Images/telegram.png";
import { useMediaQuery } from "react-responsive";
function Footer() {
  const isBiggerScreen = useMediaQuery({ minWidth: 1824 });
  const isBigDesktop = useMediaQuery({ minWidth: 1224, maxWidth: 1823 });
  const isDesktop = useMediaQuery({ minWidth: 992, maxWidth: 1223 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const currentYear = new Date().getFullYear();
  return (
    <div
      className="Footer pt-4 pb-4 col-12 d-flex justify-content-center flex-wrap"
      style={{
        backgroundColor: "black",
        // backgroundColor: "#1E1E1E",
        color: "silver",
        backgroundImage: `url(${require(`../Logos/logo1.png`)})`,
        backgroundPosition: isMobile || isTablet ? "bottom left" : "left",
        backgroundRepeat: "no-repeat",
        backgroundSize: "20%",
      }}
    >
      <div
        className={`${
          isMobile
            ? "col-12 p-1"
            : isTablet
            ? "col-12 p-2 d-flex justify-content-between flex-wrap"
            : "col-9 d-flex justify-content-between flex-wrap"
        } `}
      >
        <div className={isMobile ? "col-12 mb-3" : "col-4"}>
          <div
            className={
              isMobile
                ? "col-12 d-flex flex-column ps-3"
                : "col-12 d-flex flex-column text-center"
            }
          >
            <div
              className={`${
                isMobile
                  ? "fs-6 pb-2 col-12 fw-bold"
                  : isTablet
                  ? "fs-5 col-12 text-center   fw-bold"
                  : "fs-4 col-12 text-center   fw-bold"
              } `}
              style={{ borderBottom: isMobile && "1px solid white" }}
            >
              {" "}
              USEFUL LINKS
            </div>
            <div className="col-12  mt-3 pt-0">
              <a
                className="lnkHover"
                href="https://eservices.immigration.go.tz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Tanzania e-Visa Services
              </a>
            </div>
            <div className="col-12  mt-0 pt-0">
              <a
                className="lnkHover"
                href="https://visa.immigration.go.tz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Online Tanzania Visa
              </a>
            </div>
            <div className="col-12  mt-0 pt-0">
              <a
                className="lnkHover"
                href="https://www.tanzaniatourism.go.tz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Tanzania Tourism Board
              </a>
            </div>

            <div className="col-12  mt-0 pt-0">
              <a
                className="lnkHover"
                href="https://www.tanzaniaparks.go.tz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Tanzania National Parks
              </a>
            </div>
            <div className="col-12  mt-0 pt-0">
              <a
                className="lnkHover"
                href="https://www.tanzaniaparks.go.tz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                World Currency Exchange
              </a>
            </div>
            <div className="col-12  mt-0 pt-0">
              <a
                className="lnkHover"
                href="https://flydoc.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                AMREF Flying Doctors
              </a>
            </div>
            <div className="col-12   mb-0">
              <Link
                className="lnkHover"
                to={{
                  pathname: "/about-us",
                  hash: "#paymentAndTerms",
                }}
                style={{ all: "unset", cursor: "pointer" }}
              >
                Payment & Terms
              </Link>
            </div>
          </div>
        </div>
        <div className={isMobile ? "col-12 mb-3 ps-3" : "col-4"}>
          <div
            className={`${
              !isMobile ? "text-center" : ""
            } col-12 d-flex flex-column `}
          >
            <div
              className={`${
                isMobile
                  ? "fs-6 pb-2"
                  : isTablet
                  ? "text-center fs-5"
                  : "text-center fs-4"
              } col-12    fw-bold`}
              style={{ borderBottom: isMobile && "1px solid white" }}
            >
              POPULAR DESTINATIONS
            </div>
            <div className="col-12  mt-3 mb-0">
              <Link
                className="lnkHover"
                to={{
                  pathname: "/destinations",
                  hash: "#Zanzibar",
                }}
                style={{ all: "unset", cursor: "pointer" }}
              >
                Zanzibar
              </Link>
            </div>
            <div className="col-12   mb-0">
              <Link
                to={{
                  pathname: "/destinations",
                  hash: "#LakeEyasi",
                }}
                style={{ all: "unset", cursor: "pointer" }}
                className="lnkHover"
              >
                Lake Eyasi
              </Link>
            </div>
            <div className="col-12   mb-0">
              <Link
                to={{
                  pathname: "/destinations",
                  hash: "#SerengetiNationalPark",
                }}
                style={{ all: "unset", cursor: "pointer" }}
                className="lnkHover"
              >
                Serengeti NP
              </Link>
            </div>
            <div className="col-12   mb-0">
              <Link
                to={{
                  pathname: "/destinations",
                  hash: "#ServalWildlife",
                }}
                style={{ all: "unset", cursor: "pointer" }}
                className="lnkHover"
              >
                Serval Wildlife
              </Link>
            </div>
            <div className="col-12   mb-0">
              <Link
                to={{
                  pathname: "/climbing-trekking",
                  hash: "#mtKilimanjaro",
                }}
                style={{ all: "unset", cursor: "pointer" }}
                className="lnkHover"
              >
                Mt. Kilimanjaro
              </Link>
            </div>
            <div className="col-12   mb-0">
              <Link
                to={{
                  pathname: "/destinations",
                  hash: "#NgorongoroConservationArea",
                }}
                style={{ all: "unset", cursor: "pointer" }}
                className="lnkHover"
              >
                Ngorongoro Crater
              </Link>
            </div>
            <div className="col-12   mb-0">
              <Link
                to={{
                  pathname: "/destinations",
                  hash: "#DaresSalaamCity",
                }}
                style={{ all: "unset", cursor: "pointer" }}
                className="lnkHover"
              >
                Dar es Salaam City
              </Link>
            </div>
          </div>
        </div>
        <div className={isMobile ? "col-12 pb-3 ps-3" : "col-4"}>
          <div
            className={`${
              !isMobile ? "text-center" : ""
            } col-12 d-flex flex-column `}
          >
            <div
              className={`${
                isMobile
                  ? "fs-6 pb-2"
                  : isTablet
                  ? "fs-5 text-center"
                  : "fs-4 text-center"
              } col-12  fw-bold`}
              style={{ borderBottom: isMobile && "1px solid white" }}
            >
              CONTACT DETAILS
            </div>
            <div className="col-12  mt-3 mb-0 ">360 Extreme Adventures Ltd</div>
            <div className="col-12  mt-0 pt-0">
              Headquarters: Arusha - Tanzania
            </div>
            <div className="col-12  mt-0 pt-0">P.O. Box 10716</div>
            <div className="col-12  mt-0 pt-0">Arusha -Tanzania</div>
            <div
              className={`${
                !isMobile ? "justify-content-center" : ""
              } col-12 d-flex mt-0 pt-0 `}
            >
              <i className="fa fa-phone mt-1"></i> : +255 710 005 101 / +255 744
              790 003
            </div>
            <div
              className={`${
                !isMobile ? "justify-content-center" : ""
              } col-12 d-flex mt-0 pt-0 `}
            >
              <i className="fa fa-envelope mt-1 me"></i> :
              info@360xtremeadventures.com
            </div>
          </div>
        </div>
      </div>
      <div
        className={` ${
          isMobile ? "col-12" : "col-8"
        } d-flex justify-content-center mt-3`}
      >
        <div className="ps-2 pe-2">
          <a
            className="lnkHover"
            href="https://www.facebook.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={fb}
              style={{
                width: isMobile ? "15px" : isTablet ? "15px" : "25px",
                height: isMobile ? "15px" : isTablet ? "15px" : "25px",
              }}
              className="icnn"
            />
          </a>
        </div>
        <div className="ps-2 pe-1">
          <a
            className="lnkHover"
            href="https://www.instagram.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={insta}
              style={{
                width: isMobile ? "15px" : isTablet ? "15px" : "25px",
                height: isMobile ? "15px" : isTablet ? "15px" : "25px",
              }}
              className="icnn"
            />
          </a>
        </div>
        <div className="ps-1 pe-1">
          <a
            className="lnkHover"
            href="https://www.twitter.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={twitter}
              style={{
                width: isMobile ? "25px" : isTablet ? "30px" : "55px",
                height: isMobile ? "20px" : isTablet ? "25px" : "35px",
              }}
              className="icnn"
            />
          </a>
        </div>
        <div className="ps-1 pe-1">
          <a
            className="lnkHover"
            href="https://www.whatsapp.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={whatsapp}
              className="icnn"
              style={{
                width: isMobile ? "15px" : isTablet ? "15px" : "55px",
                height: isMobile ? "15px" : isTablet ? "15px" : "35px",
              }}
            />
          </a>
        </div>
        <div className="ps-1 pe-1">
          <a
            className="lnkHover"
            href="https://www.youtube.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={utube}
              className="icnn"
              style={{
                width: isMobile ? "15px" : isTablet ? "15px" : "35px",
                height: isMobile ? "15px" : isTablet ? "15px" : "35px",
              }}
            />
          </a>
        </div>
        <div className="ps-2 pe-2">
          <a
            className="lnkHover"
            href="https://www.telegram.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={telegram}
              className="icnn"
              style={{
                width: isMobile ? "15px" : isTablet ? "15px" : "30px",
                height: isMobile ? "15px" : isTablet ? "15px" : "30px",
              }}
            />
          </a>
        </div>
        <div className="ps-2 pe-2">
          <a
            className="lnkHover "
            href="https://www.pinterest.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={pin}
              className="icnn"
              style={{
                width: isMobile ? "15px" : isTablet ? "15px" : "30px",
                height: isMobile ? "15px" : isTablet ? "15px" : "30px",
              }}
            />
          </a>
        </div>
      </div>
      <div
        className={`${
          isMobile ? "col-12" : isTablet ? "col-12" : "col-8"
        } d-flex justify-content-center mt-3`}
      >
        © 360 Extreme Adventures {currentYear}. All rights Reserved
      </div>
    </div>
  );
}

export default Footer;
