export const SET_ACTIVE_ELEMEMT = "SET_ACTIVE_ELEMEMT";
export const SET_ACTIVE_ELEMEMT_LOADING = "SET_ACTIVE_ELEMEMT_LOADING";

export const EDIT_OTHERINFOS_REQUEST = "EDIT_OTHERINFOS_REQUEST";
export const EDIT_OTHERINFOS_SUCCESS = "EDIT_OTHERINFOS_SUCCESS";
export const EDIT_OTHERINFOS_FAIL = "EDIT_OTHERINFOS_FAIL";
export const EDIT_OTHERINFOS_RESET = "EDIT_OTHERINFOS_RESET";

export const GET_ALL_OTHERINFOS_REQUEST = "GET_ALL_OTHERINFOS_REQUEST";
export const GET_ALL_OTHERINFOS_SUCCESS = "GET_ALL_OTHERINFOS_SUCCESS";
export const GET_ALL_OTHERINFOS_FAIL = "GET_ALL_OTHERINFOS_FAIL";
export const GET_ALL_OTHERINFOS_RESET = "GET_ALL_OTHERINFOS_RESET";

export const GET_ALL_BOOKED_SAFARIS_REQUEST = "GET_ALL_BOOKED_SAFARIS_REQUEST";
export const GET_ALL_BOOKED_SAFARIS_SUCCESS = "GET_ALL_BOOKED_SAFARIS_SUCCESS";
export const GET_ALL_BOOKED_SAFARIS_FAIL = "GET_ALL_BOOKED_SAFARIS_FAIL";

export const GET_BOOKED_SAFARI_BYID_REQUEST = "GET_BOOKED_SAFARI_BYID_REQUEST";
export const GET_BOOKED_SAFARI_BYID_SUCCESS = "GET_BOOKED_SAFARI_BYID_SUCCESS";
export const GET_BOOKED_SAFARI_BYID_FAIL = "GET_BOOKED_SAFARI_BYID_FAIL";

export const DELETE_BOOKED_SAFARI_BYID_FAIL = "DELETE_BOOKED_SAFARI_BYID_FAIL";
export const DELETE_BOOKED_SAFARI_BYID_SUCCESS =
  "DELETE_BOOKED_SAFARI_BYID_SUCCESS";
export const DELETE_BOOKED_SAFARI_BYID_REQUEST =
  "DELETE_BOOKED_SAFARI_BYID_REQUEST";
export const DELETE_BOOKED_SAFARI_BYID_RESET =
  "DELETE_BOOKED_SAFARI_BYID_RESET";

export const CREATE_SAFARI_BOOKING_REQUEST = "CREATE_SAFARI_BOOKING_REQUEST";
export const CREATE_SAFARI_BOOKING_SUCCESS = "CREATE_SAFARI_BOOKING_SUCCESS";
export const CREATE_SAFARI_BOOKING_FAIL = "CREATE_SAFARI_BOOKING_FAIL";
export const CREATE_SAFARI_BOOKING_RESET = "CREATE_SAFARI_BOOKING_RESET";
